import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import MaterialIcon from 'tio-common/components/material-icon';
import TioButton from 'tio-common/components/tio/button';
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
let SyfInsightsIdrRouteComponent = class SyfInsightsIdrRouteComponent extends Component {
    static{
        template(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.syf.dashboard" @label="Dashboard" />
      <b.crumb @route="authenticated.syf.insights.idr" @label="IDR" />
    </TioPageBreadcrumbs>
    <div class="mx-12">
      <h1 class="text-xl text-violet-800 font-semibold">
        {{t "syf.insights.idr.heading"}}
      </h1>
      <div class="grid grid-cols-3">
        <div>
          <h2 class="text-lg mt-4">{{t "syf.insights.idr.explore.heading"}}</h2>
          <div class="flex mt-4">
            <div class="ml-4">
              {{t "syf.insights.idr.explore.one"}}
            </div>
          </div>
          <div class="flex mt-4">
            <div class="ml-4">
              {{t "syf.insights.idr.explore.two"}}
            </div>
          </div>
        </div>
        <div class="col-span-2 ml-12">
          <h2 class="text-lg mt-4">{{t "syf.insights.idr.advantage.heading"}}</h2>
          <div class="my-4 flex">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t "syf.insights.idr.advantage.one"}}
            </div>
          </div>
          <div class="my-4 flex">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t "syf.insights.idr.advantage.two"}}
            </div>
          </div>
          <div class="my-4 flex">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t "syf.insights.idr.advantage.three"}}
            </div>
          </div>
          <hr />
          {{t "syf.insights.idr.advantage.label"}}
          <div class="flex mt-4">
            <TioButton @linkTo="authenticated.idr">
              {{t "syf.insights.idr.advantage.button"}}
            </TioButton>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SyfInsightsIdrRouteComponent);
