import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminEligibilityFilesShowRoute extends Route {
  @service declare store: StoreService;

  async model({ eligibility_file_job_log_id }: { eligibility_file_job_log_id: string }) {
    return this.store.findRecord('eligibility-file-job-log', eligibility_file_job_log_id, {
      include: 'company',
    });
  }
}
