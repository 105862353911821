import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import ArrowRight from 'ember-static-heroicons/components/outline-24/arrow-right';
const sectionFixtures = [
    {
        title: 'Single Step One Time Reimbursement',
        description: 'Single step one time reimbursement is targeted to cover most educational programs other than Graduate Degrees or Scholarship grants.'
    },
    {
        title: 'Two Step Prepaid Reimbursement',
        description: 'Two step prepaid reimbursement is targeted to cover most educational programs other than Graduate Degrees or Scholarship grants.'
    },
    {
        title: 'Non-monetary approval tracking',
        description: 'Non-monetary approval tracking is targeted to cover most educational programs other than Graduate Degrees or Scholarship grants.'
    },
    {
        title: 'Long Running Degree Tracking',
        description: 'Long running degree tracking is targeted to cover most educational programs other than Graduate Degrees or Scholarship grants.'
    }
];
const benifitFixture = {
    maximum: 6000,
    claimed: 0,
    remaining: 6000,
    dateRange: 'Dec 31, 2023 - Dec 31, 2024'
};
interface NavigationSectionSignature {
    Args: {
        title: string;
        description: string;
    };
}
const NavigationSection: TOC<NavigationSectionSignature> = template(`
  {{! template-lint-disable no-bare-strings }}
  <div class="mb-6 bg-cross-hatch">
    <div class="border-t border-gray-300"></div>
    <div class="mt-6">
      <div class="flex items-center justify-between">
        <div>
          <h2 class="text-xl font-medium text-gray-800">
            {{@title}}
          </h2>

          <p class="text-sm text-gray-700">
            {{@description}}
          </p>
        </div>
        <div
          class="border-t border-gray-300 my-auto p-2 w-9 h-9 bg-gray-200 rounded-full flex items-center justify-center"
        >
          <ArrowRight class="h-9 inline-block" />
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(template(`
    {{! template-lint-disable no-bare-strings }}
    <div class="flex w-full">
      <div class="max-w-3xl w-2/3 mx-auto p-4">
        <div class="font-normal text-lg">Available Applications</div>
        {{#each sectionFixtures as |fixture|}}
          <NavigationSection @title={{fixture.title}} @description={{fixture.description}} />
        {{/each}}
        <div class="flex justify-between mt-4">
          <h3 class="text-lg text-gray-800">Available Applications</h3>
          <div class="text-right text-gray-600 text-sm font-semibold tracking-tight">
            See All
          </div>
        </div>
      </div>

      <div class="w-1/3 h-72 bg-white rounded-lg border border-gray-200 p-4">
        <div class="mb-4">
          <div class="text-gray-700 text-base font-medium">Annual Benefit</div>
          <div class="mt-2 flex items-center space-x-2">
            <div class="p-2 bg-gray-50 rounded-md border border-gray-200 bg-cross-hatch">
              {{benifitFixture.dateRange}}
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="flex justify-between items-center text-base tracking-tight">
            <div class="text-gray-800 font-medium leading-tight">Maximum</div>
            <div class="text-black font-semibold bg-cross-hatch">
              {{safeFormatNumber benifitFixture.maximum currency="USD"}}
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="flex justify-between items-center text-base tracking-tight">
            <div class="text-gray-800 font-medium leading-tight">Claimed</div>
            <div class="text-purple-400 font-semibold bg-cross-hatch">
              {{safeFormatNumber benifitFixture.claimed currency="USD"}}

            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="flex justify-between items-center text-base tracking-tight">
            <div class="text-gray-800 font-medium leading-tight">Remaining</div>
            <div class="text-purple-900 font-semibold tracking-tight bg-cross-hatch">
              {{safeFormatNumber benifitFixture.remaining currency="USD"}}
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div class="text-gray-600 text-sm font-semibold tracking-tight">Benefit Details</div>
          <div
            class="border-t border-gray-300 my-auto p-2 w-9 h-9 bg-gray-200 rounded-full flex items-center justify-center"
          >
            <ArrowRight class="h-9 inline-block" />
          </div>
        </div>
      </div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
