import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import { LinkTo } from '@ember/routing';
import { htmlSafe } from '@ember/template';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import ArrowDownTray from 'ember-static-heroicons/components/outline-24/arrow-down-tray';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import Badge from 'tio-ui/components/badge';
import type { Intent } from 'tio-ui/utilities';
import { and, eq } from 'tio-ui/utilities';
import { getStatus, getProvider, extractContent, getDisputeNote } from 'tio-employee/services/observability';
import AccountSummary from 'tio-employee/components/observability/account-summary';
import { VStack, Header, Section } from 'tio-ui/components/layout';
interface RouteSignature {
    Args: {
        model: ObservableDocumentModel;
    };
}
const getStatusIntent = (status1: string): Intent | undefined =>{
    switch(status1){
        case 'PROCESSING':
            return 'primary';
        case 'INVALID':
        case 'FAILED':
            return 'error';
        case 'PROCESSED':
            return 'success';
        case 'IN_DISPUTE':
            return 'warning';
    }
};
const getSubmittedIcon = (via1: string)=>{
    if (via1 === 'EMAIL') {
        return Envelope;
    }
    return DocumentArrowUp;
};
const formatDisputeNote = (note1?: string): string | undefined =>{
    if (note1) {
        return `"${note1}"`;
    }
};
let ObservabilityDocumentsShow = class ObservabilityDocumentsShow extends Component<RouteSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get documentCreated() {
        const observableDocument1 = this.args.model;
        const createdAtDate1 = new Date(observableDocument1.createdAt);
        const date1 = createdAtDate1.toLocaleDateString();
        const time1 = createdAtDate1.toLocaleTimeString();
        const via1 = this.intl.t(`observability.${observableDocument1.via}`);
        return {
            via: via1,
            date: date1,
            time: time1
        };
    }
    get loanSummary() {
        const observableDocument1 = this.args.model;
        return extractContent(observableDocument1);
    }
    get amendLink(): string {
        const linkText1 = this.intl.t('click_here').toLowerCase();
        const url1 = this.router.urlFor('authenticated.observability.dispute', this.args.model.id);
        return htmlSafe(`<a href="${url1}" class="tio-anchor">${linkText1}</a>`) as unknown as string;
    }
    get documentSuccessful() {
        return this.args.model?.extractionState === 'ExtractionState.PROCESSED';
    }
    get documentUnsuccessful() {
        return [
            'ExtractionState.FAILED',
            'ExtractionState.INVALID'
        ].includes(this.args.model?.extractionState);
    }
    get documentInReview() {
        return this.args.model?.subsistenceState === 'SubsistenceState.IN_DISPUTE';
    }
    static{
        template(`
    <VStack>
      <Header>
        <LinkTo
          @route="authenticated.observability.documents.index"
          class="text-gray-900 font-medium"
        >
          <ChevronLeft class="w-5 inline" />
          {{t "back"}}
        </LinkTo>
      </Header>
      <Section>
        <:body>
          <div class="flex gap-2 items-start w-full md:w-2/3">
            <h1 class="tio-h1">{{@model.observableAsset.filename}}</h1>
            <Badge @intent={{getStatusIntent (getStatus @model)}} class="mt-1">{{t
                "observable_document.status"
                state=(getStatus @model)
              }}</Badge>
            <a
              href={{@model.observableAsset.signedUrl}}
              target="_blank"
              rel="noopener noreferrer"
              class="tio-btn tio-btn-default tio-btn-outlined ml-auto"
            ><ArrowDownTray class="inline w-4 mr-1" /> {{t "download"}}</a>
          </div>
          <h2 class="tio-h3 my-4">
            {{t "observable_document.provider" provider=(getProvider @model)}}</h2>
          <div class="italic flex items-center">
            {{#let (getSubmittedIcon @model.via) as |SubmittedIcon|}}
              <SubmittedIcon class="w-6 mr-2" />
            {{/let}}
            {{t
              "observability.dispute.document.uploaded"
              via=this.documentCreated.via
              date=this.documentCreated.date
              time=this.documentCreated.time
            }}
          </div>
          {{#if this.documentSuccessful}}
            {{#each this.loanSummary as |account|}}
              <AccountSummary
                @servicerName={{account.servicer}}
                @balance={{account.balance}}
                @accountNumber={{account.accountNumber}}
                @loans={{account.loans}}
                class="w-full md:w-2/3 my-8"
                as |summary|
              >
                {{#if (and this.documentInReview (getDisputeNote @model))}}
                  <summary.footer>
                    {{formatDisputeNote (getDisputeNote @model)}}
                  </summary.footer>
                {{else if (eq @model.subsistenceState "SubsistenceState.IMPLICIT")}}
                  <summary.footer>
                    {{! TODO: genericize this instead of stealing it from the confirmation page }}
                    {{t "observability.confirmation.amend" clickHere=this.amendLink htmlSafe=true}}
                  </summary.footer>
                {{/if}}
              </AccountSummary>
            {{/each}}
          {{else if this.documentUnsuccessful}}
            <p class="text-red-700 my-8">
              {{t "observability.documents.show.unsuccessful"}}
            </p>
            <p class="my-4">
              {{t "observability.uploads.support.prompt"}}
              <span class="block text-sm my-2">
                {{htmlSafe
                  (t
                    "observability.uploads.support.email"
                    email="support@tuition.io"
                    linkClass="tio-link"
                  )
                }}
              </span>
              <span class="block text-sm my-2">
                {{t "observability.uploads.support.phone"}}
              </span>
            </p>
          {{/if}}
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ObservabilityDocumentsShow);
