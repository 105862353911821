import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router';

export default class AuthenticatedPslfFormOverviewRoute extends Route {
  @service declare router: RouterService;

  // This exists purely to support traffic from the Vue app. It can be
  // removed once all PSLF exists here in Ember - Julia 11/30/2022
  redirect() {
    this.router.transitionTo('authenticated.pslf.dashboard.start-new');
  }
}
