import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PartnerService from 'tio-employee/services/partner';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedIdrRoute extends Route {
  @service declare partner: PartnerService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async beforeModel() {
    const idrEnabled = this.partner.isIdrEnabled;

    if (!idrEnabled) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }
}
