import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
interface SyfPaymentsRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfPaymentsRouteSignature>(template(`{{outlet}}`, {
    eval () {
        return eval(arguments[0]);
    }
}));
