import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat, fn } from '@ember/helper';
import { eq } from 'tio-ui/utilities';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import SelectGeneric from 'tio-common/components/select/generic';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import TioSearchInput from 'tio-common/components/tio/search-input';
import TioTablist from 'tio-common/components/tio/tablist';
import TuitionAssistanceProgramInstancesTableRow from 'tio-common/components/tuition-assistance/program-instances/table/row';
import type PartnerService from 'tio-employee/services/partner';
import type StoreService from 'tio-common/services/store';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TuitionAssistanceProgramInstancesIndexController from 'tio-employee/controllers/authenticated/admin/tuition-assistance/program-instances/index';
import type EnumModel from 'tio-common/models/enum';
import Paginator from 'tio-common/components/tio/paginator';
import Table from 'tio-common/components/table/index';
import { Section, VStack } from 'tio-ui/components/layout';
interface S {
    Args: {
        controller: TuitionAssistanceProgramInstancesIndexController;
        model: TasProgramInstanceModel[];
    };
}
const INPUT_DEBOUNCE = ENV.environment === 'test' ? 0 : 250;
let TuitionAssistanceProgramInstancesIndexRouteComponent = class TuitionAssistanceProgramInstancesIndexRouteComponent extends Component<S> {
    @service
    store: StoreService;
    @service
    partner: PartnerService;
    @tracked
    selectedStateEnum?: EnumModel;
    @tracked
    selectedProgramFilter?: TasProgramTemplateModel;
    constructor(owner1: unknown, args1: S['Args']){
        super(owner1, args1);
        this.setupMenus();
    }
    @action
    async setupMenus() {
        if (this.args.controller.state && !this.selectedStateEnum) {
            const selected1 = await this.store.query('enum', {
                filter: {
                    dot: this.args.controller.state
                }
            });
            this.selectedStateEnum = selected1.objectAt(0);
        }
        // Same logic as above
        if (this.args.controller.program && !this.selectedProgramFilter) {
            const selected1 = this.store.peekRecord('tasProgramTemplate', this.args.controller.program);
            this.selectedProgramFilter = selected1;
        }
    }
    stateOptionsData = trackedFunction(this, async ()=>{
        const states1 = await this.store.query('enum', {
            filter: {
                flavor: 'TAS.ProgramInstanceState'
            }
        });
        return states1;
    });
    get stateOptions() {
        return this.stateOptionsData.value || [];
    }
    programOptionsData = trackedFunction(this, async ()=>{
        if (!this.partner?.company) {
            return [];
        }
        const programs1 = await this.store.query('tas-program-template', {
            filter: {
                company: this.partner.company?.id
            }
        });
        return programs1;
    });
    get programOptions() {
        return this.programOptionsData.value || [];
    }
    didSearch = restartableTask(async (string1)=>{
        await timeout(INPUT_DEBOUNCE);
        this.args.controller.search = string1;
    });
    @action
    setSelectedState(stateEnum1: EnumModel) {
        this.args.controller.state = stateEnum1?.dot || '';
        this.selectedStateEnum = stateEnum1;
    }
    @action
    setSelectedProgramFilter(model1: TasProgramTemplateModel) {
        this.selectedProgramFilter = model1;
        this.args.controller.program = model1?.id || '';
    }
    @action
    changePaginatorPage(page1: number) {
        this.args.controller.page = page1;
    }
    static{
        template(`
    {{pageTitle (t "tuition_assistance.program_instances.program_applications")}}
    <VStack>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "tuition_assistance.applications.course_applications"}}
          @route="authenticated.admin.tuition-assistance.applications.index"
        />
        <tabs.tab
          @label={{t "tuition_assistance.program_instances.program_applications"}}
          @route="authenticated.admin.tuition-assistance.program-instances.index"
        />
      </TioPageTabs>
      <div class="sticky top-0 z-10 bg-gray-50">
        <div class="flex gap-4 text-sm">
          <div class="basis-80">
            {{! This label is aria-hidden because the search input has an explicit aria-label on it already }}
            <label class="text-xs" aria-hidden={{true}}>
              {{t "search"}}
            </label>

            <TioSearchInput
              @placeholder="App ID, Employee ID, Email"
              @searchString={{@controller.search}}
              @onInput={{this.didSearch.perform}}
              @onSubmit={{this.didSearch.perform}}
            />
          </div>

          <SelectGeneric
            @label={{t "tuition_assistance.applications.state_filter_label"}}
            @selected={{this.selectedStateEnum}}
            @options={{this.stateOptions}}
            @onChange={{this.setSelectedState}}
            {{! @glint-expect-error }}
            @allowClear={{true}}
            class="basis-80"
          >
            <:option as |state|>
              {{t (concat "status_message.to." state.dot)}}
            </:option>
          </SelectGeneric>

          <SelectGeneric
            @label="Filter by program"
            @selected={{this.selectedProgramFilter}}
            @options={{this.programOptions}}
            @onChange={{this.setSelectedProgramFilter}}
            {{! @glint-expect-error }}
            @allowClear={{true}}
            class="basis-80"
          >
            <:option as |program|>
              {{! @glint-expect-error we should make a helper for templates that will not return a record }}
              {{getSingleValueForTasField "PROGRAM_NAME" program.fields}}
            </:option>
          </SelectGeneric>
        </div>

        <TioTablist class="mb-6 border-b" as |tablist|>
          <tablist.tab
            @title="Approvals Waiting On Me"
            @isSelected={{eq @controller.tab "urgent"}}
            @onClick={{fn (mut @controller.tab) "urgent"}}
            class="lg:min-w-72"
          />
          <tablist.tab
            @title="Approvals Waiting On Others"
            @isSelected={{eq @controller.tab "waiting"}}
            @onClick={{fn (mut @controller.tab) "waiting"}}
            class="lg:min-w-72"
          />
          <tablist.tab
            @title="All Other Applications"
            @isSelected={{eq @controller.tab "all"}}
            @onClick={{fn (mut @controller.tab) "all"}}
            class="lg:min-w-72"
          />
        </TioTablist>
      </div>
      <Section>
        <:body>
          <Table @isLoading={{false}}>
            <:thead as |options|>
              <options.tr>
                <options.th>{{t "tuition_assistance.preapproval_app.application_id"}}</options.th>
                <options.th>{{t "tuition_assistance.preapproval_app.payroll_id"}}</options.th>
                <options.th>{{t "tuition_assistance.applications.partner_name"}}</options.th>
                <options.th>{{t "tuition_assistance.applications.employee_name"}}</options.th>
                <options.th>
                  {{t "tuition_assistance.program_details.application_history.state"}}
                </options.th>
                <options.th>{{t "tuition_assistance.applications.last_action_by"}}</options.th>
                <options.th />
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model as |item|}}
                <TuitionAssistanceProgramInstancesTableRow
                  @model={{item}}
                  @routePrefix="authenticated.admin"
                  @rowClass={{options.rowClass}}
                  @cellClass={{options.cellClass}}
                />
              {{/each}}
            </:tbody>
            <:tfoot as |options|>
              <options.tr>
                <td colspan="7">
                  <Paginator
                    {{! @glint-expect-error: ed needs types}}
                    @totalItems={{@model.meta.page.total}}
                    @pageSize={{@controller.limit}}
                    @currentPage={{@controller.page}}
                    @onChange={{this.changePaginatorPage}}
                  />
                </td>
              </options.tr>
            </:tfoot>
          </Table>
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(TuitionAssistanceProgramInstancesIndexRouteComponent);
