import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template(`
    {{! template-lint-disable no-bare-strings }}
    hello history
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
