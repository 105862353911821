import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminTuitionAssistanceProgramInstancesShowRoute extends Route {
  @service declare store: StoreService;

  includes = [
    'tas-applications.tas-courses',
    'tas-applications.tags',
    'tas-applications.comments.person',
    'tas-applications.tas-assistances',
    'tas-program-template',
    'employee.person',
    'employee.company.comments',
    'employee.tas-participant',
    'tas-assets',
    'comments.person',
  ];

  model(params: { tas_program_instance_id: string }) {
    const { tas_program_instance_id } = params;

    return this.store.findRecord('tas-program-instance', tas_program_instance_id, {
      include: this.includes.join(','),
      reload: true,
    });
  }
}
