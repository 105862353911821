import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import LoanSummaryChart from 'tio-employee/components/charts/loan-summary-chart';
import { t } from 'ember-intl';
import Tile from '../tile';
import type UserModel from 'tio-common/models/user';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
export interface LoanSummarySignature {
    Args: {
        user: UserModel;
    };
}
export default class LoanSummary extends Component<LoanSummarySignature> {
    get user() {
        return this.args.user;
    }
    get loans() {
        return this.user?.activeAccounts.map((account1)=>account1.activeLoans).flat() || [];
    }
    get lastUpdatedOn() {
        return this.loans[0]?.lastUpdatedOn;
    }
    get loansTotal() {
        return this.loans.reduce((sum1, loan1)=>{
            return (sum1 += loan1.currentBalance);
        }, 0);
    }
    static{
        template(`
    <LinkTo @route="authenticated.account-activity.accounts">
      <Tile @headerText={{t "dashboard_tiles.loan_summary"}}>
        <:body>
          <p class="font-semibold">
            <span class="block uppercase text-sm">{{t "current_balance"}}</span>
            <span class="text-2xl">
              {{safeFormatNumber this.loansTotal style="currency" currency="USD"}}
            </span>
          </p>
          <LoanSummaryChart @loans={{this.loans}} />
          {{#if this.lastUpdatedOn}}
            <p class="text-xs text-gray-500">
              {{t "dashboard_tiles.last_updated"}}
              {{safeFormatDate this.lastUpdatedOn month="short" day="2-digit" year="numeric"}}
            </p>
          {{/if}}
        </:body>
      </Tile>
    </LinkTo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
