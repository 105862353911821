import Service from '@ember/service';
import { service } from '@ember/service';
import ENV from 'tio-employee/config/environment';
import type SessionContextService from './session-context';
import type PartnerService from './partner';

export default class ZendeskService extends Service {
  @service declare sessionContext: SessionContextService;
  @service declare partner: PartnerService;

  get isEnabledForPartner() {
    return this.partner.isZendeskEnabled;
  }

  // @ts-expect-error: this is ok for now
  zendeskAction(...args) {
    return !!window.zE && window.zE(...args);
  }

  setVisibilityForEnvironment() {
    if (!this.isEnabledForPartner || !ENV.APP.showZendeskOnLoad) {
      this.zendeskAction('messenger', 'hide');
    }
  }

  openWebChatWindow() {
    this.zendeskAction('messenger', 'show');
    this.zendeskAction('messenger', 'open');
  }

  clearUser() {
    this.zendeskAction('messenger', 'logoutUser');
  }

  setWidgetLocale() {
    if (this.isEnabledForPartner) {
      this.zendeskAction('messenger:set', 'locale', window.navigator.language);
    }
  }
}
