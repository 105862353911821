/* eslint-disable ember/no-get */
import Service from '@ember/service';
import { get, action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type PartnerService from './partner';
import type { CompanySettingQuicksight } from 'tio-common/models/company-setting';

type Dashboard = CompanySettingQuicksight['dashboards'][0];

function intersection<T>(arr1: T[], arr2: T[]) {
  const set2 = new Set(arr2);
  return arr1.filter((item) => set2.has(item));
}

/**
 * Provides data relating to the current partner's available QuickSight dasbhoards (aka reports).
 * Reports are viewable as part of the partner admin experience.
 *
 * @memberof services
 */
class PartnerDashboardsService extends Service {
  @service declare partner: PartnerService;
  @service declare store: StoreService;

  /**
   * Returns an array of unfiltered QuickSight dashboards for the current company.
   * These are not to be displayed directly. See `reports` for that.
   */
  get dashboards() {
    return get(this.partner.companySettings, 'quickSight.dashboards') || [];
  }

  @action
  filterDashboards(dashboards: Dashboard[] = [], dashboardTypesToShow: string[] = []) {
    return dashboards.filter((dashboard) => {
      return (
        dashboard.active &&
        dashboard.reportType?.at(0) !== 'AGENT' &&
        (dashboard.custom ||
          intersection(dashboardTypesToShow, dashboard.reportType).length !== 0 ||
          !dashboard.reportType)
      );
    });
  }

  @action
  async fetchPartnerDashboardById(id: string) {
    const headers = this.store.adapterFor('application').headers;
    const apiHost = this.store.adapterFor('application').host;

    const response = await fetch(
      `${apiHost}/companies/${this.partner.company?.id}/quicksight-dashboard/${id}`,
      { headers }
    );

    if (response.ok) {
      const dashboard = await response.json();
      return dashboard;
    } else {
      return response;
    }
  }

  @action
  async loadAndFilterReportsForPartner() {
    // TODO: why do we need to load plans here? Are they not loaded elsewhere?
    const plans = await this.partner.loadPlansForCurrentCompany();
    const hasContributions = this.partner.doPlansHaveContributions(plans);
    const hasWellness = this.partner.doPlansHaveWellness(plans);
    const hasSecure = this.partner.isSyfEnabled;
    const hasPslf = this.partner.isPslfEnabled;

    const programTemplates = this.partner.company?.id
      ? await this.store.query('tas-program-template', {
          filter: {
            state: 'TAS.ProgramTemplateState.LAUNCHED',
            company: this.partner.company?.id,
          },
        })
      : [];

    const hasTA = !!programTemplates.length;

    const dashboardTypesToShow = [
      ...(hasContributions ? ['CONTRIBUTION'] : []),
      ...(hasTA ? ['TUITION_ASSISTANCE'] : []),
      ...(hasWellness ? ['WELLNESS'] : []),
      ...(hasSecure ? ['SECURE'] : []),
      ...(hasPslf ? ['PSLF'] : []),
    ];

    return this.filterDashboards(this.dashboards, dashboardTypesToShow);
  }
}

export default PartnerDashboardsService;
