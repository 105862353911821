import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RecommendationsController from 'tio-employee/controllers/authenticated/pslf/dashboard/recommendations';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import { t } from 'ember-intl';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
interface S {
    Args: {
        controller: RecommendationsController;
    };
}
let AuthenticatedPslfDashboardRecommendations = class AuthenticatedPslfDashboardRecommendations extends Component<S> {
    get showConsolidationRec() {
        return this.args.controller.recommendation === 'consolidation';
    }
    get showChangeRepaymentPlanRec() {
        return this.args.controller.recommendation === 'changeRepaymentPlan';
    }
    static{
        template(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.pslf.dashboard.index" @label="Dashboard" />
      <b.crumb @route="authenticated.pslf.dashboard.recommendations" @label="Recommendations" />
    </TioPageBreadcrumbs>

    {{#if this.showConsolidationRec}}
      <Section>
        <:header>
          <div>{{t "recommendations_tab.consolidate_loans"}}</div>
          <h2 class="text-normal font-normal">
            {{t "recommendations_tab.about_consolidation"}}
          </h2>
        </:header>
        <:body>
          <VStack>
            <p>{{t "recommendations_tab.consolidation_deadline" htmlSafe=true}}</p>
            <HStack @collapsed={{true}}>
              <Section>
                <:header>{{t "recommendations_tab.consolidate_how.header"}}</:header>
                <:body>
                  <ol class="list-decimal list-inside">
                    <li>
                      {{t "recommendations_tab.consolidate_how.point_1" htmlSafe=true}}
                    </li>
                    <li>{{t "recommendations_tab.consolidate_how.point_2"}}</li>
                    <li>{{t "recommendations_tab.consolidate_how.point_3"}}</li>
                    <li>{{t "recommendations_tab.consolidate_how.point_4"}}</li>
                  </ol>
                </:body>
              </Section>
              <Section>
                <:header>{{t "recommendations_tab.loan_types_header"}}</:header>
                <:body>
                  <ul class="list-disc list-inside">
                    <li>
                      <span class="font-semibold">
                        {{t "recommendations_tab.ffelp"}}
                      </span>
                      {{t "recommendations_tab.ffelp_blurb"}}
                    </li>
                    <li>
                      <span class="font-semibold">
                        {{t "recommendations_tab.perkins"}}
                      </span>
                      {{t "recommendations_tab.perkins_blurb"}}
                    </li>
                    <li>
                      <span class="font-semibold">
                        {{t "recommendations_tab.ppl"}}
                      </span>
                      {{t "recommendations_tab.ppl_blurb"}}
                    </li>
                  </ul>
                </:body>
              </Section>
              <Section>
                <:header>{{t "recommendations_tab.max_counts_if"}}</:header>
                <:body>
                  <ul class="list-disc list-inside">
                    <li>{{t "recommendations_tab.diff_loans_payments"}}</li>
                    <li>{{t "recommendations_tab.ppl_and_undergrad"}}</li>
                  </ul>
                </:body>
              </Section>
              <Section>
                <:header>{{t "recommendations_tab.still_have_questions"}}</:header>
                <:body>
                  {{t "recommendations_tab.schedule_coach" htmlSafe=true}}

                </:body>
              </Section>
            </HStack>
            <a
              class="text-ocean-600 hover:text-ocean-800"
              href="https://studentaid.gov/app/launchConsolidation.action"
              target="blank"
            >
              {{t "recommendations_tab.consolidate_loans"}}
            </a>
          </VStack>
        </:body>
      </Section>
    {{/if}}

    {{#if this.showChangeRepaymentPlanRec}}
      <Section>
        <:header>
          <div>{{t "recommendations_tab.change_repayment_type.default"}}</div>
          <h2 class="text-normal font-normal">
            {{t "recommendations_tab.change_repayment_type.about_switching"}}
          </h2>
        </:header>
        <:body>
          <VStack>
            <HStack @collapsed={{true}}>
              <Section>
                <:header>{{t "recommendations_tab.change_repayment_type.q_1"}}</:header>
                <:body>
                  <p>{{t "recommendations_tab.change_repayment_type.a_1"}}</p>
                </:body>
              </Section>
              <Section>
                <:header>{{t "recommendations_tab.change_repayment_type.q_2"}}</:header>
                <:body>
                  <ol class="list-decimal list-inside">
                    <li>
                      {{t "recommendations_tab.change_repayment_type.q_2_a_1" htmlSafe=true}}
                    </li>
                    <li>
                      {{t "recommendations_tab.change_repayment_type.q_2_a_2"}}
                    </li>
                    <li>
                      {{t "recommendations_tab.change_repayment_type.q_2_a_3" htmlSafe=true}}
                    </li>
                  </ol>
                </:body>
              </Section>
              <Section>
                <:header>{{t "recommendations_tab.still_have_questions"}}</:header>
                <:body>
                  <p>{{t "recommendations_tab.schedule_coach" htmlSafe=true}}</p>
                </:body>
              </Section>
            </HStack>
            <a
              class="text-ocean-600 hover:text-ocean-800"
              href="https://studentaid.gov/manage-loans/repayment/plans"
              target="blank"
            >
              {{t "recommendations_tab.change_repayment_type.default"}}
            </a>
          </VStack>
        </:body>
      </Section>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedPslfDashboardRecommendations);
