import Route from '@ember/routing/route';
import StoreService from '@ember-data/store';
import { service } from '@ember/service';

export default class PslfEmployerNonPartnerVerificationRoute extends Route {
  @service declare store: typeof StoreService;

  async model() {
    const params = this.paramsFor('pslf-employer');
    const employerFormInfo = await this.store
      .adapterFor('pslf-form')
      .verifyNonPartnerEmployer(params.form_document_id, params.approval);
    return {
      initialValues: employerFormInfo,
      documentId: params.form_document_id,
      approvalId: params.approval,
    };
  }
}
