import { modifier } from 'ember-modifier';
import { schedule } from '@ember/runloop';

export default modifier((element: HTMLElement, [callback]: [() => void]) => {
  if (element instanceof HTMLElement) {
    const childDiv = element.querySelector('div > div > form');
    if (childDiv) {
      // eslint-disable-next-line ember/no-runloop
      schedule('afterRender', () => {
        callback();
      });
    }
  }
});
