import { modifier } from 'ember-modifier';

export default modifier(
  (element: HTMLElement) => {
    element.dataset.partner = '61968f74e5d23';
    element.dataset.widget = '61afe0e6e72f8';

    const script = document.createElement('script');
    script.src = 'https://quatromoney.anovaa.com/widget.js';
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'qm-application-script';
    document.body.appendChild(script);

    return () => {
      document.getElementById('qm-application-script')?.remove();
    };
  },
  // @ts-expect-error: There is an issue with modifiers not exposing this correctly.
  { eager: false }
);
