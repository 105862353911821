import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PartnerDashboardsService from 'tio-employee/services/partner-dashboards';
import type RouterService from '@ember/routing/router';

export default class AuthenticatedAdminReportsIndexRoute extends Route {
  @service declare partnerDashboards: PartnerDashboardsService;
  @service declare router: RouterService;

  async model() {
    return await this.partnerDashboards.loadAndFilterReportsForPartner();
  }

  afterModel(model: Report[]) {
    // If there is only one report don't bother showing the index, go directly to the report view
    if (model.length === 1) {
      // @ts-expect-error: return to fixing ED types
      this.router.transitionTo('authenticated.admin.reports.show', model[0]?.id);
    }
  }
}
