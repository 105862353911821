import { modifier } from 'ember-modifier';
import type { EmbedInfoSignature } from '../types';

type Options = {
  embedInfo: EmbedInfoSignature;
  onAccountLink: () => void;
};

type RouteEvent = {
  type: 'route';
  data: {
    screen: 'sign-in' | 'login' | 'loading' | 'review-accounts';
    params: { institutionId: string };
  };
};

type SuccessEvent = {
  reason: string;
  code: 200;
  reportData: unknown;
};

export default modifier(
  (element: HTMLElement, _, options: Options) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://connect2.finicity.com/assets/sdk/finicity-connect.min.js';
      element.id = 'finicity_container';

      script.onload = () => {
        finicityDidLoad(options.embedInfo, options.onAccountLink);
        resolve(true);
      };
      script.onerror = reject;
      element.parentElement?.appendChild(script);
    });
    // TODO: clean up script in unload
  },
  // @ts-expect-error: There is an issue with modifiers not exposing this correctly.
  { eager: false }
);

const finicityDidLoad = (embedInfo: EmbedInfoSignature, onAccountLink: () => void) => {
  console.log('finicity loaded');
  window.finicityConnect.launch(embedInfo.embed.url, {
    selector: '#finicity_container',
    success: async (context: SuccessEvent) => {
      console.log('success', context);
      onAccountLink();
    },
    cancel: async (context: unknown) => {
      console.log('cancel', context);
    },
    error: async (context: unknown) => {
      console.log('error', context);
    },
    loaded: async (context: unknown) => {
      console.log('loaded', context);
      document.getElementById('finicityConnectIframe')?.style.setProperty('height', '100%');
      document.getElementById('finicityConnectIframe')?.style.setProperty('width', '100%');
    },
    route: async (context: RouteEvent) => {
      console.log('route', context);

      // if (this.connectFixGoToSelectStepOnRouteChange) {
      //   // We need to get out of the embedded login flow for connect fix.
      //   // It will not be able to handle the current aggregation status code,
      //   //    and the end user may be caught in an endless UI loop or worse
      //   //    receive poor messaging something like "you're good to go!".
      //   this.secondaryAction.action.handler.goToSelectStep();
      // }
    },
    user: async () => {
      // This code was brought over from Vue. It's not clear what it does.
      // Leaving for reference for now.
      // switch (context?.data?.action) {
      //   case 'Initialize':
      //     // this.connectType = event.data.type; // fix or lite
      //     break;
      //   case 'DisplayAlert':
      //     // Connect fix only....
      //     if (this.connectType === 'fix') {
      //       // If the finicity sdk is going to display an error alert, check the error code (aggregation status code).
      //       // Some codes require that on the next route change we override and return to the financial institution selection step.
      //       // This will cause the embed flow to completely restart, and enables the change between connect fix, to connect lite, if required.
      //       // Reacquisition of the connect embedded login url causes the backend to also update the aggregation status code, and we'll get the correct url type.
      //       if (event?.data?.action === 'DisplayAlert') {
      //         const errorCode: number = event.data.errorCode;
      //         if (typeof errorCode === 'number') {
      //           // Connect fix may not be able to handle that aggregation status code.
      //           this.connectFixGoToSelectStepOnRouteChange =
      //             !this.connectFixAggregationStatusCodes.includes(errorCode);
      //         }
      //       }
      //     }
      //     break;
      //   default:
      //     break;
      // }
    },
  });
};
