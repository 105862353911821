import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedIdrDashboardIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const allRecommendations = await this.store.query('recommendation', {
      filter: {
        user: this.sessionContext.user.id,
        'requesting-app': 'IDR',
      },
      include: 'user',
      sort: '-created-at',
      page: {
        offset: 0,
      },
    });

    return {
      allRecommendations: allRecommendations,
    };
  }
}
