import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import AppContentCard from 'tio-common/components/app/content-card';
import AppPageProsVsCons from 'tio-employee/components/app/page/pros-vs-cons';
import AppPagePlanDetails from 'tio-employee/components/app/page/plan-details';
import AppPagePlanDetailsCollapsible from 'tio-employee/components/app/page/plan-details-collapsible';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const pros = [
    'plan_details.examples.pro_1'
];
const cons = [
    'plan_details.examples.con_1',
    'plan_details.examples.con_2'
];
const detailsFor3 = [
    {
        title: 'plan_details.examples.details.title_1',
        description: 'plan_details.examples.details.description_1'
    },
    {
        title: 'plan_details.examples.details.title_2',
        description: 'plan_details.examples.details.description_2'
    },
    {
        title: 'plan_details.examples.details.title_3',
        description: 'plan_details.examples.details.description_3'
    }
];
const detailsFor4 = [
    {
        title: 'plan_details.examples.details.title_1',
        description: 'plan_details.examples.details.description_1'
    },
    {
        title: 'plan_details.examples.details.title_2',
        description: 'plan_details.examples.details.description_2'
    },
    {
        title: 'plan_details.examples.details.title_3',
        description: 'plan_details.examples.details.description_3'
    },
    {
        title: 'plan_details.examples.details.title_4',
        description: 'plan_details.examples.details.description_4'
    }
];
const detailsTitle = 'plan_details.examples.title';
const detailsDescription = 'plan_details.examples.description';
const detailsSections = [
    {
        title: 'plan_details.examples.section_1.title',
        description: 'plan_details.examples.section_1.description'
    },
    {
        title: 'plan_details.examples.section_2.title',
        description: 'plan_details.examples.section_2.description'
    }
];
// TODO: This is part of main component as well. Unify!
interface SectionSignature {
    Args: {
        title: string;
    };
    Blocks: {
        default: [];
    };
}
const Section: TOC<SectionSignature> = template(`
  <section class="pb-16">
    <h2 class="truncate text-base font-medium leading-7 text-slate-900">{{@title}}</h2>
    <div class="col-span-2 row-start-2 min-w-0">
      <div class="mt-4 focus:outline-none">
        <div class="w-full overflow-hidden rounded-lg ring-1 ring-slate-900/10 p-4">
          {{yield}}
        </div>
      </div>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(template(`
    {{! template-lint-disable no-bare-strings }}
    <div>
      <Section @title="Hero Section">
        <AppPageHeroSection>
          <:title>
            What is a Hero Section
          </:title>
          <:body>
            A Hero Section appears at the top of many pages and lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua.
          </:body>
        </AppPageHeroSection>
      </Section>

      <Section @title="Content Cards">
        <AppContentCard @title="This is a content card with a title" class="mb-4">
          <p>And here is the content inside of it.</p>
        </AppContentCard>

        <AppContentCard @title="">
          <p>This is a content card without a title and only content.</p>
        </AppContentCard>
      </Section>

      <Section @title="Pros Vs Cons and Plan Details">
        <div class="p-2 mb-2">
          <p>Below is a Pros Vs Cons component.</p>
          <p>Be aware: all strings given must be translation keys.</p>
          <AppPageProsVsCons @pros={{pros}} @cons={{cons}} />
        </div>

        <div class="p-2 mb-2">
          <p>Below is a Plan Details component.</p>
          <p>Be aware: all strings given must be translation keys.</p>
          <AppPagePlanDetails
            @details={{detailsFor3}}
            @pros={{pros}}
            @cons={{cons}}
            @title=""
            @description=""
          />
        </div>

        <div class="p-2 mb-2">
          <p>Below is a Collapsible Plan Details component.</p>
          <p>Be aware: all strings given must be translation keys.</p>
          <AppPagePlanDetailsCollapsible
            @title={{detailsTitle}}
            @description={{detailsDescription}}
            @details={{detailsFor4}}
            @pros={{pros}}
            @cons={{cons}}
            @moreSections={{detailsSections}}
          />
        </div>
      </Section>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
