import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PartnerDashboardsService from 'tio-employee/services/partner-dashboards';

export default class AuthenticatedAdminReportsShowRoute extends Route {
  @service declare partnerDashboards: PartnerDashboardsService;

  // TODO: Can this be deleted?
  get reports() {
    return this.partnerDashboards.dashboards;
  }

  async model(params: { quicksight_dashboard_id: string }) {
    const { quicksight_dashboard_id } = params;
    const dashboard =
      await this.partnerDashboards.fetchPartnerDashboardById(quicksight_dashboard_id);
    return dashboard;
  }
}
