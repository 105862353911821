import { template } from "@ember/template-compiler";
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { instanceBadgeState } from 'tio-employee/utils/tas/instanceStateMap';
import { getConfigForTasField } from 'tio-common/utils/tuition-assistance/fields';
import Component from '@glimmer/component';
import NavTabs from 'tio-ui/components/nav-tabs';
import RouteTemplate from 'ember-route-template';
import VerticalProgressTracker from 'tio-common/components/tio/vertical-progress-tracker';
import DescriptionList from 'tio-ui/components/description-list';
import TioFaqs from 'tio-common/components/tio/faqs';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import ProgramCustomReadonlyField from 'tio-common/components/tuition-assistance/program-custom-readonly-field';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type { Intent } from 'tio-ui/utilities';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import SafeFormatDate from 'tio-common/helpers/safe-format-date';
interface RouteSignature {
    Args: {
        model: TASProgramInstanceModel;
    };
}
let AuthenticatedTasProgramsDetails = class AuthenticatedTasProgramsDetails extends Component<RouteSignature> {
    @service
    progressBuilder: ProgressBuilder;
    @tracked
    isOpen = true;
    get programProgress() {
        return this.progressBuilder.programProgress(this.args.model.programInstance) as Step[];
    }
    get programInstanceBadgeState() {
        return instanceBadgeState(this.args.model.programInstance.state) as Record<string, Intent>;
    }
    get programType() {
        let field1 = getConfigForTasField('PROGRAM_TYPE', this.args.model.programInstance.tasProgramTemplate.fields);
        return {
            label: field1?.label || '',
            value: field1?.values[0] || ''
        } as Record<string, string>;
    }
    get employeeCustomFields() {
        const customFields1 = this.args.model.programInstance.customFields;
        let filtered1 = customFields1.filter((field1: {
            province: string;
        })=>field1.province === 'EMPLOYEE');
        return filtered1;
    }
    get programCustomFields() {
        const customFields1 = this.args.model.programInstance.customFields;
        return customFields1.filter((field1: {
            province: string;
        })=>field1.province === 'PROGRAM');
    }
    static{
        template(`
    <VStack>
      <NavTabs as |navtabs|>
        <navtabs.item @route="authenticated.tas.programs.show">
          {{t "tas.program.program"}}
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.programs.details">
          {{t "tas.program.details"}}
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.programs.history">
          {{t "tas.program.history"}}
        </navtabs.item>
      </NavTabs>
      <HStack>
        <Section>
          <:header>
            <h1>{{t "tas.program.education_assistance"}}</h1>
          </:header>
          <:body>
            <VStack @collapsed={{true}}>
              <Section>
                <:body>
                  <h2 class="font-medium">{{t "partner.employee.employee_details"}}</h2>
                  <DescriptionList @striped={{true}} as |l|>
                    <ProgramReadonlyField
                      @name="APPLICATION_ID"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="NAME"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="EMPLOYEE_ID"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="FT_PT_OTHER"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="EMPLOYEE_EMAIL"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="HIRE_DATE"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{SafeFormatDate field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="EMPLOYEE_DOB"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{SafeFormatDate field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="STUDENT_ID"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{SafeFormatDate field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    {{#each this.employeeCustomFields as |field|}}
                      <ProgramCustomReadonlyField @customField={{field}} as |field|>
                        <l.Group>
                          {{! @glint-expect-error: field.value is null or record }}
                          <l.Term>{{field.programLabel}}</l.Term>
                          {{! @glint-expect-error: field.value is null or record }}
                          <l.Details>{{field.value}}</l.Details>
                        </l.Group>
                      </ProgramCustomReadonlyField>
                    {{/each}}
                  </DescriptionList>

                  <h2 class="mt-6 font-medium">{{t "tas.program.program_approval_details"}}</h2>
                  <DescriptionList @striped={{true}} as |l|>
                    <ProgramReadonlyField
                      @name="SCHOOL_INSTITUTION_NAME"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="PROGRAM_MAJOR"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="PROGRAM_SPECIALIZATION"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="ESTIMATED_PROGRAM_BEGIN"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{SafeFormatDate field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="ESTIMATED_PROGRAM_COMPLETION"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{SafeFormatDate field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    <ProgramReadonlyField
                      @name="ENROLLMENT_OBJECTIVE"
                      @programTemplate={{@model.programInstance.tasProgramTemplate}}
                      @fields={{@model.programInstance.fields}}
                      as |field|
                    >
                      <l.Group>
                        <l.Term>{{field.programLabel}}</l.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <l.Details>{{field.value}}</l.Details>
                      </l.Group>
                    </ProgramReadonlyField>
                    {{#each this.programCustomFields as |field|}}
                      <ProgramCustomReadonlyField @customField={{field}} as |field|>
                        <l.Group>
                          {{! @glint-expect-error: field.value is null or record }}
                          <l.Term>{{field.programLabel}}</l.Term>
                          {{! @glint-expect-error: field.value is null or record }}
                          <l.Details>{{field.value}}</l.Details>
                        </l.Group>
                      </ProgramCustomReadonlyField>
                    {{/each}}
                  </DescriptionList>
                </:body>
              </Section>
              {{#if @model.programInstance.tasProgramTemplate.programFaqs}}
                <Section>
                  <h2 class="font-medium">
                    {{t "common.frequently_asked_questions"}}
                  </h2>
                  {{#each @model.programInstance.tasProgramTemplate.programFaqs as |faq|}}
                    <TioFaqs @question={{faq.heading}} @answer={{faq.body}} class="mt-3" />
                  {{/each}}
                </Section>
              {{/if}}
            </VStack>
          </:body>
        </Section>
        <Section class="md:!w-1/3">
          <:header>{{t "common.progress_tracker.program_progress"}}</:header>
          <:body>
            {{! TODO: using lists to show numbers causes flow issues. The ml-4 class will force
                the numbers into the box, but this isn't ideal as mobile view will have issues.
                Looks like the component is just a stack anyway so might be target for update. }}
            <VerticalProgressTracker class="ml-4" @steps={{this.programProgress}} />
          </:body>
        </Section>
      </HStack>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTasProgramsDetails);
