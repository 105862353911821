import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { hasInProgressInstanceOfCurrentProgramTemplate } from 'tio-common/utils/tuition-assistance/tas-grouping';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import { eq } from 'tio-ui/utilities';
import formatDateString from 'tio-common/helpers/format-date-string';
import AcceptConditions from '../accept-conditions';
import Component from '@glimmer/component';
import EmployeeReviewCard from './application-review/employee-review-card';
import ProgramReviewCard from './application-review/program-review-card';
import Submitted from './application-review/submitted';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import DependentApplicationStepper from 'tio-employee/components/tuition-assistance/program-details/dependent-application-stepper';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
const dependentFields = [
    'DEPENDENT_NAME',
    'DEPENDENT_DOB'
];
const programFields = [
    {
        name: 'SCHOOL_INSTITUTION_NAME',
        mask: null
    },
    {
        name: 'PROGRAM_SPECIALIZATION',
        mask: null
    },
    {
        name: 'PROGRAM_MAJOR',
        mask: null
    },
    {
        name: 'ESTIMATED_PROGRAM_BEGIN',
        mask: 'date'
    },
    {
        name: 'ESTIMATED_PROGRAM_COMPLETION',
        mask: 'date'
    }
];
export interface TuitionAssistanceFormsApplicationReviewSignature {
    Args: {
        programInstance: TasProgramInstanceModel;
        tasGroups: TasGroupModel[];
        activeInstances: TasProgramInstanceModel[];
    };
}
export default class TuitionAssistanceFormsApplicationReview extends Component<TuitionAssistanceFormsApplicationReviewSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    @service
    tuitionAssistance: TuitionAssistanceService;
    @tracked
    hasSubmitted = false;
    @tracked
    didSubmitSuccessfully = false;
    @tracked
    submitError = null;
    @tracked
    acceptedConditions: ConditionModel[] = [];
    fieldsCopy: TasProgramInstanceModel['fields'];
    constructor(owner1: unknown, args1: TuitionAssistanceFormsApplicationReviewSignature['Args']){
        super(owner1, args1);
        this.fieldsCopy = new TrackedObject(this.args.programInstance.fields);
    }
    get conditionsForSubmit() {
        return this.args.programInstance?.tasProgramTemplate?.programApproveConditions || [];
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    get isFormValid() {
        if (this.requireAttachmentUploads) {
            return (this.isActiveParticipant && this.hasAgreedAllConditions && !!this.args.programInstance?.tasAssets?.length);
        }
        return this.isActiveParticipant && this.hasAgreedAllConditions;
    }
    get programTemplate() {
        return this.args.programInstance.tasProgramTemplate;
    }
    get requireAttachmentUploads() {
        return this.programTemplate.requireAttachmentsForProgramApproval;
    }
    get attachmentUploadsVisible() {
        return this.programTemplate.attachmentsForProgramApprovalVisible;
    }
    get isActiveParticipant() {
        return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(this.args.programInstance.tasProgramTemplate);
    }
    get isRestrictedByProgramExclusivity() {
        if (this.args.tasGroups.length === 0) {
            return false;
        }
        return hasInProgressInstanceOfCurrentProgramTemplate(this.args.tasGroups, this.programTemplate.id, this.args.activeInstances);
    }
    get showRestrictedByProgramExclusivityWarning() {
        return !this.isMultipleInstanceProgram && this.isRestrictedByProgramExclusivity;
    }
    get isMultipleInstanceProgram() {
        return this.args.programInstance.tasProgramTemplate.isMultipleInstanceProgram;
    }
    get disableSubmitButton() {
        return !this.isFormValid || this.isRestrictedByProgramExclusivity;
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    cancel() {
        this.router.transitionTo('authenticated.tuition-assistance.dashboard');
    }
    @action
    async saveForLater() {
        await this.saveFields.perform();
        if (this.args.programInstance.isDependentProgram) {
            this.router.transitionTo('authenticated.tuition-assistance.dependent-programs');
        } else {
            this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        }
    }
    submitTask = dropTask(async ()=>{
        if (!this.isFormValid || !this.hasAgreedAllConditions || !this.isActiveParticipant || this.isRestrictedByProgramExclusivity) {
            return;
        }
        try {
            this.hasSubmitted = true;
            this.submitError = null;
            await this.saveAgreementsForConditions.perform();
            await this.saveFields.perform();
            await this.store.adapterFor('tas-program-instance').requestProgramApproval(this.args.programInstance);
            this.didSubmitSuccessfully = true;
        } catch (e1) {
            console.error(e1);
            this.submitError = e1;
        }
    });
    saveAgreementsForConditions = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.acceptedConditions.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        const saved1 = await all(promises1);
        return saved1;
    });
    saveFields = dropTask(async ()=>{
        this.args.programInstance.fields = this.fieldsCopy;
        await this.args.programInstance.save();
    });
    static{
        template(`
    {{#if @programInstance.tasProgramTemplate.isDependentProgram}}
      <DependentApplicationStepper
        @stage="Review"
        @applicationSubmitted={{this.didSubmitSuccessfully}}
      />
    {{/if}}
    {{#if this.didSubmitSuccessfully}}
      <Submitted @programInstance={{@programInstance}} />
    {{else}}
      <h4>{{t "tuition_assistance.preapproval_app.subtitle_step3"}}</h4>

      {{#unless this.isActiveParticipant}}
        <TioAlert @type="warning" @allowDismiss={{false}} class="my-4">
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
            </p>
          </:body>
        </TioAlert>
      {{/unless}}

      {{#if this.isRestrictedByProgramExclusivity}}
        <TioAlert @type="warning" @allowDismiss={{false}} class="my-4">
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.program_exclusivity_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t "tuition_assistance.program_exclusivity_warnings.dashboard_description"}}
            </p>
          </:body>
        </TioAlert>
      {{/if}}

      <div class="flex flex-wrap lg:flex-no-wrap">
        <div class="lg:mr-12">
          {{#if @programInstance.tasProgramTemplate.isDependentProgram}}
            <h3 class="mt-6 font-semibold my-2 text-midnight">{{t
                "common.tuition_assistance.dependent_info"
              }}</h3>
            <dl class="grid grid-cols-4 gap-2 my-4 w-full">
              <ProgramReadonlyField
                @name="APPLICATION_ID"
                @programTemplate={{@programInstance.tasProgramTemplate}}
                @fields={{@programInstance.fields}}
                as |field|
              >
                <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
                <dd class="col-span-2 font-semibold">
                  {{@programInstance.id}}
                </dd>
              </ProgramReadonlyField>
              {{#each dependentFields as |fieldName|}}
                <ProgramReadonlyField
                  {{! @glint-expect-error: need better type on field }}
                  @name={{fieldName}}
                  @programTemplate={{@programInstance.tasProgramTemplate}}
                  @fields={{@programInstance.fields}}
                  as |field|
                >
                  <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
                  <dd class="col-span-2 font-semibold break-words">
                    {{#if (eq fieldName "DEPENDENT_DOB")}}
                      {{! @glint-expect-error }}
                      {{formatDateString field.value}}
                    {{else}}
                      {{! @glint-expect-error }}
                      {{field.value}}
                    {{/if}}
                  </dd>
                </ProgramReadonlyField>
              {{/each}}
            </dl>
            <h3 class="font-semibold my-2 text-midnight">{{t
                "tuition_assistance.preapproval_app.program_info"
              }}</h3>
            <dl class="grid grid-cols-4 gap-2 my-4 w-full">
              {{#each programFields as |programField|}}
                <ProgramReadonlyField
                  {{! @glint-expect-error: need better type on field }}
                  @name={{programField.name}}
                  @programTemplate={{@programInstance.tasProgramTemplate}}
                  @fields={{@programInstance.fields}}
                  as |field|
                >
                  <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
                  <dd class="col-span-2 font-semibold">
                    {{#if (eq programField.mask "date")}}
                      {{! @glint-expect-error }}
                      {{formatDateString field.value}}
                    {{else}}
                      {{! @glint-expect-error }}
                      {{field.value}}
                    {{/if}}
                  </dd>
                </ProgramReadonlyField>
              {{/each}}
            </dl>
          {{else}}
            <div class="mt-8 mb-14 flex flex-wrap gap-2 md:gap-4 justify-center md:justify-start">
              <EmployeeReviewCard @programInstance={{@programInstance}} />

              <ProgramReviewCard @programInstance={{@programInstance}} />
            </div>
          {{/if}}
        </div>

        {{#if this.attachmentUploadsVisible}}
          <div class="mt-5 lg:mt-16 max-w-[400px]">
            <UploadAttachments
              @programTemplate={{@programInstance.tasProgramTemplate}}
              @classification="PROGRAM_APPROVAL_DOCUMENTS"
              @attachable={{@programInstance}}
              @attachableType="tasProgramInstance"
              @uploadRequired={{this.requireAttachmentUploads}}
            />
          </div>
        {{/if}}
      </div>

      {{#if this.conditionsForSubmit.length}}
        <AcceptConditions
          @conditions={{this.conditionsForSubmit}}
          @onChange={{this.didUpdateAcceptedConditions}}
        />
      {{/if}}

      <TioErrorMessages
        @showErrors={{this.hasSubmitted}}
        @error={{this.submitError}}
        class="text-center"
      />

      <div class="flex flex-col justify-center items-center my-5">
        <TioButton
          @onClick={{this.submitTask.perform}}
          @loading={{this.submitTask.isRunning}}
          @disabled={{this.disableSubmitButton}}
          class="mx-8 w-48"
        >
          {{t "submit"}}
        </TioButton>
        <button
          type="button"
          {{on "click" this.saveForLater}}
          class="text-xs tio-copy text-center my-4"
        >
          {{t "save_for_later"}}
        </button>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
