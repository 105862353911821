import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { hash } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import PslfDashboardCardInfo from 'tio-employee/components/pslf/dashboard/card-info';
import PslfDashboardTodo from 'tio-employee/components/pslf/dashboard/todo';
import PslfDashboardTodoCheckbox from 'tio-employee/components/pslf/dashboard/todo/checkbox';
import TioButton from 'tio-common/components/tio/button';
import type AnswerProfileModel from 'tio-common/models/answer-profile';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
export interface RecommendationsRepaymentPlanTodoSignature {
    Args: {
        answerProfile: AnswerProfileModel;
    };
}
export default class RecommendationsRepaymentPlanTodoComponent extends Component<RecommendationsRepaymentPlanTodoSignature> {
    @service
    router: RouterService;
    @service
    intl: IntlService;
    get answerProfile() {
        return this.args.answerProfile;
    }
    get isCompleted() {
        return !!this.answerProfile?.changedRepaymentPlanAt;
    }
    get linkToRec() {
        return this.idrOrPslfRoute('link');
    }
    get textForRecCard() {
        return this.idrOrPslfRoute('text');
    }
    @action
    idrOrPslfRoute(option1: 'link' | 'text') {
        if (option1 === 'link') {
            return this.router.currentRouteName === 'authenticated.idr.dashboard.index' ? 'authenticated.idr.dashboard.recommendations' : 'authenticated.pslf.dashboard.recommendations';
        }
        if (option1 === 'text') {
            return this.router.currentRouteName === 'authenticated.pslf.dashboard.index' ? this.intl.t('recommendations.change_repayment_plan_pslf') : this.intl.t('recommendations.change_repayment_plan_idr');
        }
    }
    @action
    async toggleCompleted() {
        const profile1 = this.answerProfile;
        try {
            profile1.changedRepaymentPlanAt = this.isCompleted ? '' : new Date().toISOString();
            await profile1.save();
        } catch (e1) {
            console.error(e1);
            profile1?.rollbackAttributes();
        }
    }
    static{
        template(`
    <PslfDashboardTodo
      @title={{t "recommendations.update_repayment_plan"}}
      @isComplete={{this.isCompleted}}
    >
      <:leftHeader>
        <PslfDashboardTodoCheckbox
          @checked={{this.isCompleted}}
          @onClick={{this.toggleCompleted}}
        />
      </:leftHeader>
      <:body>
        <PslfDashboardCardInfo @icon="change_circle" @text={{this.textForRecCard}} class="w-fit" />
      </:body>
      <:actions>
        <TioButton
          @linkTo={{this.linkToRec}}
          @query={{hash recommendation="changeRepaymentPlan"}}
          @outlined={{true}}
        >
          {{t "recommendations.update_repayment_plan"}}
        </TioButton>
      </:actions>
    </PslfDashboardTodo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
