import { default as EmberRouterService } from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SessionContextService from '../session-context';
import type { RouteArgs } from '@ember/routing/lib/utils';
import type Transition from '@ember/routing/transition';

export default class RouterService extends EmberRouterService {
  @service declare sessionContext: SessionContextService;

  /**
   * The route or URL to redirect to after the user is fully authenticated.
   */
  redirectAfterAuth?: unknown;

  transitionTo(...args: RouteArgs): Transition {
    // @ts-expect-error: Not sure how to add qp to the type
    const query = args[args.length - 1]?.queryParams;
    const hasQuery = typeof query === 'object';
    const emptyQuery = hasQuery && Object.keys(query).length === 0;
    const routeOrUrl = args.length > 0 || !hasQuery ? args[0] : '';

    // Once the user has agreed to any terms & conditions and selected a role,
    // then redirect to any deep link that was requested before authentication
    if (
      this.sessionContext.isFullyAuthenticated &&
      this.redirectAfterAuth &&
      typeof args?.[0] === 'string'
    ) {
      args.splice(0, 1, this.redirectAfterAuth);
      delete this.redirectAfterAuth;
    }

    if (!emptyQuery || !this.currentRoute || this.currentRoute.name !== routeOrUrl) {
      return super.transitionTo(...args);
    }

    // If a route transition was requested for the same page and an empty query
    // was explicitly passed in, treat it as if all query params were reset
    const queryParams = Object.keys(this.currentRoute.queryParams).reduce((params, key) => {
      // @ts-expect-error: Not sure how to add qp to the type
      params[key] = undefined;

      return params;
    }, {});

    return super.transitionTo(...[...args.slice(0, -1), { queryParams }]);
  }

  /**
   * Checks if a redirect is present in the URL query string and saves it for
   * later use.
   */
  detectRedirectParameter() {
    const redirect = this.currentRoute?.queryParams.redirect;

    if (redirect) {
      this.redirectAfterAuth = redirect;
    }
  }
}
