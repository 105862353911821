import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import AppHeaderBanner from './header/banner';
import AppHeaderCurrentUserMenu from './header/current-user-menu';
import Component from '@glimmer/component';
import screenIs from 'tio-common/helpers/screen-is';
import TioHeaderMenuButton from 'tio-common/components/tio/header/menu-button';
import type SessionContextService from 'tio-employee/services/session-context';
import type SideDrawerService from 'tio-common/services/side-drawer';
import type RoleViewModel from 'tio-common/models/role-view';
import { tracked } from '@glimmer/tracking';
interface AppHeaderSignature {
    Element: HTMLHeadElement;
}
export default class AppHeaderComponent extends Component<AppHeaderSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    sideDrawer: SideDrawerService;
    @tracked
    currentRole?: RoleViewModel;
    constructor(owner1: unknown, args1: unknown){
        // @ts-expect-error: not sure how to type when there are no actual args
        super(owner1, args1);
        this.currentRole = this.sessionContext.currentRole;
    }
    @action
    toggleDrawer() {
        this.sideDrawer.toggleDrawer();
    }
    @action
    setCurrentRole(role1: RoleViewModel) {
        this.sessionContext.setCurrentRole(role1);
        this.currentRole = role1;
    }
    static{
        template(`
    <header class="isolate top-0 bg-white z-10">
      <div class="flex items-center justify-between border-b border-gray-200 px-2">
        {{! template-lint-disable simple-unless }}
        {{#unless (screenIs "md")}}
          <TioHeaderMenuButton @onClick={{this.toggleDrawer}} />
        {{/unless}}
        <AppHeaderBanner @company={{this.sessionContext.currentRole.company}} />

        <div class="h-14 header-right">
          <div class="relative inline-block text-left">
            <AppHeaderCurrentUserMenu
              @user={{this.sessionContext.user}}
              @roles={{this.sessionContext.roles}}
              {{! @glint-expect-error: current role should never be undefined}}
              @currentRole={{this.currentRole}}
              @onRoleChange={{this.setCurrentRole}}
              @onLogout={{this.sessionContext.logout}}
            />
          </div>
        </div>
      </div>
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
