import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCourseApplicationsShowRoute extends Route {
  @service declare store: StoreService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  includes = [
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance',
    'tas-program-instance.tas-program-template.tas-groups',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.tas-field-options',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-assets',
    'tas-assistances',
    'tags',
  ];

  async model({ tas_application_id }: { tas_application_id: string }) {
    const application = await this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });

    await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();

    return application;
  }
}
