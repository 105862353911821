import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import LinkedAccountsCard from 'tio-employee/components/accounts/linked-accounts-card';
import DashResults from 'tio-employee/components/recommendations/dash-results';
import MonthlyPaymentForgivenessCard from 'tio-employee/components/recommendations/monthly-payment-forgiveness-card';
import type { SummaryByLoanType } from 'tio-employee/types/loan-summary';
import type UserModel from 'tio-common/models/user';
interface S {
    Args: {
        model: UserModel;
    };
}
let StrategyFinderDashboardIndexRoute = class StrategyFinderDashboardIndexRoute extends Component<S> {
    get hasGraduatedPlan() {
        const summary1 = this.args.model.loanSummary;
        return summary1.summaryByLoanType.some((typeSummary1: SummaryByLoanType)=>typeSummary1.repaymentPlan === 'graduated');
    }
    static{
        template(`
    <div class="pt-4 pb-8">
      <div class="grid grid-cols-6 gap-y-4 md:gap-4">
        {{#if @model.person.needsToAnswerWhyNoLinkedLoans}}
          <div class="col-span-6">
            <AppAlertNoLinkedLoans @person={{@model.person}} />
          </div>
        {{/if}}
        <div class="flex flex-col md:flex-row lg:flex-col gap-4 col-span-6 lg:col-span-2">
          <LinkedAccountsCard
            @accounts={{@model.accounts}}
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @programName={{t "common.strategy_finder.default"}}
          />
        </div>
        {{#if @model.mostRecentRecommendation}}
          <DashResults
            @accounts={{@model.accounts}}
            @allRecommendations={{@model.allRecommendations}}
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @programType="sf"
            @recommendation={{@model.mostRecentRecommendation}}
            class="lg:col-span-4 col-span-6"
          />
        {{/if}}
        {{#if this.hasGraduatedPlan}}
          <div class="flex flex-col md:flex-row lg:flex-col gap-4 col-span-6 lg:col-span-2">
            <div class="border border-gray-100 rounded-3xl shadow p-4">
              <p class="text-lg text-center text-gray-600 font-semibold m-4">
                {{t "pslf_dashboard.actions.dialog.graduated_plan"}}</p>
            </div>
          </div>
        {{else}}
          <MonthlyPaymentForgivenessCard
            @accounts={{@model.accounts}}
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @programType="sf"
            @user={{@model.user}}
            class="lg:col-span-4 col-span-6"
            @isCanceling={{false}}
          />
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(StrategyFinderDashboardIndexRoute);
