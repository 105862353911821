import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
interface SyfInsightsRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfInsightsRouteSignature>(template(`
    <div class="pb-12">
      {{outlet}}
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
