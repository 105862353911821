import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import type EmployeeModel from 'tio-common/models/employee';
import AccountsLinkedAccountsCard from 'tio-employee/components/accounts/linked-accounts-card';
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import RecommendationsDashRecommendations from 'tio-employee/components/recommendations/dash-recommendations';
import RecommendationsDashResults from 'tio-employee/components/recommendations/dash-results';
import RecommendationsMonthlyPaymentForgivenessCard from 'tio-employee/components/recommendations/monthly-payment-forgiveness-card';
import { HStack, VStack } from 'tio-ui/components/layout';
interface S {
    Args: {
        model: EmployeeModel;
    };
}
export default RouteTemplate<S>(template(`
    {{#if @model.person.needsToAnswerWhyNoLinkedLoans}}
      <AppAlertNoLinkedLoans @person={{@model.person}} />
    {{/if}}
    <HStack>
      <VStack>
        <AccountsLinkedAccountsCard
          @accounts={{@model.accounts}}
          @loanSummary={{@model.loanSummary}}
          @person={{@model.person}}
          @programName={{t "dashboard_tiles.income_driven_repayment"}}
        />
        <RecommendationsDashRecommendations
          class="grow"
          @loanSummary={{@model.loanSummary}}
          @person={{@model.person}}
          @recommendation={{@model.mostRecentRecommendation}}
        />
      </VStack>
      {{#if @model.mostRecentRecommendation}}
        <RecommendationsDashResults
          @allRecommendations={{@model.allRecommendations}}
          @accounts={{@model.accounts}}
          @loanSummary={{@model.loanSummary}}
          @person={{@model.person}}
          @programType="idr"
          @recommendation={{@model.mostRecentRecommendation}}
        />
      {{else}}
        <RecommendationsMonthlyPaymentForgivenessCard
          @accounts={{@model.accounts}}
          @loanSummary={{@model.loanSummary}}
          @person={{@model.person}}
          @programType="idr"
          @user={{@model.user}}
          @isCanceling={{false}}
        />
      {{/if}}
    </HStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
