import ApplicationAdapter from './application';
import downloadFile from 'tio-common/utils/download-file';

export default class ReportingAdapter extends ApplicationAdapter {
  async tasPayments(companyId: string, params: Record<string, unknown>) {
    const url = this.buildURL('reports/tas-payments', companyId) + `?${params}`;
    return await this.ajax(url, 'GET');
  }

  // TODO: why do two identical files report different errors?
  async downloadCsv(companyId: string, params: Record<string, unknown>) {
    const headers = this.headers;
    // @ts-expect-error: ED header conflicts with native fetch headers
    headers['Content-Type'] = 'text/csv';

    // TODO: can we use this.ajax instead of fetch and get the header info we need
    const url = this.buildURL('reports/tas-payments') + `/${companyId}.csv?${params}`;
    const response = await fetch(url, { headers });

    if (response.ok) {
      const contentDisposition = response.headers.get('Content-Disposition');
      // This feels ugly, but regex causes parse issues
      const name = contentDisposition?.split('filename=')[1]?.split('.')[0];
      const extension = contentDisposition?.split('.')[1]?.split(';')[0];
      const filename = `${name}.${extension}`.replaceAll('"', '');

      downloadFile(await response.blob(), filename, 'text/csv');
    }
  }
}
