import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedTuitionAssistanceProgramsInstanceApplyReviewRoute extends Route {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;

  async model() {
    const instance = this.modelFor('authenticated.tuition-assistance.programs.instance.apply');

    const currentEmployee = this.sessionContext.currentEmployee;
    const tasGroups = await this.store.query('tas-group', {
      filter: {
        company: currentEmployee.company.id,
        flavor: 'TAS.GroupFlavor.MUTUALLY_EXCLUSIVE',
      },
      include: 'tas-groupings.tas-program-template',
    });

    const activeInstances = await this.store.query('tas-program-instance', {
      filter: { employee: currentEmployee.id, active: true },
      include: 'tas-program-template',
    });

    return {
      programInstance: instance,
      tasGroups: tasGroups,
      activeInstances: activeInstances,
    };
  }
}
