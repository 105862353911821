import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { eq } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from '../../accounts/linked-loans-summary';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import screenIs from 'tio-common/helpers/screen-is';
import SvgJar from 'ember-svg-jar/helpers/svg-jar';
import TioButton from 'tio-common/components/tio/button';
import type { IntlService } from 'ember-intl';
import type { LoanSummary, SummaryByLoanType } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type RouterService from 'tio-employee/services/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
export interface WhatHappensNextSignature {
    Args: {
        accounts: AccountModel[];
        person: PersonModel;
        loanSummary: LoanSummary;
        programType: string;
    };
    Element: HTMLElement;
}
let RecResultsWhatHappensNextComponent = class RecResultsWhatHappensNextComponent extends Component<WhatHappensNextSignature> {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    intl: IntlService;
    @tracked
    showMissingInfoDialog = false;
    @tracked
    showEstimatorBlockDialog = false;
    @tracked
    showGraduatedPlanBanner = false;
    @action
    checkLoansAndGoToEstimator() {
        const summary1 = this.args.loanSummary;
        const loanErrors1 = summary1.errors || [];
        const noLoanErrors1 = loanErrors1.length === 0;
        const hasSummaryByLoanType1 = summary1.summaryByLoanType.length > 0;
        const hasGraduatedPlan1 = summary1.summaryByLoanType.some((typeSummary1: SummaryByLoanType)=>typeSummary1.repaymentPlan === 'graduated');
        if (this.args.programType === 'sf' && hasGraduatedPlan1) {
            this.showGraduatedPlanBanner = true;
            return;
        }
        if (!hasSummaryByLoanType1) {
            this.showEstimatorBlockDialog = true;
            return;
        }
        if (noLoanErrors1) {
            return this.args.programType === 'sf' ? this.router.transitionTo('authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire') : this.router.transitionTo('authenticated.idr.dashboard.forgiveness-estimator');
        } else {
            this.showMissingInfoDialog = true;
        }
    }
    static{
        template(`
    {{#if (screenIs "md")}}
      <div ...attributes>
        <h1 class="text-center font-semibold mb-12">
          {{t "idr.results.what_happens_next"}}
        </h1>
        <div class="my-4 mx-12">
          {{SvgJar
            "crystal-ball"
            role="img"
            width="100%"
            height="100%"
            desc=(t "svg.crystal_ball")
          }}
        </div>
        <div class="flex justify-center my-1">
          <MaterialIcon @icon="check" class="text-emerald-500 mx-1" aria-hidden="true" />
          <p class="mr-4">{{t "idr.results.recertify"}}</p>
        </div>
        <div class="flex justify-center my-1">
          <MaterialIcon @icon="check" class="text-emerald-500 mx-1" aria-hidden="true" />
          <p class="mr-2">{{t "idr.results.financial_circumstances"}}</p>
        </div>
        <div class="mx-10">
          <TioButton class="mx-auto mt-8" @onClick={{this.checkLoansAndGoToEstimator}}>
            {{#if (eq @programType "sf")}}
              {{t "common.strategy_finder.default"}}
            {{else}}
              {{t "idr.results.payment_button"}}
            {{/if}}
          </TioButton>
        </div>
      </div>
    {{else}}
      <AppContentCard @title={{t "idr.results.what_happens_next"}} ...attributes>
        <div class="flex justify-center">
          <div class="max-w-md">
            {{SvgJar
              "crystal-ball"
              role="img"
              width="100%"
              height="100%"
              desc=(t "svg.crystal_ball")
            }}
          </div>
        </div>
        <div class="grid grid-cols-6 gap-2">
          <MaterialIcon
            @icon="check"
            class="text-emerald-500 col-span-1 text-right"
            aria-hidden="true"
          />
          <p class="col-span-5">{{t "idr.results.recertify"}}</p>
          <MaterialIcon
            @icon="check"
            class="text-emerald-500 col-span-1 text-right"
            aria-hidden="true"
          />
          <p class="col-span-5">{{t "idr.results.financial_circumstances"}}</p>
        </div>
        <div class="mx-10">
          <TioButton class="mx-auto mt-8" @onClick={{this.checkLoansAndGoToEstimator}}>
            {{#if (eq @programType "sf")}}
              {{t "common.strategy_finder.default"}}
            {{else}}
              {{t "idr.results.payment_button"}}
            {{/if}}
          </TioButton>
        </div>
      </AppContentCard>
    {{/if}}
    {{! TODO: Could be one modal who's text is deisplayed based on state}}
    <Modal
      @isOpen={{this.showEstimatorBlockDialog}}
      @onClose={{fn (mut this.showEstimatorBlockDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Body>
        {{#if (eq @programType "sf")}}
          <p class="text-xl text-center text-gray-600 font-semibold mb-4">
            {{t "strategy_finder.before_proceeding"}}
          </p>
          <p>{{t "strategy_finder.we_will_not_be_able"}}</p>
        {{else}}
          <p class="text-xl text-center text-gray-600 font-semibold mb-4">
            {{t "pslf_dashboard.actions.dialog.before_proceeding"}}
          </p>
          <p>{{t "pslf_dashboard.actions.dialog.we_will_not_be_able"}}</p>
        {{/if}}
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{fn (mut this.showEstimatorBlockDialog) false}}
          @submitText={{t "ok"}}
        />
      </m.Footer>
    </Modal>
    <Modal
      @isOpen={{this.showGraduatedPlanBanner}}
      @onClose={{fn (mut this.showGraduatedPlanBanner) false}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Body>
        <p class="text-xl text-center text-gray-600 font-semibold mb-4">
          {{t "pslf_dashboard.actions.dialog.graduated_plan"}}
        </p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{fn (mut this.showEstimatorBlockDialog) false}}
          @submitText={{t "ok"}}
        />
      </m.Footer>
    </Modal>
    <Modal
      @isOpen={{this.showMissingInfoDialog}}
      @onClose={{fn (mut this.showMissingInfoDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        {{@person.firstName}},
        {{t "pslf_dashboard.actions.dialog.we_need_your_help"}}
      </m.Header>
      <m.Body>
        <p class="text-tio-gray-400 mb-5">
          {{t "pslf_dashboard.actions.dialog.please_provide_info"}}
        </p>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @loanSummary={{@loanSummary}}
          @person={{@person}}
        />
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{this.checkLoansAndGoToEstimator}}
          @submitText={{t "common.continue"}}
          @onCancel={{fn (mut this.showMissingInfoDialog) false}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RecResultsWhatHappensNextComponent;
