import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router';
import type UserModel from 'tio-common/models/user';

export default class AuthenticatedDashboardRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;
  @service declare partner: PartnerService;

  async model() {
    const currentUser = this.sessionContext.user;

    const user = (await this.store.findRecord('user', currentUser?.id, {
      include: 'accounts.loans,employees.plan',
      reload: true,
    })) as UserModel;

    const isTaOnlyCompany = this.partner.isTaOnly;
    const companyKey = user.employees.map((e) => e.plan?.companyKey);
    const taOnlyPlanId = this.partner.companySettings?.tuitionAssistance?.taOnlyPlanId;
    const isTaOnlyEmployee = taOnlyPlanId ? companyKey.includes(taOnlyPlanId) : false;

    return {
      user: user,
      isTaOnly: isTaOnlyCompany || isTaOnlyEmployee,
    };
  }
}
