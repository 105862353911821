import { template } from "@ember/template-compiler";
import { Footer, Modal } from 'tio-ui/components/modal';
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface AuthRegisterEnrollConfirmNameModalSignature {
    Args: {
        show: boolean;
        onClose: () => void;
        onSubmit: () => void;
        employeeFullName: string;
        inputName: string;
    };
}
const AuthRegisterEnrollConfirmNameModal: TOC<AuthRegisterEnrollConfirmNameModalSignature> = template(`
    <Modal
      @isOpen={{@show}}
      @onClose={{@onClose}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>{{t "enroll.confirm_name.header" name=@employeeFullName}}</m.Header>
      <m.Body>
        {{t "enroll.confirm_name.body" inputName=@inputName employeeFullName=@employeeFullName}}
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{@onSubmit}}
          @submitText={{t "enroll.confirm_name.register_as" name=@inputName}}
          @onCancel={{@onClose}}
          @cancelText={{t "enroll.confirm_name.yes_im" name=@employeeFullName}}
        />
      </m.Footer>
    </Modal>
  `, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AuthRegisterEnrollConfirmNameModal;
