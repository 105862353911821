import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type MatchParticipantModel from 'tio-common/models/match-participant';

export type AuthenticatedSyfRouteModel = MatchParticipantModel;

export default class AuthenticatedSyfRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const employee = await this.store.findRecord(
      'employee',
      this.sessionContext.currentEmployee.id,
      {
        include: [
          'match-participant.email-address',
          'match-participant.match-plan',
          'match-participant.person.account-transactions',
          'match-participant.match-payments.account-transaction.financial-institution',
        ].join(','),
        reload: true,
      }
    );
    return employee.matchParticipant;
  }
}
