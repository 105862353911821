import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export type QueryKeys =
  // Programs
  | 'programTemplateId'
  | 'programCreatedAtStart'
  | 'programCreatedAtEnd'
  // Applications
  | 'showIndividualApplications'
  | 'applicationStatus'
  | 'applicationCreatedAtStart'
  | 'applicationCreatedAtEnd'
  // Courses
  | 'includeCourses'
  | 'coursesBeginDate'
  | 'coursesEndDate'
  | 'employeeId'
  | 'includePayments'
  | 'approverId';

export default class AuthenticatedCompaniesReportingTasProgramsController extends Controller {
  // Programs
  @tracked programTemplateId = '';
  @tracked programCreatedAtStart = '';
  @tracked programCreatedAtEnd = '';
  // Applications
  @tracked showIndividualApplications = false;
  @tracked applicationStatus = '';
  @tracked applicationCreatedAtStart = '';
  @tracked applicationCreatedAtEnd = '';
  // Courses
  @tracked includeCourses = false;
  @tracked coursesBeginDate = '';
  @tracked coursesEndDate = '';

  @tracked includePayments = false;
  @tracked employeeId = '';
  @tracked approverId = '';

  queryParams = [
    // Programs
    'programTemplateId',
    'programCreatedAtStart',
    'programCreatedAtEnd',
    // Applications
    'showIndividualApplications',
    'applicationStatus',
    'applicationCreatedAtStart',
    'applicationCreatedAtEnd',
    // Courses
    'includeCourses',
    'coursesBeginDate',
    'coursesEndDate',

    'employeeId',
    'approverId',
    'includePayments',
  ];
}
