import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
export default class AuthenticatedAdminPlansRoute extends Route {
  @service declare store: StoreService;

  includes = ['plans'];

  model(params: { company_id: string }) {
    return this.store.findRecord('company', params.company_id, {
      backgroundReload: true,
      include: this.includes.join(','),
    });
  }
}
