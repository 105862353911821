/**
 * NOTE: This is an Ember JavaScript version of the same logging library in
 *       `tio-web/tio-web-employee/src/services/root-logger.js`. Any changes
 *       made here should also be made to that version. [twl 13.Sep.22]
 */
import Service from '@ember/service';

// type LogLevel = 'debug' | 'info' | 'warn' | 'error';

const levels = ['debug', 'info', 'warn', 'error'];
type LogLevel = (typeof levels)[number];
type LoggerConfig = {
  level: LogLevel;
  scopes?: string[];
};

const rootScope = '';
function assertValidLevel(level: LogLevel) {
  if (levels.indexOf(level) == -1) {
    throw new Error(`Invalid logging level ${level}`);
  }
}

class Logger {
  config;
  scope;

  constructor(config: LoggerConfig, scope: string) {
    assertValidLevel(config.level);

    this.config = config;
    this.scope = scope;
  }

  isLogging(level: LogLevel) {
    assertValidLevel(level);

    return (
      levels.indexOf(level) >= levels.indexOf(this.config.level) &&
      (!this.config.scopes || this.config.scopes.includes(this.scope))
    );
  }

  // @ts-expect-error: spread is fine here
  debug(...args) {
    this.log('debug', ...args);
  }
  // @ts-expect-error: spread is fine here
  info(...args) {
    this.log('info', ...args);
  }
  // @ts-expect-error: spread is fine here
  warn(...args) {
    this.log('warn', ...args);
  }
  // @ts-expect-error: spread is fine here
  error(...args) {
    this.log('error', ...args);
  }
  // @ts-expect-error: spread is fine here
  log(level: LogLevel, ...args) {
    assertValidLevel(level);

    if (!this.isLogging(level)) {
      return;
    }

    if (this.scope) {
      args = [`[${this.scope}]`, ...args];
    }
    // @ts-expect-error: spread is fine here
    console[level === 'info' ? 'log' : level](...args);
  }
}

/**
 * A basic implementation of a scope-based logger service.
 *
 * To use, add the service to a class, then use the `rootLogger` directly or
 * create a `logger` member to scope the logger to a specific scope:
 *
 * ```
 * @service rootLogger;
 *
 * logger = this.rootLogger.get('myScope');
 * ````
 *
 * To log messages, use `this.logger.<level>`, where `level` is one of: `debug`,
 * `info`, `warn`, `error`. For example:
 *
 * ```
 * this.logger.info('Starting operation');
 * ```
 *
 * Only loggers that meet the `config` criteria defined in the root logger will
 * have their log statements printed to the console.
 *
 * @memberof services
 */
class RootLoggerService extends Service {
  /**
   * The criteria for what to log:
   *
   * `level`
   * : The minimum level to log. Anything below this will be silenced.
   *
   *  `scopes`
   *  : If defined, only loggers which match one of these scopes will actively
   *    log.
   *
   * For instance, the following will log all messages from the root logger and
   * from the scope `auth` when the level is at least `info`:
   *
   * ```
   * config = {
   *   level: 'info',
   *   scopes: [ rootScope, 'auth' ],
   * };
   * ```
   *
   * TODO: Configure this in `environment.js` instead of hard-coding it.
   *       [twl 9.Sep.22]
   */
  config = {
    level: 'warn',
    scopes: undefined,
  };

  rootLogger = new Logger(this.config, rootScope);

  loggers = {};

  /**
   * Returns a new logger for the specified `scope`.
   */
  get(scope: string) {
    // @ts-expect-error: not sure what to do here
    this.loggers[scope] = this.loggers[scope] ?? new Logger({ ...this.config }, scope);
    // @ts-expect-error: not sure what to do here
    return this.loggers[scope];
  }

  /**
   * Returns whether logging is active on this logger for `level`.
   */
  isLogging(level: LogLevel) {
    return this.rootLogger.isLogging(level);
  }

  /**
   * Logs a debug message.
   */
  // @ts-expect-error: spread is fine here
  debug(...args) {
    this.rootLogger.debug(...args);
  }

  /**
   * Logs an info message.
   */
  // @ts-expect-error: spread is fine here
  info(...args) {
    this.rootLogger.info(...args);
  }

  /**
   * Logs a warning.
   */
  // @ts-expect-error: spread is fine here
  warn(...args) {
    this.rootLogger.warn(...args);
  }

  /**
   * Logs an error.
   */
  // @ts-expect-error: spread is fine here
  error(...args) {
    this.rootLogger.error(...args);
  }
}

export default RootLoggerService;
