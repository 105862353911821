import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Drawer } from '@frontile/overlays';
import { on } from '@ember/modifier';
import { tracked } from '@glimmer/tracking';
import { VStack } from 'tio-ui/components/layout';
import Component from '@glimmer/component';
import NavTabs from 'tio-ui/components/nav-tabs';
import RouteTemplate from 'ember-route-template';
let AuthentencatedTas = class AuthentencatedTas extends Component {
    @tracked
    isOpen = true;
    @action
    toggle() {
        this.isOpen = !this.isOpen;
    }
    static{
        template(`
    {{! template-lint-disable no-bare-strings }}
    <VStack>
      <NavTabs class="w-full" as |navtabs|>
        <navtabs.item @route="authenticated.tas.dashboard.summary">Summary</navtabs.item>
        <navtabs.item @route="authenticated.tas.dashboard.applications">
          Applications
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.dashboard.history">History</navtabs.item>
        <navtabs.item @route="authenticated.tas.dashboard.benefits">Benefits</navtabs.item>
        <navtabs.item @route="authenticated.tas.dashboard.network">Network</navtabs.item>
      </NavTabs>
      <div>
        {{outlet}}
      </div>
      <Button {{on "click" this.toggle}}>
        Open Drawer
      </Button>
      <Drawer
        @size="xl"
        class="bg-white"
        @isOpen={{this.isOpen}}
        @onClose={{this.toggle}}
        @backdrop="blur"
        as |d|
      >
        <d.Header @labelledById="what-is-this-follow-up-with-issue">Title</d.Header>
        <d.Body>
          <div class="container mx-auto p-6">
            <div class="mb-8 text-[#474747] text-2xl font-semibold font-montserrat">
              Non-monetary approval tracking
            </div>

            <div class="flex justify-between mb-4">
              <button
                type="button"
                class="w-32 h-9 bg-[#00809d] text-white text-sm font-medium font-montserrat rounded"
              >
                Get Started
              </button>
            </div>

            <div class="border-t border-gray-300 mb-8"></div>

            <div class="bg-white shadow p-6 rounded-lg">
              <div class="text-[#474747] text-lg font-medium font-montserrat mb-4">
                Education Information
              </div>
              <div class="flex flex-col space-y-4">
                <div class="flex justify-between items-center">
                  <div class="text-[#474747] text-sm font-medium font-montserrat">Name:</div>
                  <div class="text-[#474747] text-sm font-normal font-montserrat">Matt Hunt</div>
                </div>
                <div class="flex justify-between items-center">
                  <div class="text-[#474747] text-sm font-medium font-montserrat">Email:</div>
                  <div
                    class="text-[#474747] text-sm font-normal font-montserrat"
                  >mhunt+ta@tuition.io</div>
                </div>
                <div class="flex justify-between items-center">
                  <div
                    class="text-[#474747] text-sm font-medium font-montserrat"
                  >Specialization:</div>
                  <div class="text-[#ff0000] text-sm font-semibold font-montserrat">*</div>
                </div>
                <div class="border-t border-gray-300"></div>
                <div class="flex justify-between items-center">
                  <div class="text-[#474747] text-sm font-medium font-montserrat">Institution:</div>
                  <div class="text-[#ff0000] text-sm font-semibold font-montserrat">*</div>
                </div>
                <div class="flex justify-between items-center">
                  <div class="text-[#474747] text-sm font-medium font-montserrat">School:</div>
                  <div class="text-[#ff0000] text-sm font-semibold font-montserrat">*</div>
                </div>
                <div class="flex justify-between items-center">
                  <div class="text-[#474747] text-sm font-medium font-montserrat">Dates:</div>
                  <div class="text-[#ff0000] text-sm font-semibold font-montserrat">*</div>
                </div>
                <div class="flex space-x-4">
                  <div class="w-1/2">
                    <div class="text-[#474747] text-sm font-semibold font-montserrat mb-1">
                      Start Date
                    </div>
                    <div
                      class="w-full h-10 px-3 py-2 border border-gray-300 rounded-lg flex justify-between items-center"
                    >
                      <div class="text-[#474747] text-sm font-medium font-montserrat">
                        8/30/2024
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="text-[#474747] text-sm font-semibold font-montserrat mb-1">
                      End Date
                    </div>
                    <div
                      class="w-full h-10 px-3 py-2 border border-gray-300 rounded-lg flex justify-between items-center"
                    >
                      <div class="text-[#474747] text-sm font-medium font-montserrat">
                        12/15/2024
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </d.Body>
        <d.Footer>
          <div class="flex justify-end mt-8 space-x-4">
            <Button @appearance="minimal" @intent="danger">Cancel</Button>
            <Button @intent="primary">Add</Button>
          </div>
        </d.Footer>
      </Drawer>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthentencatedTas);
