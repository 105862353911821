import ApplicationAdapter from './application';
import { service } from '@ember/service';
import type Store from '@ember-data/store';

interface PaymentArgs {
  companyId?: string;
  paymentId?: string;
}

export default class TasPaymentAdapter extends ApplicationAdapter {
  @service declare store: typeof Store;

  async settlePayment({ paymentId }: PaymentArgs) {
    const url = [this.buildURL('tas-payment'), paymentId, 'settle_payment'].join('/');
    return this.ajax(url, 'PUT');
  }

  async cancelPayment({ paymentId }: PaymentArgs) {
    const url = [this.buildURL('tas-payment'), paymentId, 'cancel_payment'].join('/');
    return this.ajax(url, 'DELETE');
  }
}
