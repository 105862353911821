/**
 * A class that manages sending messages to and from a target window.
 *
 * Messages sent via this class can be awaited upon to receive a return value or
 * error from the target window after the message has been processed.
 *
 * Messages are sent using a `type` and a `payload`, where the type indicates
 * which kind of message is being sent and the payload contains optional data
 * for that message.
 *
 * This class automatically processes any `messageProcessed` messages sent from
 * the target window; otherwise it forwards all messages received to the
 * callback registered when this class is created.
 *
 * @memberof utils
 */

type MessageType = {
  state: 'created' | 'pending' | 'queued' | 'resolved' | 'rejected';
  type: string;
  payload: unknown;
  reject: (arg0: unknown) => void;
  resolve: (arg0: unknown) => void;
};

class WindowMessenger {
  /**
   * A unique ID for this instance.
   */
  #instanceId = new Date().valueOf();

  /**
   * The window instance this messenger is communicating with.
   */
  #targetWindow;

  /**
   * The URL of the window this messenger is communicating with.
   */
  #targetUrl;

  /**
   * The function to call when messages are received from the target window.
   */
  #callback;

  /**
   * A function that listens for messages from the target window.
   */
  #messageListener;

  /**
   * A backlog of messages to send to the target window once connected.
   */
  #outgoingQueue: MessageType[] = [];

  /**
   * Array of incoming messages received while incoming messages are paused.
   */
  #incomingQueue: MessageType[] = [];

  /**
   * Whether the processing of incoming messages is paused. When set to `false`,
   * incoming messages are processed immediately; when set to `true`, they are
   * queued until processing resumes.
   */
  #incomingPaused = false;

  /**
   * Maps message IDs to the messages waiting to be resolved.
   */
  #activeMessages: Record<string, MessageType> = {};

  /**
   * The logger instance for this class.
   */
  logger = console;

  /**
   * Whether this messenger is connected to the target window.
   */
  isConnected = false;

  /**
   * Whether this class is in debugging mode.
   *
   * Debugging mode retains messages in `activeMessages` after they have been
   * fulfilled and logs them to the console when `messageProcessed` is received.
   */
  isDebugging = false;

  /**
   * Processes messages received by another window via `WindowMessenger`.
   *
   * @callback messageHandler
   * @param {string} type The type of message that was received
   * @param {Object} [payload] The data that was received with the message
   * @memberof WindowMessenger
   */

  /**
   * Construct and initialize this messenger to send and receive messages with
   * the `targetWindow` when its location matches the `targetUrl`. Received
   * messages are sent to the `callback`.
   *
   * @param {Window} targetWindow The window to send/receive messages to/from
   * @param {string} targetUrl The URL of the target window
   * @param {messageHandler} callback A function to process messages when they
   *                                  are received
   *
   * @constructor
   */
  constructor(
    targetWindow: Window,
    targetUrl: string,
    callback: (arg0: unknown, arg1: unknown) => void
  ) {
    this.#targetWindow = targetWindow;
    this.#targetUrl = targetUrl;
    this.#callback = callback;
    this.#messageListener = this.#receive.bind(this);

    // Once added, we never remove this, since the timing of any destructor
    // registered via `registerDestructor` would cause us to miss messages
    // @ts-expect-error: needs more research
    window.addEventListener('message', this.#messageListener);
  }

  /**
   * Send a message to the target window.
   *
   * @param {string} type The type of message to send
   * @param {Object} [payload] The data to send with the messsage
   *
   * @returns {Promise<any>} A Promise that resolves with the result from the
   *                         target window
   */
  send(type: string, payload?: unknown) {
    return new Promise((resolve, reject) => {
      try {
        const id = this.#track({
          state: this.isConnected ? 'pending' : 'queued',
          type,
          payload,
          reject,
          resolve,
        });

        if (this.isConnected) {
          this.#post(id);
        } else {
          this.logger.info('Queuing message', id, type, payload ?? '');
          // @ts-expect-error: needs more research
          this.#outgoingQueue.push(id);
        }
      } catch (e) {
        this.logger.error('Could not send message:', type, payload ?? '', e);
        reject(e);
      }
    });
  }

  /**
   * Pauses sending incoming messages to the `callback` message handler, saving
   * any messages for later.
   */
  pauseIncoming() {
    this.#incomingPaused = true;
  }

  /**
   * Resumes sending incoming messages to the `callback` message handler. All
   * messages received while incoming messages were paused will be sent to the
   * callback in the order they were received before processing of new incoming
   * messages resumes.
   */
  resumeIncoming() {
    while (this.#incomingQueue.length > 0) {
      // @ts-expect-error: needs more research
      this.#receive(this.#incomingQueue.shift(), true);
    }

    this.#incomingPaused = false;
  }

  /**
   * Disconnects from the target window.
   */
  disconnect() {
    // @ts-expect-error: needs more research
    window.removeEventListener('message', this.#messageListener);
    this.isConnected = false;
  }

  /**
   * Receive a message sent from the target window.
   */
  async #receive(message: MessageType, ignorePaused = false) {
    // @ts-expect-error: needs more research
    if (!this.#targetUrl.startsWith(message.origin)) {
      return;
    }

    const shouldSendQueuedMessages = !this.isConnected;
    // @ts-expect-error: needs more research
    const { type, payload } = message.data;

    if (this.#incomingPaused && !ignorePaused && type !== 'messageProcessed') {
      this.#incomingQueue.push(message);
      return;
    }

    // Set this to true before calling #callback, so any messages sent during
    // the initial message received are sent immediately and not queued
    this.isConnected = true;

    this.logger.info('Received message', type, payload ?? '', message);

    if (type === 'messageProcessed') {
      this.#fulfill(payload);
    } else {
      // Await the callback to allow it to finish before sending queued messages
      await this.#callback(type, payload);
    }

    if (shouldSendQueuedMessages) {
      this.#sendQueuedOutgoing();
    }
  }

  /**
   * Posts a message to the Vue app.
   */
  #post(id: string) {
    if (!this.#activeMessages[id]) {
      this.logger.error(`No active message with ID ${id} found to post`);
      return;
    }
    const { type, payload, reject } = this.#activeMessages[id];

    try {
      this.logger.info('Sending message', id, type, payload ?? '');
      this.#targetWindow.postMessage(
        {
          id,
          type,
          payload,
        },
        this.#targetUrl
      );
    } catch (e) {
      this.logger.error('Could not post message:', id, type, payload ?? '', e);
      reject(e);
    }
  }

  /**
   * Send the messages in the message queue.
   */
  #sendQueuedOutgoing() {
    while (this.#outgoingQueue.length > 0) {
      // @ts-expect-error: needs more research
      this.#post(this.#outgoingQueue.shift());
    }
  }

  /**
   * Creates an active message for this integration and returns its ID so it
   * can be tracked.
   *
   * @param message {object} The message to create
   *
   * @returns {string} The ID of the message
   */
  #track(message: MessageType) {
    let id = `${this.#instanceId}:${new Date().valueOf()}`;

    while (this.#activeMessages[id]) {
      // `.` is an arbitrary character. Since we're using a timestamp to create
      // the `id` above, it's going to be rare for this to execute even once.
      // This just prevents ID collisions if we have a really fast CPU.
      id += '.';
    }

    this.#activeMessages[id] = {
      // `state` can be overridden by `message`
      // @ts-expect-error: needs more research
      state: 'created',
      ...message,
    };

    return id;
  }

  /**
   * Fulfills an open promise attached to a message sent by this integration.
   *
   * @param payload.id          The ID of the message
   * @param payload.returnValue The return value if the message was resolved
   * @param payload.error       The error if the message was rejected
   */
  #fulfill({ id, returnValue, error }: { id: string; returnValue?: unknown; error?: unknown }) {
    const activeMessage = this.#activeMessages[id];

    if (!activeMessage) {
      this.logger.error(`No active message with ID ${id} found to fulfill`);
      return;
    }

    this.logger.info('Fulfilling message:', id, {
      ...activeMessage,
      returnValue,
      error,
    });

    if (error) {
      activeMessage.reject(error);
      activeMessage.state = 'rejected';
      // @ts-expect-error: needs more research
      activeMessage.error = error;
    } else {
      activeMessage.resolve(returnValue);
      activeMessage.state = 'resolved';
      // @ts-expect-error: needs more research
      activeMessage.returnValue = returnValue;
    }

    if (this.isDebugging) {
      // Release these functions to free them up for garbage collection; keep
      // the data itself for debugging
      // @ts-expect-error: needs more research
      delete activeMessage.resolve;
      // @ts-expect-error: needs more research
      delete activeMessage.reject;

      this.logger.info('Active messages:', this.#activeMessages);
    } else {
      delete this.#activeMessages[id];
    }
  }
}

export { WindowMessenger };
