import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import FormInput from '@frontile/forms-legacy/components/form-input';
interface PasswordInputComponentSignature {
    Args: {
        label: string;
        passwordMinLength: number;
        value?: string;
        errors?: string | string[];
        hasSubmitted?: boolean;
        onInput?: (value: string) => void;
    };
    Element: HTMLDivElement;
}
export default class PasswordInput extends Component<PasswordInputComponentSignature> {
    @tracked
    showPassword = false;
    @action
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
    get passwordFieldType() {
        return this.showPassword ? 'text' : 'password';
    }
    static{
        template(`
    <FormInput
      data-legacy-input
      @label={{@label}}
      @value={{@value}}
      @hint={{t "login.minimum_characters" min=@passwordMinLength}}
      @onInput={{@onInput}}
      @errors={{@errors}}
      @hasSubmitted={{@hasSubmitted}}
      @type={{this.passwordFieldType}}
      ...attributes
    >
      <div class="relative">
        <Button
          class="absolute inset-y-0 right-0 px-3 flex items-center text-xs text-gray-500"
          @appearance="custom"
          {{on "click" this.toggleShowPassword}}
        >
          {{if this.showPassword "Hide" "Show"}}
        </Button>
      </div>
    </FormInput>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
