import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCertificateIndexRoute extends Route {
  @service declare router: RouterService;

  async beforeModel() {
    const certificateProgramInstance = this.modelFor(
      'authenticated.tuition-assistance.programs.instance'
    ) as TasProgramInstanceModel;

    const existingApplication = certificateProgramInstance.tasApplications?.[0];

    if (certificateProgramInstance.certificateApplicationInDraft && existingApplication) {
      this.router.transitionTo(
        'authenticated.tuition-assistance.programs.instance.certificate.edit',
        certificateProgramInstance.id
      );
    } else {
      this.router.transitionTo(
        'authenticated.tuition-assistance.programs.instance.certificate.new'
      );
    }
  }
}
