import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type EmployeeService from 'tio-employee/services/employee';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';

export default class AuthenticatedSlrDashboard extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare employee: EmployeeService;
  @service declare router: RouterService;

  async model() {
    const currentUser = this.sessionContext.user;

    const user = (await this.store.findRecord('user', currentUser?.id, {
      include: 'accounts.loans,accounts.financial-institution',
      reload: true,
    })) as UserModel;

    return user;
  }

  // redirect to link loans if they try to access without a loan
  async afterModel() {
    const user = await this.model();
    if (!user?.accounts?.length) {
      this.router.transitionTo('authenticated.observability.upload');
    }
  }
}
