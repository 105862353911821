import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionService from 'tio-employee/services/session';
import type RouterService from '@ember/routing/router';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import type VueIntegrationService from 'tio-employee/services/vue-integration';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedTuitionAssistanceRoute extends Route {
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare tuitionAssistance: TuitionAssistanceService;
  @service declare vueIntegration: VueIntegrationService;
  @service declare session: SessionService;
  @service declare partner: PartnerService;

  async beforeModel() {
    if (this.partner.hasLegacyTuitionAssistance) {
      await this.vueIntegration.loginWithToken(this.session.data.authenticated.access_token);
    }

    try {
      if (this.sessionContext.isAdminView) {
        this.router.transitionTo('authenticated.admin');
      }

      const taEligibilityWaitingPeriod =
        this.sessionContext.currentRole?.company.companySetting?.tuitionAssistance
          ?.eligibilityWaitingPeriodDays || 0;

      const tasParticipant = this.sessionContext.currentEmployee.tasParticipant;

      const ineligibleBasedOnWaitingPeriod = this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(
        taEligibilityWaitingPeriod,
        tasParticipant
      );

      if (ineligibleBasedOnWaitingPeriod) {
        this.router.transitionTo('authenticated.dashboard');
      }
    } catch (e) {
      console.error(e);
    }
  }
}
