import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class AdminTuitionAssistanceProgramInstancesIndexController extends Controller {
  @tracked tab = 'urgent';
  @tracked sort = 'updated-at';
  @tracked state = '';
  @tracked program = '';
  @tracked search = '';
  @tracked page = 1;
  @tracked limit = 20;

  queryParams = ['sort', 'state', 'tab', 'program', 'search', 'page', 'limit'];
}
