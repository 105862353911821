import type StoreService from 'tio-common/services/store';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';

type Query = {
  filter: {
    company: string;
    byCurrentReviewers?: string;
    bySecondaryRelevanceToReviewers?: string;
    byPassiveRelevanceToReviewers?: string;
    state?: string;
    tasProgramTemplate?: string;
    search?: string;
  };
  page: {
    limit: number;
    offset: number;
  };
  include: string;
};

type Params = {
  search: string;
  sort: string;
  state: string;
  tab: string;
  program: string;
  page: number;
  limit: number;
};

export default class AuthenticatedAdminTuitionAssistanceApplicationsIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare partner: PartnerService;
  @service declare sessionContext: SessionContextService;

  queryParams = {
    search: { refreshModel: true, replace: true },
    sort: { refreshModel: true, replace: true },
    state: { refreshModel: true, replace: true },
    tab: { refreshModel: true, replace: true },
    program: { refreshModel: true, replace: true },
    page: { refreshModel: true, replace: true },
    limit: { refreshModel: true, replace: true },
  };

  includes = [
    'tas-program-instance.tas-program-template',
    'tas-program-instance.employee',
    'tas-program-instance.employee.company',
  ];

  async model(params: Params) {
    const { search, sort, state, tab, program, page, limit } = params;
    if (program) {
      // Make sure if a tas program template ID query param exists on inital load that we have the record loaded
      const record = this.store.peekRecord('tasProgramTemplate', program) as TasProgramTemplate;
      if (!record) {
        await this.store.findRecord('tasProgramTemplate', program);
      }
    }

    const employeeIds = await this.sessionContext.getEmployeeIdsAsync();
    const employeeIdsFilter = employeeIds.join(',');

    const filter: Query['filter'] = {
      company: this.partner.company?.id as string,
    };

    if (tab === 'urgent') {
      filter.byCurrentReviewers = employeeIdsFilter;
    }

    if (tab === 'waiting') {
      filter.bySecondaryRelevanceToReviewers = employeeIdsFilter;
    }

    if (tab === 'all') {
      filter.byPassiveRelevanceToReviewers = employeeIdsFilter;
    }

    if (state) {
      filter.state = state;
    }

    if (program) {
      filter.tasProgramTemplate = program;
    }

    if (search) {
      filter.search = search;
    }

    const query = {
      filter,
      page: {
        limit,
        offset: (page - 1) * limit,
      },
      sort,
      include: this.includes.join(','),
    };
    return this.store.query('tas-application', query);
  }
}
