import ApplicationAdapter from './application';

/**
 *
 * @memberof adapters
 */
class InviteAdapter extends ApplicationAdapter {
  async getInviteByCode(inviteCode: string) {
    const url = this.buildURL('invites', inviteCode);
    return await this.ajax(url);
  }
}

export default InviteAdapter;
