/* eslint-disable ember/no-get */
import Service from '@ember/service';
import { get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { trackedFunction } from 'reactiveweb/function';
import type SessionContextService from './session-context';
import type StoreService from 'tio-common/services/store';
import type FeatureService from 'tio-common/services/features';
import type PreRegisterService from './pre-register';
import type CompanySettingModel from 'tio-common/models/company-setting';
import type { CompanySettingModules } from 'tio-common/models/company-setting';

export default class PartnerService extends Service {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare features: FeatureService;
  @service declare preRegister: PreRegisterService;

  @tracked tasProgramTemplates = [];

  get company() {
    return this.sessionContext.currentRole
      ? this.sessionContext.currentRole.company
      : this.preRegister.sparseCompany;
  }

  get companySettings() {
    if (this.sessionContext.currentRole) {
      return this.sessionContext.currentRole.company.companySetting as CompanySettingModel;
    } else {
      return this.preRegister.settings as Partial<CompanySettingModel>;
    }
  }

  get settingModules() {
    return this.companySettings.modules as CompanySettingModules;
  }

  get supportModule() {
    return this.settingModules.support;
  }

  get isPhoneSupportEnabled() {
    return (
      !this.supportModule ||
      this.supportModule?.tioPhone?.isEnabled ||
      this.supportModule?.partnerPhone
    );
  }

  get isEmailSupportEnabled() {
    return (
      !this.supportModule ||
      this.supportModule?.tioEmail?.isEnabled ||
      this.supportModule?.partnerEmail
    );
  }

  // this can also be checked from the Zendesk service
  get isZendeskEnabled() {
    return !this.supportModule || this.supportModule?.zendesk?.isEnabled;
  }

  get isFaqEnabled() {
    return !this.supportModule || this.supportModule?.faqs?.isEnabled;
  }

  get isAnySupportEnabled() {
    if (
      !this.supportModule ||
      this.isPhoneSupportEnabled ||
      this.isEmailSupportEnabled ||
      this.isZendeskEnabled
    ) {
      return true;
    }
    return false;
  }

  get subDomain() {
    if (this.sessionContext.currentRole) {
      return this.sessionContext.currentRole.company.companySetting.subDomain;
    } else {
      return this.preRegister.subdomain;
    }
  }

  get isLendkey() {
    if (!this.subDomain) {
      return false;
    }
    // All partners except:
    //   Boeing, Harbor Capital, Colorado Dept of Higher Ed, Northshore
    return !['boeing', 'harborcapital', 'cdhe', 'nseeh'].includes(this.subDomain);
  }

  get shouldShowRefinancingAwknowledgement() {
    /* This would be an opt in on the repayment options
      refinancing page. The current alert supports two lines of text.
      What would we support considering this will be an admin level
      setting?
    */
    return false;
  }

  get shouldShowCustomRefinancingWarning() {
    return this.isFedReserve;
  }

  // Used in repayment options refinancing
  get shouldShowRefinanceApply() {
    return !['boeing', 'nintendo-vdk'].includes(this.subDomain);
  }

  /**
   * Returns URL depending on the company ID.
   */
  get credibleHref() {
    return this.isMeredith
      ? 'https://www.credible.com/refinance-student-loans?utm_source=meredith&utm_medium=referral&utm_campaign=student_loan_refi'
      : 'https://credible.com';
  }

  /**
   * Returns URL depending on the company ID.
   */
  get lendkeyHref() {
    if (this.isFordMotors) {
      return 'https://www.lendkey.com/partners/ford/?sk=AFF-Tuition.io-Ford&utm_source=AFF-Tuition.io&utm_medium=referral&utm_campaign=AFF';
    }

    if (this.isMeredith) {
      return 'https://www.lendkey.com/partners/meredith/?sk=AFF-Tuition.io-Meredith&utm_source=AFF-Tuition.io&utm_medium=referral&utm_campaign=AFF';
    }

    return 'https://lendkey.com';
  }

  get isPslfEligible() {
    return this.companySettings.partnerType === 'PSLF_ELIGIBLE';
  }

  get isPrivate() {
    return this.companySettings.partnerType === 'PRIVATE';
  }

  get isIdrEnabled() {
    if (this.sessionContext.isFamilyMember || this.isPslfEnabled) {
      return false;
    }

    return this.isPrivate;
  }

  get isPslfEnabled() {
    if (this.sessionContext.isFamilyMember) {
      return false;
    }

    return get(this.companySettings, 'modules.pslf.enabled');
  }

  get isSyfEnabled() {
    return this.companySettings.modules?.syf?.isEnabled;
  }

  get isEnabledPriorEmployerPslf() {
    return this.isPslfEnabled && get(this.companySettings, 'modules.pslf.isEnabledPriorEmployer');
  }

  get isTaOnly() {
    return this.companySettings?.tuitionAssistance?.isTaOnly;
  }

  get doesAllowFamilyPlan() {
    return get(this.companySettings, 'allowFamilyPlan');
  }

  get hasNewTuitionAssistance() {
    return this.tasProgramTemplates.length > 0 && !this.hasLegacyTuitionAssistance;
  }

  get hasLegacyTuitionAssistance() {
    return get(this.companySettings, 'tuitionmanagerAccount');
  }

  @action
  async loadPlansForCurrentCompany() {
    if (!this.company?.id) {
      return;
    }

    const company = await this.store.findRecord('company', this.company?.id, {
      include: 'plans',
      reload: true,
    });
    const plans = company.plans;
    return plans;
  }

  doPlansHaveContributions(plans = []) {
    // @ts-expect-error: what is a plan in this context?
    const plansWithContributions = plans.filter((plan) => plan.cadence !== 'NEVER');
    return plansWithContributions.length > 0;
  }

  doPlansHaveWellness(plans = []) {
    // @ts-expect-error: what is a plan in this context?
    const plansWithContributions = plans.filter((plan) => plan.cadence === 'NEVER');
    return plansWithContributions.length > 0;
  }

  plansState = trackedFunction(this, async () => {
    const plans = await this.loadPlansForCurrentCompany();
    return plans;
  });

  get plans() {
    return this.plansState.value || [];
  }

  get hasContributions() {
    return this.doPlansHaveContributions(this.plans);
  }

  get hasWellness() {
    return this.doPlansHaveWellness(this.plans);
  }

  @action
  async loadTuitionAssistanceData() {
    const programTemplates = await this.store.query('tas-program-template', {
      filter: {
        state: ['TAS.ProgramTemplateState.LAUNCHED', 'TAS.ProgramTemplateState.SUSPENDED'],
        company: this.company?.id,
      },
    });
    this.tasProgramTemplates = programTemplates;
  }

  /*************************
   **  Specific Partners  **
   *************************/
  get isBoeing() {
    return this.subDomain === 'boeing';
  }

  get isStarbucks() {
    return this.subDomain === 'starbucks';
  }

  get isTrilogy() {
    return this.subDomain === 'trilogy';
  }

  get isFedReserve() {
    return this.subDomain === 'frb';
  }

  get isHoraceMann() {
    return this.subDomain === 'horacemann';
  }

  get isMeredith() {
    return this.subDomain === 'meredith';
  }

  get isNorthshore() {
    return this.subDomain === 'nseeh';
  }

  get isFordMotors() {
    return this.subDomain === 'ford';
  }
}
