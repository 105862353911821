import ApplicationSerializer from './application';

/**
 * Serializes the `PslfForm` model.
 *
 * @memberof serializers
 */
class PslfSerializer extends ApplicationSerializer {
  attributesToOmit = [
    'status',
    'payrollId',
    'faxId',
    'borrowerCompletedAt',
    'employerCompletedAt',
    'submittedAt',
    'employerSignLink',
  ];

  serializeHasMany(snapshot: unknown, json: unknown, relationship: { key: string }) {
    const key = relationship.key;
    if (key === 'attachments') {
      return;
    } else {
      super.serializeHasMany(snapshot, json, relationship);
    }
  }

  /** Only send dirty attributes to the server for saving */
  serializeAttribute(snapshot: unknown, json: unknown, key: string, attributes: unknown) {
    if (this.attributesToOmit.includes(key)) {
      return;
    }
    // @ts-expect-error: Ed needs types
    if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
      super.serializeAttribute(snapshot, json, key, attributes);
    }
  }
}

export default PslfSerializer;
