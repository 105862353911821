import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';

export default class AuthenticatedTasApplicationsHistoryRoute extends Route {
  @service declare store: StoreService;

  includes = ['tas-courses', 'tas-program-instance.tas-program-template'];

  async model({
    tas_application_id,
  }: {
    tas_application_id: string;
  }): Promise<TasApplicationModel> {
    const application = await this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });
    await application.loadHistory();
    return application;
  }
}
