import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedTasProgramsShowRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;

  async model() {
    return this.modelFor('authenticated.tas.programs');
  }

  afterModel(model: TasProgramInstanceModel /*, transition*/) {
    // check if program is still in draft state - send to new program page
    if (model.programInstance.isDraft) {
      this.router.transitionTo('authenticated.tas.programs.new', model.programInstance.id);
    }
  }
}
