import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { t } from 'ember-intl';
import { Header, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template(`
    {{! USE FREESTYLE }}
    <div class="h-screen pb-20 w-full overflow-auto">
      <VStack>
        <Header>{{t "kitchen_sink.welcome"}}</Header>

        <TioPageTabs as |tabs|>
          <tabs.tab @route="kitchen-sink.index" @label="common" />
          <tabs.tab @route="kitchen-sink.employee" @label="employee" />
        </TioPageTabs>

        {{outlet}}
      </VStack>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
