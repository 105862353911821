import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type { IntlService } from 'ember-intl';
import type { NotificationsService } from '@frontile/notifications';
import type TASProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { filterEligibleTemplates } from 'tio-common/utils/tuition-assistance/filter-eligibile-templates';

export default class AuthenticatedTuitionAssistanceDependentProgramsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare notifications: NotificationsService;
  @service declare intl: IntlService;

  async model() {
    const currentEmployee = this.sessionContext.currentEmployee;

    const promises = [
      this.store.query('tas-program-template', {
        filter: {
          state: 'TAS.ProgramTemplateState.LAUNCHED',
          byEligibility: currentEmployee.id,
          company: currentEmployee.company.id,
        },
        include: 'company.company-setting',
        reload: true,
        page: {
          limit: 100,
        },
      }),
      this.store.query('tas-program-instance', {
        filter: { employee: currentEmployee.id, active: true },
        include: 'tas-program-template,tas-applications',
        page: {
          limit: 100,
        },
      }),
      this.store.query('tas-program-instance', {
        filter: { employee: currentEmployee.id, past: true },
        include: 'tas-program-template',
      }),
      this.store.findRecord('employee', currentEmployee.id, {
        include: 'dependents',
      }),
    ];

    const [templates, activeInstances, pastInstances, employee] = await Promise.all(promises);

    const tasParticipantId = employee.tasParticipant.id;

    const [dynamicEligibility, programEligibility] = await Promise.all([
      this.store.adapterFor('tas-participant').dynamicEligibility(tasParticipantId),
      this.store.adapterFor('tas-participant').periodOfEligibility(tasParticipantId),
    ]);

    const templatesFilteredByEligibility = filterEligibleTemplates(
      templates,
      dynamicEligibility,
      programEligibility
    );

    const isDependentProgram = (program: TASProgramTemplateModel) => {
      return program.isDependentProgram;
    };

    const isDependentInstance = (instance: TASProgramInstanceModel) => {
      return instance.isDependentProgram;
    };

    const filteredTemplates = templatesFilteredByEligibility.filter(isDependentProgram);
    const filteredActiveInstances = activeInstances.filter(isDependentInstance);
    const filteredPastInstances = pastInstances.filter(isDependentInstance);

    return {
      templates: filteredTemplates,
      activeInstances: filteredActiveInstances,
      pastInstances: filteredPastInstances,
      employee,
    };
  }
}
