import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedContactUsRoute extends Route {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare partner: PartnerService;

  async model() {
    return await this.partner.companySettings;
  }
}
