import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type RouterService from '@ember/routing/router-service';
import type { MatchParticipantInsights } from 'tio-common/types/secure-your-future';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type MatchPlanModel from 'tio-common/models/match-plan';

export type SyfInsightsFourOhOneKRouteModel = {
  matchParticipant: MatchParticipantModel;
  matchPlan: MatchPlanModel;
  insights?: MatchParticipantInsights;
};

export default class AuthenticatedSyfInsightsFourOhOneKRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  beforeModel() {
    if (!this.sessionContext.currentEmployee.matchParticipant?.isEnrolled) {
      this.router.transitionTo('authenticated.syf.dashboard');
    }
  }

  async model(): Promise<SyfInsightsFourOhOneKRouteModel> {
    const participant = this.sessionContext.currentEmployee.matchParticipant;

    await participant.fetchInsights();

    return {
      matchParticipant: participant,
      matchPlan: participant.matchPlan,
      insights: participant.insights,
    };
  }
}
