import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedIdrDashboardIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const loanSummary = await this.store
      .adapterFor('user')
      .getLoanSummary(this.sessionContext.user.id);
    const person = await this.store.findRecord('person', this.sessionContext.user.person.id, {
      include: 'accounts.financial-institution,accounts.loans',
      reload: true,
    });
    // TODO: There is no reason to await any of this.
    const personAccounts = await person.accounts;
    await personAccounts.financialInstitution;
    await personAccounts.loans;
    const user = await person.user;
    const allRecsQuery = await this.store.query('recommendation', {
      filter: {
        user: this.sessionContext.user.id,
        'requesting-app': 'IDR',
      },
      include: 'user',
      sort: '-created-at',
      page: {
        offset: 0,
        limit: 3,
      },
    });

    return {
      accounts: personAccounts,
      allRecommendations: allRecsQuery,
      loanSummary: loanSummary,
      mostRecentRecommendation: allRecsQuery?.objectAt(0),
      person: person,
      user: user,
    };
  }
}
