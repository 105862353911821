import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { ComponentLike } from '@glint/template';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { isEmpty, not } from 'tio-ui/utilities';
import { capitalize } from 'tio-common/utils/format';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import CardGeneric from 'tio-common/components/card/generic';
import { formatAsDollars } from 'tio-common/utils/format';
import type AccountTransactionModel from 'tio-common/models/account-transaction';
type PaymentRowProps = {
    Args: {
        amount: number;
        servicer?: string;
        transactionDate: string;
        qualificationState?: string;
    };
};
// TODO: this should probably either be purged in favor of an internal implementation
// or altered to yield; display logic conditional on payment input is brittle here
// when the header inclusion & order are not conditional in the main component template
const PaymentRow: TOC<PaymentRowProps> = template(`
  <tr>
    <td class="px-1 pt-3 text-center">{{formatAsDollars @amount}}</td>
    {{#if @servicer}}
      <td class="px-1 pt-3 text-center">{{@servicer}}</td>
    {{/if}}
    <td class="px-1 pt-3 text-center">{{@transactionDate}}</td>
    {{#if @qualificationState}}
      <td class="px-1 pt-3 text-center">
        {{t @qualificationState}}
      </td>
    {{/if}}
  </tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
type EmptyMessageProps = {
    Args: {
        status: string;
    };
};
// NB: this is here as a result of moving more logic than was useful out of the
// main component, can be removed as part of the potential changes described below
const EmptyMessage: TOC<EmptyMessageProps> = template(`
  <div class="mt-2"><i>{{@status}}</i></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
type LoanPaymentsProps = {
    Args: {
        unverifiedPayments: AccountTransactionModel[];
        ineligiblePayments: AccountTransactionModel[];
    };
    Blocks: {
        eligiblePayments: [{
                row: ComponentLike<PaymentRowProps>;
                empty: ComponentLike;
            }];
        unverifiedPayments: [{
                row: ComponentLike<PaymentRowProps>;
                empty: ComponentLike;
            }];
    };
};
// NB: initially intended this component to implement an overly composable pattern,
// but that turned out to be more mental overhead than implementing some of the
// necessary conditional logic internally so have begun transitioning to a more
// encapsulated implementation, starting with the ineligible payments table in
// response to a header/cell mismatch bug
// - james 20240313
let LoanPayments = class LoanPayments extends Component<LoanPaymentsProps> {
    PaymentRow = PaymentRow;
    formatPaymentVerificationState(verificationState1: string): string {
        return (verificationState1.split('.').pop() || '').split('_').map(capitalize).join(' ');
    }
    static{
        template(`
    <CardGeneric class="mb-4">
      <:title>
        <div class="mb-4 text-2xl">{{t "syf.dashboard.payments.header"}}</div>
      </:title>
      <:content>
        <div class="mb-4">
          {{t "syf.dashboard.payments.add_link_prefix"}}
          <LinkTo @route="authenticated.syf.payments.new" class="mb-4 text-ocean-600">
            {{t "syf.dashboard.payments.add_link_text"}}
          </LinkTo>
        </div>
        <h2 class="font-semibold text-lg mb-2">{{t "syf.dashboard.payments.qualified.header"}}</h2>
        <table class="w-full mb-8 text-tio-gray-700 text-lg">
          <thead class="bg-ocean-50">
            <tr>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.qualified.table.payment_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.qualified.table.servicer_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.qualified.table.payment_date_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.qualified.table.status_header"
                }}</th>
            </tr>
          </thead>
          <tbody>
            {{yield
              (hash
                row=(component this.PaymentRow)
                empty=(component EmptyMessage status=(t "syf.dashboard.payments.qualified.empty"))
              )
              to="eligiblePayments"
            }}
          </tbody>
        </table>
        <h2 class="font-semibold text-lg mb-2">{{t "syf.dashboard.payments.unverified.header"}}</h2>
        <table class="w-full mb-8 text-tio-gray-700 text-lg">
          <thead class="bg-ocean-50">
            <tr>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unverified.table.payment_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unverified.table.payment_date_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unverified.table.status_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unverified.table.creation_date_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unverified.table.reason_header"
                }}</th>
            </tr>
          </thead>
          <tbody>
            {{yield
              (hash
                row=(component this.PaymentRow)
                empty=(component EmptyMessage status=(t "syf.dashboard.payments.unverified.empty"))
              )
              to="unverifiedPayments"
            }}
            {{#each @unverifiedPayments as |payment|}}
              <tr>
                <td class="px-1 pt-3 text-center">{{formatAsDollars payment.amount}}</td>
                <td class="px-1 pt-3 text-center">{{payment.transactionDate}}</td>
                <td class="px-1 pt-3 text-center">{{this.formatPaymentVerificationState
                    payment.verificationState
                  }}</td>
                <td class="px-1 pt-3 text-center">{{safeFormatDate
                    payment.createdAt
                    month="2-digit"
                    day="2-digit"
                    year="numeric"
                  }}</td>
                <td class="px-1 pt-3 text-center">{{payment.rejectionReason}}</td>
              </tr>
            {{/each}}
          </tbody>
        </table>
        <h2 class="font-semibold text-lg mb-2">
          {{t "syf.dashboard.payments.unqualified.header"}}
        </h2>
        <table class="w-full mb-8 text-tio-gray-700 text-lg">
          <thead class="bg-ocean-50">
            <tr>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unqualified.table.payment_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unqualified.table.servicer_header"
                }}</th>
              <th class="px-1 py-1">{{t
                  "syf.dashboard.payments.unqualified.table.payment_date_header"
                }}</th>
            </tr>
          </thead>
          <tbody>
            {{#if (not (isEmpty @ineligiblePayments))}}
              {{#each @ineligiblePayments as |payment|}}
                <tr>
                  <td class="px-1 pt-3 text-center">{{formatAsDollars payment.amount}}</td>
                  <td class="px-1 pt-3 text-center">{{payment.financialInstitution?.name}}</td>
                  <td class="px-1 pt-3 text-center">{{payment.transactionDate}}</td>
                </tr>
              {{/each}}
            {{else}}
              <EmptyMessage @status={{t "syf.dashboard.payments.unqualified.empty"}} />
            {{/if}}
          </tbody>
        </table>
      </:content>
    </CardGeneric>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default LoanPayments;
