import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type RouterService from '@ember/routing/router-service';
import type { AuthenticatedSyfRouteModel } from 'tio-employee/routes/authenticated/syf';
import type PersonModel from 'tio-common/models/person';
import type MatchPlanModel from 'tio-common/models/match-plan';

export type AuthenticatedSyfAccountConfirmationRouteModel = {
  person: PersonModel;
  matchParticipant: AuthenticatedSyfRouteModel;
  matchPlan: MatchPlanModel;
};

export default class AuthenticatedSyfAccountConfirmationRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  beforeModel() {
    const matchParticipant = this.modelFor('authenticated.syf') as AuthenticatedSyfRouteModel;
    if (matchParticipant.isEnrolled) {
      this.router.transitionTo('authenticated.syf.dashboard');
    }
  }

  async model() {
    const person = await this.store.findRecord('person', this.sessionContext.person.id, {
      include: 'accounts.financial-institution',
    });
    const matchParticipant = this.modelFor('authenticated.syf') as AuthenticatedSyfRouteModel;
    const matchPlan = matchParticipant.matchPlan;
    return { person, matchParticipant, matchPlan };
  }
}
