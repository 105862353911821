import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCourseApplicationsShowCompleteCourseEvidenceRoute extends Route {
  @service declare store: StoreService;

  includes = ['tas-assets', 'tas-application.tas-program-instance.tas-program-template'];

  model({ tas_course_id }: { tas_course_id: string }) {
    return this.store.findRecord('tas-course', tas_course_id, {
      include: this.includes.join(','),
      reload: true,
    });
  }
}
