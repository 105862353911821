import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { Popover } from 'tio-ui/components/popover';
import ExclamationTriangle from 'ember-static-heroicons/components/outline-24/exclamation-triangle';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
interface MissingRequirementsSignature {
    Blocks: {
        default: [];
    };
    Element: HTMLSpanElement;
}
const MissingRequirements: TOC<MissingRequirementsSignature> = template(`
  <Popover as |pop|>
    <button
      type="button"
      disabled={{true}}
      class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-gray-300 rounded-lg"
      {{on "mouseenter" pop.open}}
      {{on "mouseleave" pop.close}}
      {{pop.anchor}}
    >
      {{t "tas.benefits_summary.submit_application"}}
    </button>
    <pop.Content @class="p-4 !w-1/2 !h-96 bg-[#FDFBE6]">
      <div class="flex">
        <div class="flex-none w-14 h-14">
          <ExclamationTriangle class="w-8 h-8 text-yellow-500" />
        </div>
        <div class="grow h-14">
          <h3 class="font-bold text-[#774517]">
            {{t "tas.application.type_two.missing_requirements.title"}}
          </h3>
          <ul class="p-6">
            <li class="list-disc">
              {{t "tas.application.type_two.missing_requirements.documents_required"}}
            </li>
            <ol class="p-4">
              <li class="list-decimal">
                {{t "tas.application.type_two.missing_requirements.documents_education"}}
              </li>
              <li class="list-decimal">
                {{t "tas.application.type_two.missing_requirements.documents_attachments"}}
              </li>
            </ol>
            <li class="list-disc pb-4">
              {{t "tas.application.type_two.missing_requirements.reimbursement_confirmation"}}
            </li>
            <li class="list-disc">
              {{t "tas.application.type_two.missing_requirements.conditions_confirmation"}}
            </li>
          </ul>
        </div>
        <div class="flex-none w-14 h-14">
          <XMark class="w-8 h-8 text-gray-500" />
        </div>
      </div>
    </pop.Content>
  </Popover>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default MissingRequirements;
