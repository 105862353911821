import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionContextService from 'tio-employee/services/session-context';
import type RouterService from '@ember/routing/router-service';

export default class LogoutRoute extends Route {
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  beforeModel() {
    this.sessionContext.logout();
    this.router.transitionTo('/logged-out');
  }
}
