import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

type RouteParams = {
  // route
  observable_document_id: string;
  // query
  source: string;
};

export default class AuthenticatedObservabilityConfirmRoute extends Route {
  @service declare store: StoreService;

  queryParams = { source: { refreshModel: false } };

  async model(params: RouteParams) {
    // fetch loan here for account information section
    const observableDocument = await this.store.findRecord(
      'observable-document',
      params['observable_document_id']
    );

    return {
      source: params.source,
      observableDocument,
    };
  }
}
