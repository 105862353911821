import Base from 'ember-simple-auth/authenticators/base';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type SessionService from 'tio-employee/services/session';
import type ApiService from 'tio-employee/services/api';

/**
 * An authenticator to authenticate users against credentials stored in the Tio
 * database.
 *
 * @memberof authenticators
 */
class Database extends Base {
  @service declare api: ApiService;
  @service declare session: SessionService;

  /**
   * Restores the session from a session data object; __will return a resolving
   * promise when there is a non-empty `access_token` in the session data__ and
   * a rejecting promise otherwise.
   *
   * @param {Object} data The data to restore the session from
   * @return {Promise} A promise that when it resolves results in the session becoming or remaining authenticated
   * @public
   */
  restore(data: unknown) {
    return new Promise((resolve, reject) => {
      if (!this._validateData(data)) {
        return reject('Could not restore session - "access_token" missing.');
      }

      return resolve(data);
    });
  }

  /**
   * Authenticates the user using the `email` and `password`.
   *
   * @param {string} email
   * @param {string} password
   * @return {Promise} A promise that when it resolves results in the session becoming authenticated
   * @public
   */
  async authenticate(email: string, password: string, initialData = {}) {
    const apiResponse = await this.api.post(
      '/user_logins/sign_in',
      {
        user_login: {
          email,
          password,
        },
      },
      {
        returnType: 'response',
      }
    );
    // @ts-expect-error: we should revisit these authenticators entirely for modern techniques
    if (apiResponse.ok) {
      return {
        // @ts-expect-error: we should revisit these authenticators entirely for modern techniques
        access_token: apiResponse.headers.get('tio-auth-token'),
        ...initialData,
      };
    } else {
      // @ts-expect-error: we should revisit these authenticators entirely for modern techniques
      throw apiResponse.apiError ? apiResponse.apiError : new Error('Unknown authentication error');
    }
  }
  /**
   * This method simply returns a resolving promise.
   *
   * @return {Promise} A promise that when it resolves results in the session being invalidated
   * @public
   */
  invalidate() {
    return new Promise((resolve /*, reject*/) => {
      // @ts-expect-error: not sure
      resolve();
    });
  }

  _validateData(data: unknown) {
    // see https://tools.ietf.org/html/rfc6749#section-4.2.2
    // @ts-expect-error: not sure
    return !isEmpty(data) && !isEmpty(data.access_token);
  }
}

export default Database;
