import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCourseApplicationsShowCompleteReviewRoute extends Route {
  @service declare store: StoreService;

  model() {
    return this.modelFor(
      'authenticated.tuition-assistance.programs.instance.course-applications.show'
    );
  }
}
