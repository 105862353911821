import Route from '@ember/routing/route';

export default class PslfEmployerCancelledRoute extends Route {
  model(params: { form_document_id: string }) {
    const { form_document_id } = params;
    return {
      documentId: form_document_id,
    };
  }
}
