import { modifier } from 'ember-modifier';
import type { EmbedInfoSignature } from '../types';
import type Store from '@ember-data/store';

type Options = {
  embedInfo: EmbedInfoSignature;
  onAccountLink: () => void;
  // Not happy about this, see note on modifier.
  store: typeof Store;
};

/***
 * A modifier that partially simulates an embed provider for development purposes.
 * In general the code inside should not be looked at as an example of how to write
 * modifiers. Normally this would be a component, but we are using a modifier to
 * emulate the behavior of an embed provider.
 */
export default modifier((element: HTMLElement, _, options: Options) => {
  // Create the form elements
  const form = document.createElement('form');
  form.className = 'bg-gray-100 text-center w-1/3 px-3 py-4 mx-auto rounded';

  const usernameInput = document.createElement('input');
  const passwordInput = document.createElement('input');
  const submitButton = document.createElement('button');

  // Set attributes for username input
  usernameInput.setAttribute('type', 'text');
  usernameInput.setAttribute('name', 'username');
  usernameInput.setAttribute('placeholder', 'Username');
  usernameInput.className = 'block w-full mx-auto text-sm py-2 px-3 rounded mb-4';

  // Set attributes for password input
  passwordInput.setAttribute('type', 'password');
  passwordInput.setAttribute('name', 'password');
  passwordInput.setAttribute('placeholder', 'Password');
  passwordInput.className = 'block w-full mx-auto text-sm py-2 px-3 rounded mb-4';

  // Set attributes for the submit button
  submitButton.setAttribute('type', 'submit');
  submitButton.textContent = 'Submit';
  submitButton.className = 'font-bold py-2 px-4 rounded border block mx-auto w-full';

  // Append the inputs and button to the form
  form.appendChild(usernameInput);
  form.appendChild(passwordInput);
  form.appendChild(submitButton);

  // Add an event listener to handle form submission
  form.addEventListener('submit', (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Call the external service using the provided callback in options
    const username = usernameInput.value;
    const password = passwordInput.value;
    runLinkingFlow(username, password, options);
  });

  // Append the form to the provided element
  element.appendChild(form);
});

const runLinkingFlow = async (username: string, password: string, options: Options) => {
  const loginInfo = await options.store
    .adapterFor('account')
    .fetchSpreadsheetForm(options.embedInfo.financialInstitution.id);

  const data = {
    financialInstitution: { id: options.embedInfo.financialInstitution.legacyId },
    loginFormAnswers: [
      { id: loginInfo.findBy('type', 'USERNAME').id, value: username },
      { id: loginInfo.findBy('type', 'PASSWORD').id, value: password },
    ],
    step: 2,
    user: { id: options.embedInfo.user?.id },
  };

  localStorage.setItem('spreadsheet-provider', JSON.stringify(data));
  await options.store.adapterFor('account').simulateLinking(data);

  options.onAccountLink();
};
