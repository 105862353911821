import { template } from "@ember/template-compiler";
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import ForgivenessEstimatorResultsDashboardTable from 'tio-employee/components/forgiveness-estimator/results-dashboard-table';
import PslfDashboardActions from 'tio-employee/components/pslf/dashboard/actions';
import PslfDashboardQualifyingPayments from 'tio-employee/components/pslf/dashboard/qualifying-payments';
import RecommendationsDashRecommendations from 'tio-employee/components/recommendations/dash-recommendations';
import RouteTemplate from 'ember-route-template';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
import type RecomendationModel from 'tio-common/models/recommendation';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
/* TODO: The margin is a hack until there is a more dynamic route-based breadcrumb
solution. We need the top margin because only index has no breadcrumbs and the
bottom margin needs to match - Julia 11/22/2022 */ interface S {
    model: {
        loanSummary: LoanSummary;
        accounts: AccountModel[];
        pslfProfile: PslfProfileModel;
        person: PersonModel;
        mostRecentRecommendation: RecomendationModel;
    };
}
export default RouteTemplate<S>(template(`
    {{#if @model.person.needsToAnswerWhyNoLinkedLoans}}
      <Section>
        <AppAlertNoLinkedLoans @person={{@model.person}} />
      </Section>
    {{/if}}
    <HStack>
      <PslfDashboardActions
        @loanSummary={{@model.loanSummary}}
        @accounts={{@model.accounts}}
        @pslfProfile={{@model.pslfProfile}}
        @person={{@model.person}}
        @recommendation={{@model.mostRecentRecommendation}}
      />
      <Section>
        <VStack @collapsed={{true}}>
          {{#if @model.mostRecentRecommendation}}
            <ForgivenessEstimatorResultsDashboardTable
              @recommendation={{@model.mostRecentRecommendation}}
              @programType="pslf"
            />
          {{/if}}
          <RecommendationsDashRecommendations
            @loanSummary={{@model.loanSummary}}
            @person={{@model.person}}
            @recommendation={{@model.mostRecentRecommendation}}
          />
          <PslfDashboardQualifyingPayments class="md:col-span-2 xl:col-span-1" />
        </VStack>
      </Section>
    </HStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
