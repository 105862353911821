import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { LinkTo } from '@ember/routing';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import Table from 'tio-common/components/table/index';
import Paginator from 'tio-common/components/tio/paginator';
import Badge from 'tio-ui/components/badge';
import type { Intent } from 'tio-ui/utilities';
import { getStatus, getProvider } from 'tio-employee/services/observability';
import { Header, Section, VStack } from 'tio-ui/components/layout';
interface RouteSignature {
    Args: {
        model: {
            observableDocuments: ObservableDocumentModel[];
            total: number;
            page: number;
            pageSize: number;
        };
    };
}
const getStatusIntent = (status1: string): Intent | undefined =>{
    switch(status1){
        case 'PROCESSING':
            return 'primary';
        case 'INVALID':
        case 'FAILED':
            return 'error';
        case 'PROCESSED':
            return 'success';
        case 'IN_DISPUTE':
            return 'warning';
    }
};
const getSubmittedIcon = (via1: string)=>{
    if (via1 === 'EMAIL') {
        return Envelope;
    }
    return DocumentArrowUp;
};
const getCreatedAt = (document1: ObservableDocumentModel)=>{
    const createdAtDate1 = new Date(document1.createdAt);
    return createdAtDate1.toLocaleString();
};
let ObservabilityDocumentsIndex = class ObservabilityDocumentsIndex extends Component<RouteSignature> {
    get currentPage() {
        return this.args.model.page || 1;
    }
    static{
        template(`
    <VStack>
      <Header>
        <LinkTo @route="authenticated.account-activity.accounts" class="text-gray-900 font-medium">
          <ChevronLeft class="w-5 inline" />
          {{t "back"}}
        </LinkTo>
      </Header>
      <Section>
        <:header>{{t "observability.documents.index.title"}}</:header>
        <:body>
          <Table @isLoading={{false}}>
            <:thead as |options|>
              <options.tr>
                <options.th>{{t "observability.documents.index.headers.filename"}}</options.th>
                <options.th>{{t "observability.documents.index.headers.provider"}}</options.th>
                <options.th>{{t "observability.documents.index.headers.status"}}</options.th>
                <options.th>{{t "observability.documents.index.headers.submitted"}}</options.th>
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model.observableDocuments as |document|}}
                <options.tr>
                  <options.td>
                    <LinkTo
                      @route="authenticated.observability.documents.show"
                      @model={{document}}
                      class="tio-anchor"
                    >
                      {{document.observableAsset.filename}}
                    </LinkTo>
                  </options.td>
                  <options.td>
                    {{t "observable_document.provider" provider=(getProvider document)}}
                  </options.td>
                  <options.td>
                    <Badge @intent={{getStatusIntent (getStatus document)}}>{{t
                        "observable_document.status"
                        state=(getStatus document)
                      }}</Badge>
                  </options.td>
                  <options.td>
                    <div class="flex flex-row flex-nowrap justify-left items-center">
                      {{#let (getSubmittedIcon document.via) as |SubmittedIcon|}}
                        <SubmittedIcon class="w-6 mr-2" />
                      {{/let}}
                      {{getCreatedAt document}}
                    </div>
                  </options.td>
                </options.tr>
              {{/each}}
            </:tbody>
          </Table>
          <Paginator @totalItems={{@model.total}} @pageSize={{@model.pageSize}} />
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ObservabilityDocumentsIndex);
