import Service from '@ember/service';
import { service } from '@ember/service';
import type AppSession from './session';
import ENV from 'tio-employee/config/environment';

export interface CallApiOptions extends RequestInit {
  requestType?: 'json' | 'jsonApi';
  returnType?: 'response' | 'json' | 'text' | 'blob';
}

export class ApiError extends Error {
  response: Response;
  details?: object | string | Blob;

  constructor(message: string, response: Response, details?: object | string | Blob) {
    super(message);

    this.name = 'ApiError';
    this.response = response;
    this.details = details;
  }
}

export class ApiResponse extends Response {
  apiError?: ApiError;
}

export default class ApiService extends Service {
  @service declare session: AppSession;

  /**
   * Calls the API with the given path and options. Unless specified otherwise via the `method`
   * option, the request will be a GET request.
   *
   * This function has two different behaviors depending on the value of `options.returnType`:
   *
   * `json` (default)
   * : A JSON object is returned if the response is OK; otherwise an error is thrown with the
   *   response status.
   *
   * `blob`
   * : A Blob is returned if the response is OK; otherwise an error is thrown with the response
   *   status.
   *
   * `text`
   * : A string is returned if the response is OK; otherwise an error is thrown with the response
   *   status.
   *
   * `response`
   * : The request object is returned, regardless of whether it is OK; no errors are thrown.
   *
   * This allows this function to be used for calls expecting a specific return type as well as
   * calls that require inspecting the response.
   *
   * @param path    - The API path to call
   * @param options - Options to pass to fetch
   *
   * @returns if `options.returnType` is `response`, the response object; otherwise, if the response
   *          is OK, the type specified by `options.returnType`
   *
   * @throws an error the response is not OK and `options.returnType` is not `response`
   */
  async call<JsonData>(
    path: string,
    options: CallApiOptions = {}
  ): Promise<JsonData | string | Blob | ApiResponse> {
    const { returnType = 'json', requestType, ...init } = options;
    const response: ApiResponse = await fetch(`${ENV.apiHost}${path}`, {
      ...init,
      headers: {
        // TODO: Remove this once types have been merged into `ember-simple-auth` in
        //       [this PR](https://github.com/mainmatter/ember-simple-auth/pull/2514)
        //       [twl 20.Oct.23]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - the type isn't coming through from `ember-simple-auth`
        ...(this.session.isAuthenticated && {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - the type isn't coming through from `ember-simple-auth`
          'tio-auth-token': this.session.data.authenticated.access_token,
        }),
        'x-api-key': ENV.apiKey,
        'Content-Type': requestType === 'jsonApi' ? 'application/vnd.api+json' : 'application/json',
        ...init.headers,
      },
    });

    if (!response.ok) {
      const apiError = await this.#parseError(response);

      if (returnType === 'response') {
        response.apiError = apiError;
      } else {
        throw apiError;
      }
    }

    switch (returnType) {
      case 'json':
        return (await response.json()) as JsonData;

      case 'text':
        return await response.text();

      case 'blob':
        return await response.blob();

      case 'response':
        return response;

      default:
        throw new Error(`Unknown return type: ${returnType}`);
    }
  }

  /**
   * Calls the API with a POST request with the given path and options.
   *
   * See the `call` method for details about how the return value of this function differs based on
   * the `returnType` option.
   *
   * @param path    - The API path to call
   * @param payload - The payload to send with the POST request
   * @param options - Options to pass to fetch
   *
   * @returns if `options.returnType` is `response`, the response object; otherwise, if the response
   *          is OK, the type specified by `options.returnType`
   *
   * @throws an error the response is not OK and `options.returnType` is not `response`
   */
  async post<JsonData>(
    path: string,
    payload: unknown,
    options: CallApiOptions = {}
  ): Promise<JsonData | string | Blob | ApiResponse> {
    return this.call<JsonData>(path, {
      ...options,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async #parseError(response: Response): Promise<ApiError> {
    const message = response.statusText;
    const details = await this.#parseResponse(response);

    return new ApiError(message, response, details);
  }

  async #parseResponse(response: Response): Promise<object | string | Blob> {
    const contentType = response.headers.get('Content-Type');

    if (
      contentType?.startsWith('application/json') ||
      contentType?.startsWith('application/vnd.api+json')
    ) {
      return await response.json();
    } else if (contentType?.startsWith('text/plain')) {
      return await response.text();
    } else if (contentType?.startsWith('text/html')) {
      const text = await response.text();

      // NOTE: Devise used to be configured to return any errors in "HTML", but what it was actually
      //       returning was plaintext. This ensures we can handle this until the fix for Devise is
      //       deployed. [twl 25.Oct.23]
      if (text.trim().startsWith('<')) {
        console.error('Invalid HTML response from API:', text);

        return 'Invalid HTML response: see logs for details';
      } else {
        // TODO: Remove this and use only the code in the above block once Devise is fixed in
        //       [PR 2408](https://github.com/tuitionio/tio-api/pull/2408). [twl 25.Oct.23]
        return {
          error: text,
        };
      }
    } else {
      return await response.blob();
    }
  }
}
