import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface AppPageArticleSignature {
    Blocks: {
        default: [];
    };
}
const AppPageArticleComponent: TOC<AppPageArticleSignature> = template(`
  <article class="pt-4 pb-14 mx-1 md:px-3">
    {{yield}}
  </article>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppPageArticleComponent;
declare module '@ember/component' {
    export default interface ComponentRegistry {
        'App::Page::Article': typeof AppPageArticleComponent;
        AppPageArticle: typeof AppPageArticleComponent;
    }
}
