import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import PslfDashboardCardInfo from 'tio-employee/components/pslf/dashboard/card-info';
import PslfDashboardTodo from 'tio-employee/components/pslf/dashboard/todo/index';
import PslfDashboardTodoCheckbox from 'tio-employee/components/pslf/dashboard/todo/checkbox';
export default class RecommendationsAddAccountTodoComponent extends Component {
    @tracked
    isCompleted = false;
    @action
    toggleCompleted() {
        this.isCompleted = !this.isCompleted;
    }
    static{
        template(`
    <PslfDashboardTodo
      @title={{t "recommendations.add_an_account"}}
      @isComplete={{this.isCompleted}}
    >
      <:leftHeader>
        <PslfDashboardTodoCheckbox
          @checked={{this.isCompleted}}
          @onClick={{this.toggleCompleted}}
        />
      </:leftHeader>
      <:body>
        <LinkTo @route="authenticated.observability.upload" @query={{hash source="slr"}}>
          <PslfDashboardCardInfo
            @icon="link"
            @text={{t "recommendations.please_add_accounts"}}
            class="w-fit"
          />
        </LinkTo>
      </:body>
    </PslfDashboardTodo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
