import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type RouterService from '@ember/routing/router-service';
import type { AuthenticatedSyfRouteModel } from '../syf';

export default class AuthenticatedSyfDashboardRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  beforeModel() {
    if (!this.sessionContext.currentEmployee.matchParticipant?.isEnrolled) {
      this.router.transitionTo('authenticated.syf.enrollment');
    }
  }

  async model() {
    const person = await this.store.findRecord('person', this.sessionContext.user.person.id, {
      include: 'accounts.financial-institution',
      reload: true,
    });

    const matchParticipant = this.modelFor('authenticated.syf') as AuthenticatedSyfRouteModel;
    await matchParticipant.fetchInsights();

    return {
      accounts: person.activeAccounts,
      matchParticipant: matchParticipant,
      matchPlan: matchParticipant.matchPlan,
      matchPayments: matchParticipant.matchPayments,
      accountTransactions: matchParticipant.person.accountTransactions,
    };
  }
}
