import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import Tile from '../tile';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsStudentLoanCoachesComponent: TOC = template(`
  <LinkTo @route="authenticated.repaying-student-debt.askjeni">
    <Tile @headerText={{t "dashboard_tiles.student_loan_coaches"}}>
      <:description>
        {{t "dashboard_tiles.schedule_expert_call"}}
      </:description>
      <:image>
        {{svgJar
          "woman-on-laptop-working"
          width="80%"
          height="100%"
          role="img"
          desc=(t "svg.woman_on_laptop_working")
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsStudentLoanCoachesComponent;
