import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
let ReportingIndexRoute = class ReportingIndexRoute extends Component {
    static{
        template(`{{outlet}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ReportingIndexRoute);
