import ApplicationAdapter from './application';

/**
 * An extended adapter for the `Loan` model.
 *
 * @memberof adapters
 */
class LoanAdapter extends ApplicationAdapter {
  /**
   * @param {string}  userId - an ID of a user record
   *
   * @return {object}
   */
  async setCustomObservationData(loanId: string, data = {}) {
    if (!loanId) {
      return;
    }
    const url = this.buildURL('loan', loanId) + '/observation-overrides';
    const response = await this.ajax(url, 'PATCH', {
      data: JSON.stringify(data),
    });
    return response;
  }
}

export default LoanAdapter;
