import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import type Model from '@ember-data/model';

/**
 * A service that provides higher level data layer functions, so the intent of
 * code is clearer and implementations can later be optimized.
 *
 * @memberof services
 */
class DataService extends Service {
  @service declare store: typeof Store;

  /**
   * Ensures the `includes` for the `entity` are loaded into the store. Call
   * this before
   *
   * @param {Model}           entity   A parent entity
   * @param {string|string[]} includes An array or comma-separated list of
   *                                   includes
   */
  async ensureIncludesLoaded(entity: typeof Model, includes: string | string[]) {
    await this.store.findRecord(entity.constructor.modelName, entity.id, {
      include: Array.isArray(includes) ? includes.join(',') : includes,
      reload: true,
    });
  }
}

export default DataService;
