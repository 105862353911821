import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsRepaymentPlansComponent: TOC = template(`
  <LinkTo @route="authenticated.questions-you-have.repayment-plans">
    <Tile @headerText={{t "dashboard_tiles.fed_repayment_plans"}}>
      <:description>
        <span>{{t "dashboard_tiles.learn_fed_repayment"}}</span>
        <p class="font-semibold my-4 text-xl">{{t "dashboard_tiles.what_are_lrps"}}</p>
        <span>{{t "dashboard_tiles.lrp_blurb"}}</span>
      </:description>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsRepaymentPlansComponent;
