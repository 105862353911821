/**
 *
 * @param {object[]} array
 *
 * @return {boolean}
 */

export interface BulletList {
  bullets: string[];
}

export default function checkBulletListsAreSame(array: BulletList[]) {
  // Check if the length of the bullets array and the contents of the bullets array are the same for each object within the array
  return array.every((curr) =>
    array.every(
      (object) =>
        object.bullets.length === curr.bullets.length &&
        curr.bullets.every((point) => object.bullets.includes(point))
    )
  );
}
