import Inflector from 'ember-inflector';

export function initialize(/* application */) {
  const inflector = Inflector.inflector;

  // Tell the inflector that the plural of "search" is "search"
  // for building URLS for requests to GET /search?q=whatever
  inflector.uncountable('search');
}

export default {
  name: 'custom-inflector-rules',
  initialize,
};
