import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { eq } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AccountLinkingFooter from './footer';
import AccountLinkingTermsComponent from './terms';
import type AccountModel from 'tio-common/models/account';
import Component from '@glimmer/component';
import finicity from './modifiers/finicity';
import InstitutionPicker from './institution-picker';
import purify from 'tio-common/helpers/purify';
import spinwheel from './modifiers/spinwheel';
import spreadsheet from './modifiers/spreadsheet';
import type Store from '@ember-data/store';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
import type { EmbedInfoSignature } from './types';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type RemoteEventLoggerService from 'tio-employee/services/remote-event-logger';
import type SessionContextService from 'tio-employee/services/session-context';
import waitForChildElements from './modifiers/wait-for-child-elements';
export type SpinwheelContext = {
    action: string;
    instanceId: string;
    location: {
        hash: string;
        key: string;
        pathname: string;
        search: string;
        state: undefined;
    };
    triggerType: string;
};
export interface AccountLinkingSlrSignature {
    Args: {
        /**
     * Caller can use this to pass in a callback to be called when the account
     * linking is complete.
     *
     * @remarks
     * Ideal usecase would be for where to route.
     */ onSuccess: (institution: FinancialInstitutionModel) => void;
        onCanceled: () => void;
    };
}
/**
 * A component is responsible for the asecure ccount linking flow.
 *
 * @remarks
 * Note to future self (or other devs). It may be tempting to combine
 * with the other account linking components. However, this component
 * contains specific flows to SLR Account Linking. The other components
 * will be used for other account linking flows rather than trying to have a
 * super component with all sorts of convoluted logic.
 */ let AccountLinkingSlrComponent = class AccountLinkingSlrComponent extends Component<AccountLinkingSlrSignature> {
    @service
    store: typeof Store;
    @service
    sessionContext: SessionContextService;
    @service
    remoteEventLogger: RemoteEventLoggerService;
    @tracked
    account?: AccountModel;
    @tracked
    displayLoadingMessages = false;
    @tracked
    embedInfo: EmbedInfoSignature;
    @tracked
    institution?: FinancialInstitutionModel;
    @tracked
    isLoading = false;
    @tracked
    currentStep = 'terms';
    get selectedProvider() {
        return this.institution?.provider;
    }
    get isMohela() {
        return /m\s*o\s*h\s*e\s*l\s*a/i.test(this.institution?.name || '');
    }
    // In general this is misleading. We're not actually loading the account info.
    loadAccountTask = dropTask(async (institutionId1: string, step1: number = 1)=>{
        return this.store.adapterFor('account').fetchLinkingInfo(this.sessionContext.user.id, institutionId1, step1);
    });
    @action
    toggleLoadingMessagesDisplay() {
        this.displayLoadingMessages = false;
    }
    @action
    async didLinkAccount() {
        if (this.institution) {
            this.args.onSuccess(this.institution);
        }
        this.displayLoadingMessages = false;
    }
    @action
    async didSelectInstitution(institution1: FinancialInstitutionModel) {
        this.displayLoadingMessages = true;
        this.embedInfo = await this.loadAccountTask.perform(institution1.legacyId);
        this.embedInfo.financialInstitution = institution1;
        this.institution = institution1;
        this.currentStep = 'servicer';
    }
    @action
    didAcceptTerms() {
        this.currentStep = 'institution';
    }
    @action
    async onAnyEvent(context1: SpinwheelContext, type1: string) {
        console.log('onAny', type1, context1);
        this.remoteEventLogger.log('spinwheel', purify(context1, '***OBFUSCATED***'), 'INFO', 'LoginSpinwheel', this.institution);
        if (type1 === 'success') {
            this.didLinkAccount();
            this.currentStep = 'verify';
        }
        switch(type1){
            case 'success':
                if (this.institution) {
                    this.didLinkAccount();
                    this.currentStep = 'verify';
                }
                break;
            case 'page change':
                if (context1.action === 'POP') {
                    this.currentStep = 'institution';
                    delete this.institution;
                }
                break;
        }
    }
    static{
        template(`
    {{! template-lint-disable no-inline-styles }}
    {{if this.loadAccountTask.isRunning TioLoadingSpinner}}
    {{#if (eq this.currentStep "terms")}}
      <AccountLinkingTermsComponent @onAccepted={{this.didAcceptTerms}} />
    {{/if}}
    {{#if (eq this.currentStep "institution")}}
      <InstitutionPicker @onChange={{this.didSelectInstitution}} />
    {{/if}}
    {{#if (eq this.currentStep "servicer")}}
      {{#if (eq this.selectedProvider "SPINWHEEL")}}
        <div
          style="height: 1000px"
          {{spinwheel embedInfo=this.embedInfo onAnyEvent=this.onAnyEvent}}
        />
      {{/if}}
      {{#if (eq this.selectedProvider "FINICITY_CONNECT_LITE")}}
        <div
          style="height: 1000px; width: 100%;"
          {{finicity embedInfo=this.embedInfo onAccountLink=this.didLinkAccount}}
        />
      {{/if}}
      {{#if (eq this.selectedProvider "SPREADSHEET")}}
        <div {{waitForChildElements this.toggleLoadingMessagesDisplay}}>
          <div
            style="height: 1000px; width: 100%;"
            {{spreadsheet
              embedInfo=this.embedInfo
              onAccountLink=this.didLinkAccount
              store=this.store
            }}
          />
        </div>
      {{/if}}
    {{/if}}
    <AccountLinkingFooter @isMohela={{this.isMohela}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default AccountLinkingSlrComponent;
