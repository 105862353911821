import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, eq, not, or } from 'tio-ui/utilities';
import { concat } from '@ember/helper';
import { currencyStringToNumber } from 'tio-common/utils/format';
import { fn } from '@ember/helper';
import { Input, Textarea, Select } from 'tio-ui/components/forms';
import { inputsForFields } from 'tio-common/utils/data/inputs-for-fields';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { getSingleValueForTasField, setSingleValueForTasField, type FieldName } from 'tio-common/utils/tuition-assistance/fields';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import Drawer from 'tio-ui/components/drawer';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import inputmask from 'tio-common/modifiers/inputmask';
import type { CustomFieldSignature } from 'tio-common/types/tuition-assistance';
import type { TOC } from '@ember/component/template-only';
import type Store from '@ember-data/store';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import { Button } from 'tio-ui/components/buttons';
interface AddEducationDrawerSignature {
    Args: {
        isOpen: boolean;
        onClose: () => void;
        application: TasApplication;
        course: TasCourse | null;
        header?: string;
    };
    Element: HTMLDivElement;
}
export interface BuiltInput {
    id?: string;
    name: keyof TasCourse['fields'];
    label: string;
    type: string;
    inputType: string;
    required: boolean;
    values: (string | number)[];
    errors: string[];
    options?: {
        value: string;
        label: string;
    }[];
    selectionMode?: 'single' | 'multiple';
    custom?: boolean;
    visible: boolean;
    ordinal: number;
    province: string;
    rules: {
        type: 'string' | 'number';
        typeError?: 'string';
    };
    value_as_field_option?: boolean;
    toArray?: () => void;
}
export interface CustomInput extends CustomFieldSignature {
    custom: boolean;
    name?: string;
}
export interface CourseFieldsHash {
    [key: string]: {
        values: (string | number)[];
    };
}
interface RequiredAsteriskSignature {
    Args: {
        inputField: BuiltInput;
        application: TasApplication;
    };
    Element: HTMLSpanElement;
}
const RequiredAsterisk: TOC<RequiredAsteriskSignature> = template(`
  {{#if (eq @application.typeClassification "TAS.ProgramType.2")}}
    {{#if @inputField.required}}
      <span class="text-error-400">*</span>
    {{/if}}
  {{else if (and @inputField.required (not (eq @inputField.name "COURSE_GRADE")))}}
    <span class="text-error-400">*</span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class AddEducationDrawerComponent extends Component<AddEducationDrawerSignature> {
    @service
    store: typeof Store;
    inputs: BuiltInput[] = [];
    @tracked
    customInputs: CustomInput[] = [];
    @tracked
    selectedKeys: string[] = [];
    @tracked
    trackedErrors: Record<string, string[]> = {};
    programTemplate: TasProgramTemplate;
    courseFields: TasCourse['fields'] = {} as TasCourse['fields'];
    customFields: TasCourse['customFields'] = [];
    createInputsForNewCourse(application1: TasApplication): BuiltInput[] {
        const builtInputs1 = [];
        const programTemplate1 = application1.tasProgramInstance.tasProgramTemplate;
        const courseFieldsHash1 = structuredClone(programTemplate1.courseFieldsHash);
        for(const field1 in courseFieldsHash1){
            // Merge the field data with input data
            const fieldInput1 = structuredClone(inputsForFields[field1 as keyof typeof inputsForFields]);
            const programField1 = structuredClone(programTemplate1.fields[field1 as keyof typeof programTemplate.fields]);
            const mergedInput1 = Object.assign(fieldInput1, programField1);
            builtInputs1.push(mergedInput1);
        }
        return builtInputs1.sort((a1, b1)=>a1.ordinal - b1.ordinal) as BuiltInput[];
    }
    createInputsToEditCourse(application1: TasApplication, course1: TasCourse): BuiltInput[] {
        const builtInputs1 = [];
        const programTemplate1 = application1.tasProgramInstance.tasProgramTemplate;
        // Deep clone courseFieldsHash to avoid any unintended mutations
        const courseFieldsHash1 = structuredClone(programTemplate1.courseFieldsHash);
        for(const field1 in courseFieldsHash1){
            // Clone template input and template field to prevent mutations
            const templateInput1 = structuredClone(inputsForFields[field1 as keyof typeof inputsForFields] || {});
            const templateField1 = structuredClone(programTemplate1.fields[field1 as keyof typeof programTemplate.fields] || {});
            // Get the corresponding course field if it exists
            const courseField1 = structuredClone(course1.fields[field1 as keyof typeof course.fields] || {});
            // Merge the course field values into the input, if the course field exists
            const mergedValues1 = courseField1.values ? {
                values: courseField1.values
            } : {};
            // Merge all inputs, ensuring immutability
            const mergedInput1 = Object.assign({}, templateInput1, templateField1, mergedValues1);
            builtInputs1.push(mergedInput1);
        }
        return builtInputs1.sort((a1, b1)=>a1.ordinal - b1.ordinal) as BuiltInput[];
    }
    createCustomInputs(customFields1: CustomFieldSignature[]): CustomInput[] {
        return customFields1.map((customField1)=>({
                ...customField1,
                custom: true
            }));
    }
    createErrorsObject(courseFieldsHash1: CourseFieldsHash[], customFields1: CustomFieldSignature[]): Record<string, string[]> {
        const errors1: Record<string, string[]> = {};
        for(const field1 in courseFieldsHash1){
            errors1[field1] = [];
        }
        for (const field1 of customFields1){
            errors1[field1.label] = [];
        }
        return errors1;
    }
    constructor(owner1: unknown, args1: AddEducationDrawerSignature['Args']){
        super(owner1, args1);
        const { application: application1, course: course1 } = args1;
        this.inputs = course1!.isNew ? new TrackedArray(this.createInputsForNewCourse(application1)) : new TrackedArray(this.createInputsToEditCourse(application1, course1!));
        this.courseFields = new TrackedObject(course1!.fields);
        this.customFields = new TrackedArray(course1!.customFields);
        this.customInputs = new TrackedArray(this.createCustomInputs(course1!.customFields));
        this.programTemplate = application1.tasProgramInstance.tasProgramTemplate;
        this.trackedErrors = new TrackedObject(this.createErrorsObject(this.programTemplate.courseFieldsHash, course1!.customFields));
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    getErrorForInput(input1: BuiltInput | CustomInput, trackedErrors1: Record<string, string[]>): string[] {
        if (input1.custom) {
            return trackedErrors1[input1.label] || [];
        } else {
            return trackedErrors1[input1.name!] || [];
        }
    }
    // This function is required for type three applications to by pass grade requirement here
    checkForRequired(input1: BuiltInput) {
        if (input1.name === 'COURSE_GRADE') {
            return false;
        }
        if (input1.required) {
            return true;
        }
        return false;
    }
    @action
    checkForErrors(): void {
        for (const input1 of this.inputs){
            // Check if the input is required
            if (this.checkForRequired(input1)) {
                if (input1.values.length === 0) {
                    this.trackedErrors[input1.name] = [
                        'This field is required'
                    ];
                } else {
                    delete this.trackedErrors[input1.name];
                }
            }
            // Check for correct type (if there are values)
            if (input1.values.length > 0) {
                const value1 = input1.values[0];
                // If the expected type is "number"
                if (input1.type === 'number') {
                    if (isNaN(Number(value1))) {
                        this.trackedErrors[input1.name] = [
                            'Value must be a number'
                        ];
                    } else {
                        delete this.trackedErrors[input1.name];
                    }
                }
                // If the expected type is "string"
                if (input1.type === 'string') {
                    if (typeof value1 !== 'string') {
                        this.trackedErrors[input1.name] = [
                            'Value must be a string'
                        ];
                    } else {
                        delete this.trackedErrors[input1.name];
                    }
                }
            }
        }
    }
    @action
    checkForCustomInputErrors() {
        for (const input1 of this.customInputs){
            // check if required
            if (input1.required) {
                if (input1.values.length === 0) {
                    this.trackedErrors[input1.label] = [
                        'This field is required'
                    ];
                } else {
                    delete this.trackedErrors[input1.label];
                }
            }
        }
    }
    @action
    noErrors(): boolean {
        for(const key1 in this.trackedErrors){
            if (Object.prototype.hasOwnProperty.call(this.trackedErrors, key1)) {
                if (this.trackedErrors[key1]?.length !== 0) {
                    return false;
                }
            }
        }
        return true;
    }
    @action
    async addEducation() {
        this.checkForErrors();
        this.checkForCustomInputErrors();
        if (this.noErrors()) {
            try {
                this.setApprovedCourseTotalBeforeSave();
                this.args.course!.fields = this.courseFields;
                this.args.course!.customFields = this.customFields;
                await this.args.course!.save();
                this.inputs = [];
                this.customInputs = [];
                this.courseFields = {} as TasCourse['fields'];
                this.customFields = [];
                this.args.onClose();
            } catch (error1) {
                console.error(error1);
            }
        }
    }
    @action
    getValue<T extends string | number | boolean | undefined>(values1: (string | number | boolean)[]): T | undefined {
        const firstValue1 = values1[0];
        if (firstValue1 === '' || firstValue1 === undefined) {
            return undefined as T;
        }
        return firstValue1 as T;
    }
    boolify(value1: string): boolean {
        return !!value1;
    }
    get courseExpenses() {
        const expenseInputs1: FieldName[] = [
            'COURSE_TUITION',
            'COURSE_BOOKS',
            'COURSE_LABS',
            'COURSE_SOFTWARE',
            'COURSE_FEES'
        ];
        const reduced1 = expenseInputs1.reduce((acc1, input1)=>{
            const value1 = getSingleValueForTasField(input1, this.courseFields) as number;
            return acc1 + (isNaN(value1) ? 0 : value1);
        }, 0);
        return reduced1;
    }
    setApprovedCourseTotalBeforeSave() {
        setSingleValueForTasField('APPROVED_COURSE_TOTAL', this.courseTotal, this.courseFields);
    }
    get courseTotal() {
        return this.programTemplate.calculateTotalByCredit ? this.reimbursementTotalByCredit : this.courseExpenses;
    }
    get reimbursementTotalByCredit() {
        return ((this.programTemplate.ratePerCredit ?? 0) * ((getSingleValueForTasField('COURSE_CREDIT', this.courseFields) as number) ?? 0));
    }
    @action
    setValue(input1: BuiltInput, value1: string | number | boolean) {
        if (input1.type === 'currency') {
            value1 = currencyStringToNumber(value1 as string);
        }
        if (input1.type === 'number') {
            // course credits can be halves ex: 2.5
            value1 = parseFloat(value1 as string);
            if (isNaN(value1)) {
                this.trackedErrors[input1.name] = [
                    'This field must be a number'
                ];
            } else {
                delete this.trackedErrors[input1.name];
            }
        }
        // @ts-expect-error: boolean not assignable to string | number
        input1.values = [
            value1
        ];
        if (input1.custom) {
            const index1 = input1.ordinal - 1;
            this.customFields[index1] = {
                ...this.customFields[index1],
                values: [
                    value1
                ] as string[] | boolean[]
            } as CustomFieldSignature;
        } else {
            setSingleValueForTasField(input1.name, value1, this.courseFields);
        }
    }
    static{
        template(`
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}} @allowCloseButton={{false}} @size="xl">
      <div class="p-6 bg-gray-50">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button type="button" data-test-close-education-drawer {{on "click" @onClose}}>
            <XMark class="h-6 w-6" />
          </button>
        </div>
        <h2 class="text-gray-600 font-semibold">
          {{@header}}</h2>
        <p class="text-gray-400 text-md">{{t
            "tas.add_education_drawer.fill_out_education_below"
          }}</p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">
            {{or @header (t "tas.add_education_drawer.education_information")}}
          </h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "tas.add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      {{#each this.inputs as |input|}}
        {{#if input.visible}}
          <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
            <label for={{input.name}} class="font-semibold text-gray-600">
              {{input.label}}
              <RequiredAsterisk @inputField={{input}} @application={{@application}} />
            </label>
            {{! regular input with type currency }}
            {{#if (and (eq input.inputType "input") (eq input.type "currency"))}}
              <Input
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value="{{divide (or (this.getValue input.values) 0) 100}}"
                @onChange={{fn this.setValue input}}
                {{inputmask
                  alias="currency"
                  prefix="\$"
                  unmaskAsNumber=true
                  digits="2"
                  autoUnmask=true
                }}
                @errors={{this.getErrorForInput input this.trackedErrors}}
                data-test-education-drawer-input-currency
              />
            {{/if}}
            {{! regular input with type string }}
            {{#if (and (eq input.inputType "input") (eq input.type "string"))}}
              <Input
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              />
            {{/if}}
            {{! regular input with type number }}
            {{#if (and (eq input.inputType "input") (eq input.type "number"))}}
              <Input
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              />
            {{/if}}
            {{#if (eq input.inputType "textarea")}}
              <Textarea
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              />
            {{/if}}
            {{#if (eq input.inputType "radio")}}
              <FormRadioGroup
                data-test={{input.name}}
                @value={{this.getValue input.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue input}}
                {{!-- @errors={{errorsForField "employmentStatus" schemaErrors=this.formValidationErrors}} --}}
                @containerClass="py-4"
                as |Radio|
              >
                {{#each input.options as |option|}}
                  {{! @glint-expect-error }}
                  <Radio @value={{option.value}} @label={{option.label}} />
                {{/each}}
              </FormRadioGroup>
            {{/if}}
            {{#if (eq input.inputType "select")}}
              <Select
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @items={{input.options}}
                @selectionMode={{input.selectionMode}}
                @onAction={{fn this.setValue input}}
                {{! @glint-expect-error }}
                @selectedKeys={{input.values}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              >
                <:item as |o|>
                  <o.Item @key={{o.item.value}} @intent="default" @appearance="faded">
                    {{o.item.value}}
                  </o.Item>
                </:item>
              </Select>
            {{/if}}
          </div>
        {{/if}}
      {{/each}}
      {{#each this.customInputs as |customInput index|}}
        {{#if customInput.visible}}
          <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
            <label class="font-semibold text-gray-600" for={{customInput.name}}>
              {{customInput.label}}
              {{#if customInput.required}}
                <span class="text-error-400">*</span>
              {{/if}}
            </label>
            {{#if (eq customInput.input "textarea")}}
              <Textarea
                @classes={{this.inputClasses}}
                @value={{this.getValue customInput.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue customInput}}
                @errors={{this.getErrorForInput customInput this.trackedErrors}}
                data-test={{concat "custom-input-textarea-" index}}
              />
            {{/if}}
            {{#if (eq customInput.input "radio")}}
              <FormRadioGroup
                @value={{this.getValue customInput.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue customInput}}
                @errors={{this.getErrorForInput customInput this.trackedErrors}}
                @containerClass="py-4"
                data-test={{concat "custom-input-radio-" index}}
                as |Radio|
              >
                {{#each customInput.options as |option|}}
                  {{! @glint-expect-error }}
                  <Radio @value={{option.value}} @label={{option.label}} />
                {{/each}}
              </FormRadioGroup>
            {{/if}}
          </div>
        {{/if}}
      {{/each}}
      <div class="flex justify-start items-center gap-4 py-4">
        <Button @appearance="minimal" data-test-cancel-education-drawer {{on "click" @onClose}}>
          {{t "common.cancel"}}
        </Button>
        <Button
          type="submit"
          @intent="primary"
          data-test-save-education-drawer
          {{on "click" this.addEducation}}
        >
          {{if @course.isNew (t "common.add") (t "common.save")}}
        </Button>
      </div>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
