import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedTasConfirmationRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;

  includes = ['tas-courses', 'tas-program-instance.tas-program-template'];

  beforeModel(transition: Transition) {
    if (transition.to?.queryParams.tasType === undefined) {
      this.router.transitionTo('authenticated.tas.dashboard');
    }
  }
  async model(params: { tasType: string; applicationId: string; evidence: boolean }) {
    const application = await this.store.findRecord('tas-application', params.applicationId, {
      include: this.includes.join(','),
    });
    return { application, tasType: params.tasType, evidence: params.evidence };
  }
}
