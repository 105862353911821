import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import QuestionsLoanTypesCard from 'tio-employee/components/questions/loan-types-card';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface CircleSignature {
    Args: {
        label: string;
    };
}
const Circle: TOC<CircleSignature> = template(`
  <div
    class="shrink-0 w-7 h-7 bg-cyan-600 mr-2 text-white rounded-full text-center items-center inline-block"
  >
    {{@label}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(template(`
    <h3 class="text-center font-semibold">
      {{t "questions_you_have.types_of_student_loans.pros.pros_plus"}}
    </h3>
    <div class="flex-row mt-4 grid grid-cols-3 justify-center">
      <QuestionsLoanTypesCard
        @src="/assets/images/questions/icons/percent.svg"
        @label={{t "questions_you_have.types_of_student_loans.pros.generally_lower_interest"}}
      />
      <div class="border-x">
        <QuestionsLoanTypesCard
          @src="/assets/images/questions/icons/pause.svg"
          @label={{t
            "questions_you_have.types_of_student_loans.pros.repayment_options_more_favorable_than"
          }}
        />
      </div>
      <QuestionsLoanTypesCard
        @src="/assets/images/questions/icons/dollar.svg"
        @label={{t "questions_you_have.types_of_student_loans.pros.qualify_for_loan_forgiveness"}}
      />
    </div>
    <div class="text-center mt-4">
      <h3 class="font-semibold">{{t
          "questions_you_have.types_of_student_loans.cons.cons_plus"
        }}</h3>
      <div class="flex-row mt-4 grid grid-cols-3 justify-center">
        <QuestionsLoanTypesCard
          @src="/assets/images/questions/icons/bars.svg"
          @label={{t "questions_you_have.types_of_student_loans.cons.plus_con1"}}
        />
        <div class="border-x">
          <QuestionsLoanTypesCard
            @src="/assets/images/questions/icons/alert.svg"
            @label={{t "questions_you_have.types_of_student_loans.cons.plus_con2"}}
          />
        </div>
        <QuestionsLoanTypesCard
          @src="/assets/images/questions/icons/double-chevrons-pointing-inward.svg"
          @label={{t "questions_you_have.types_of_student_loans.cons.plus_con3"}}
        />
      </div>
    </div>

    <div class="p-4">
      <h1 class="py-4 font-medium text-xl">
        {{t "questions_you_have.how_to_apply.default"}}
      </h1>
      <div class="py-4">
        <h3 class="text-lg font-semibold truncate text-left">
          <Circle @label={{t "number_one"}} />
          {{t "questions_you_have.how_to_apply.pplus_ask_yourself.default" htmlSafe=true}}
        </h3>
        <ul class="list-disc ml-12 mb-4">
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.pplus_ask_yourself.point_1"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.pplus_ask_yourself.point_2"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.pplus_ask_yourself.point_3"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.pplus_ask_yourself.point_4"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.pplus_ask_yourself.point_5"}}
          </li>
        </ul>
      </div>
      <div class="py-4">
        <h3 class="text-lg font-semibold truncate text-left">
          <Circle @label={{t "number_two"}} />
          {{t "questions_you_have.how_to_apply.call_fa_office.default" htmlSafe=true}}
        </h3>
        <ul class="list-disc ml-12 mb-4">
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.pplus_ask_yourself.point_1"}}
          </li>
        </ul>
      </div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
