import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface AppPageContentSignature {
    Blocks: {
        default: [];
    };
}
const AppPageContentComponent: TOC<AppPageContentSignature> = template(`
  <div class="pt-4 pb-14 mx-1 md:px-3">
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppPageContentComponent;
