import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AccountLinkingSlr from 'tio-employee/components/account-linking/slr';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { t } from 'ember-intl';
import { Header, VStack } from 'tio-ui/components/layout';
let AccountActivityNewRouteComponent = class AccountActivityNewRouteComponent extends Component {
    @service
    router: RouterService;
    @action
    onLinkingSuccess() {
        this.router.transitionTo('authenticated.observability.upload');
    }
    @action
    didCancel() {
        this.router.transitionTo('authenticated.observability.upload');
    }
    static{
        template(`
    <VStack>
      <Header>{{t "account_linking.add_account"}}</Header>
      <AccountLinkingSlr @onSuccess={{this.onLinkingSuccess}} @onCanceled={{this.didCancel}} />
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AccountActivityNewRouteComponent);
