import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import MaterialIcon from 'tio-common/components/material-icon';
import TioButton from 'tio-common/components/tio/button';
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
let SyfInsightsPslfRouteComponent = class SyfInsightsPslfRouteComponent extends Component {
    static{
        template(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.syf.dashboard" @label="Dashboard" />
      <b.crumb @route="authenticated.syf.insights.pslf" @label="PSLF" />
    </TioPageBreadcrumbs>
    <div class="mx-12">
      <h1 class="text-xl text-violet-800 font-semibold">
        {{t "syf.insights.pslf.heading"}}
      </h1>
      <div class="grid grid-cols-3">
        <div>
          <h2 class="text-lg mt-4">{{t "syf.insights.pslf.explore.heading"}}</h2>
          <div class="flex mt-4">
            <div class="ml-4">
              {{t "syf.insights.pslf.explore.one" htmlSafe=true}}
            </div>
          </div>
          <div class="flex mt-4">
            <div class="ml-4">
              {{t "syf.insights.pslf.explore.two"}}
            </div>
          </div>
        </div>
        <div class="col-span-2 ml-12">
          <h2 class="text-lg mt-4 font-semibold">{{t "syf.insights.pslf.best_options.heading"}}</h2>
          <div class="my-4 flex">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t "syf.insights.pslf.best_options.one"}}
            </div>
          </div>
          <div class="my-4 flex">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t "syf.insights.pslf.best_options.two"}}
            </div>
          </div>
          <div class="my-4 flex">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t "syf.insights.pslf.best_options.three"}}
            </div>
          </div>
          <div class="my-4 flex">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t "syf.insights.pslf.best_options.four"}}
            </div>
          </div>
          <hr />
          <div class="my-4 flex">
            <MaterialIcon @icon="priority_high" class="text-red-600" />
            <div class="ml-4 font-bold">
              {{t "syf.insights.pslf.best_options.action"}}
            </div>
          </div>
          {{t "syf.insights.pslf.best_options.label"}}
          <div class="flex mt-4">
            <TioButton @linkTo="authenticated.idr">
              {{t "syf.insights.pslf.best_options.button"}}
            </TioButton>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SyfInsightsPslfRouteComponent);
