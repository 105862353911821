import { registerDeprecationHandler } from '@ember/debug';
import ENV from 'tio-employee/config/environment';

export function initialize() {
  registerDeprecationHandler((message, options, next) => {
    if (ENV.disableDeprecations === 'true' && options?.until !== '2.0.0') {
      return;
    } else {
      next(message, options);
    }
  });
}

export default { initialize };
