import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router';

export default class AuthenticatedIdrIndexRoute extends Route {
  @service declare router: RouterService;

  redirect() {
    this.router.transitionTo('authenticated.idr.dashboard.index');
  }
}
