import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type RouterService from '@ember/routing/router-service';
import type SessionService from 'tio-employee/services/session';
import type CompanySetting from 'tio-common/models/company-setting';

const hasLandingPage = (settings: CompanySetting) =>
  settings?.landingPage && settings.landingPage?.isEnabled;

export default class LoginRoute extends Route {
  @service declare preRegister: PreRegisterService;
  @service declare router: RouterService;
  @service declare session: SessionService;

  queryParams = {
    email: { refreshModel: false },
  };

  beforeModel() {
    const { preRegister, session, router } = this;

    // Looks at the attemptedTransition and saves it to the session storage for redirect after auth
    this.session.setRouteAfterAuthentication();

    if (hasLandingPage(preRegister.settings) && !session.getTransientValue('landingPageViewed')) {
      router.transitionTo('welcome');
    } else if (preRegister.settings?.ssoAuthUrl) {
      window.location.href = preRegister.settings.ssoAuthUrl;
    }
  }
}
