import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioEmbedVue from 'tio-common/components/tio/embed-vue';
import type PartnerService from 'tio-employee/services/partner';
let AuthenticatedTuitionAssistanceRouteComponent = class AuthenticatedTuitionAssistanceRouteComponent extends Component {
    @service
    partner: PartnerService;
    get isNewTuitionAssistance() {
        return !this.partner.hasLegacyTuitionAssistance;
    }
    static{
        template(`
    {{pageTitle (t "tuition_assistance.default")}}

    <TioEmbedVue @route="/tuition-assistance" @disabled={{this.isNewTuitionAssistance}}>
      {{outlet}}
    </TioEmbedVue>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTuitionAssistanceRouteComponent);
