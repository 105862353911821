import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import type PslfProfileModel from 'tio-common/models/pslf-profile';

export default class AuthenticatedPslfSingleFormRoute extends Route {
  @service declare router: RouterService;

  model() {
    return this.modelFor('authenticated.pslf') as PslfProfileModel;
  }
}
