import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type PartnerService from 'tio-employee/services/partner';
import type RouterService from '@ember/routing/router';

type Filter = {
  'start-date': string;
  status: string;
  'company-id': string;
};

type Query = {
  filter?: Filter;
  page: {
    limit: number;
    offset: number;
  };
  include?: string;
  sort: string;
};

export default class AuthenticatedAdminEligibilityFilesIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare partner: PartnerService;
  @service declare router: RouterService;

  queryParams = {
    startDate: { refreshModel: true, replace: true },
    status: { refreshModel: true, replace: true },
    sort: { refreshModel: true, replace: true },
    page: { refreshModel: true, replace: true },
    limit: { refreshModel: true, replace: true },
  };
  includes = ['company.user'];

  async model(params: {
    status: string;
    startDate: string;
    sort: string;
    page: number;
    limit: number;
  }) {
    const { sort, startDate, page, limit, status } = params;

    const filter: Partial<Query['filter']> = {};

    if (startDate) {
      filter['start-date'] = startDate;
    }

    // TODO: This seems like it should just be handled by the query param, but quick fix for now

    if (status && status.toLocaleLowerCase() !== 'all') {
      filter['status'] = status;
    } else {
      delete filter['status'];
    }

    filter['company-id'] = this.partner.company?.id;

    const query = {
      filter,
      sort,
      page: {
        limit,
        offset: (page - 1) * limit,
      },
      include: this.includes.join(','),
    };

    return this.store.query('eligibility-file-job-log', query);
  }
}
