import Route from '@ember/routing/route';

export default class AuthenticatedPslfMyDocumentsRoute extends Route {
  limit = 500;

  model() {
    const pslfProfile = this.modelFor('authenticated.pslf');
    return pslfProfile;
  }
}
