import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PreRegisterService from '../services/pre-register';
import type RouterService from '@ember/routing/router-service';

export default class WelcomeRoute extends Route {
  @service declare preRegister: PreRegisterService;
  @service declare router: RouterService;

  beforeModel() {
    // Redirect to login if no landing page settings are set
    const landingPage = this.preRegister.settings?.landingPage;
    if (!landingPage || !Object.values(landingPage).some((value) => !!value)) {
      this.router.transitionTo('/login');
    }
  }
}
