import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

type RouteParams = {
  observable_document_id: number;
};

export default class AuthenticatedObservabilityDocumentsShow extends Route {
  @service declare store: StoreService;

  async model(params: RouteParams) {
    const id = params['observable_document_id'];

    const observableDocument = await this.store.findRecord('observable-document', id, {
      include: 'observable-asset,observable-document-logs',
      // include: 'observable-asset',
    });

    return observableDocument;
  }
}
