import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import quatromoney from 'tio-employee/modifiers/quatromoney';
import RouteTemplate from 'ember-route-template';
import style from 'ember-style-modifier/modifiers/style';
export default RouteTemplate(template(`
    <div
      {{quatromoney}}
      {{style width="100%" margin="2rem"}}
      id="quatromoney-widget"
      data-partner="61968f74e5d23"
      data-widget="61afe0e6e72f8"
    >
      {{t "login.loading"}}
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
