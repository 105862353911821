import Route from '@ember/routing/route';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';

export default class AuthenticatedTasProgramsShowHistoryRoute extends Route {
  async model() {
    const { programInstance } = this.modelFor(
      'authenticated.tas.programs'
    ) as TASProgramInstanceModel;

    await programInstance.loadHistory();

    return programInstance;
  }
}
