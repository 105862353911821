import Route from '@ember/routing/route';
import type Controller from '@ember/controller';

export default class ProfileRoute extends Route {
  resetController(controller: Controller, isExiting: boolean) {
    if (isExiting) {
      controller.set('parentDashboard', undefined);
    }
  }
}
