import { template } from "@ember/template-compiler";
import { Header, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import PslfWhatNext from 'tio-employee/components/pslf/what-next';
import RouteTemplate from 'ember-route-template';
import type PslfFormModel from 'tio-common/models/pslf-form';
interface S {
    Args: {
        model: PslfFormModel;
    };
}
export default RouteTemplate<S>(template(`
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      <PslfWhatNext @model={{@model}} />
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
