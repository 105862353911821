import ApplicationAdapter from './application';

/**
 * An extended adapter for the `Account` model.
 *
 * @memberof adapters
 */
class AccountAdapter extends ApplicationAdapter {
  async fetchLinkingInfo(userId: string, institutionId: string, step = 1) {
    const pointlessSpreadsheetCode = localStorage.getItem('spreadsheet-provider');
    let data: unknown;

    if (pointlessSpreadsheetCode) {
      const d = JSON.parse(pointlessSpreadsheetCode);
      if (d.financialInstitution.id == institutionId) {
        data = d;
        // @ts-expect-error: deal with spreadsheet code later
        data.step = 6;
      }
    }

    if (!data) {
      data = {
        step,
        user: { id: userId },
        financialInstitution: { id: institutionId },
      };
    }

    // localStorage.removeItem('spreadsheet-provider');
    return await this.ajax(`${this.buildURL('accounts')}/link`, 'PUT', {
      data: JSON.stringify(data),
    });
  }

  async fetchSpreadsheetForm(id: string) {
    return await this.ajax(`${this.buildURL('accounts')}/login-form-fields/${id}`, 'GET');
  }

  async simulateLinking(data: unknown) {
    return await this.ajax(`${this.buildURL('accounts')}/link`, 'PUT', {
      data: JSON.stringify(data),
    });
  }
}

export default AccountAdapter;
