import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionContextService from 'tio-employee/services/session-context';
import type PartnerService from 'tio-employee/services/partner';
import type RouterService from '@ember/routing/router-service';

export default class AuthenticatedAdminIndexRoute extends Route {
  @service declare sessionContext: SessionContextService;
  @service declare partner: PartnerService;
  @service declare router: RouterService;

  async beforeModel() {
    if (!this.sessionContext.isLoaded) {
      try {
        await this.sessionContext.load();
      } catch (e) {
        console.error('Could not load session context:', e);
      }
    }
    if (this.sessionContext.isTasRole || this.partner.isTaOnly) {
      this.router.transitionTo('authenticated.admin.tuition-assistance.index');
      return;
    }
    if (this.sessionContext.isPslfAdmin) {
      this.router.transitionTo('authenticated.admin.pslf.forms');
      return;
    }
    this.router.transitionTo('authenticated.admin.reports.index');
  }
}
