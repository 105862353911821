import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { AuthenticatedSyfRouteModel } from 'tio-employee/routes/authenticated/syf';

export default class AuthenticatedSyfIndexRoute extends Route {
  @service declare router: RouterService;

  redirect() {
    this.router.transitionTo('authenticated.syf.dashboard');
  }

  model() {
    return this.modelFor('authenticated.syf');
  }

  afterModel(model: AuthenticatedSyfRouteModel) {
    if (model.isEnrolled) {
      this.router.transitionTo('authenticated.syf.dashboard');
    } else {
      this.router.transitionTo('authenticated.syf.enrollment');
    }
  }
}
