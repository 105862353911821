import { LegacyNetworkHandler } from '@ember-data/legacy-compat';
import ENV from 'tio-employee/config/environment';
import Fetch from '@ember-data/request/fetch';
import RequestManager from '@ember-data/request';

const AuthHandler = {
  /* @ts-expect-error ember-data */
  request({ request }, next) {
    const headers = new Headers(request.headers);
    headers.append('Content-Type', 'application/json');
    headers.append('x-api-key', ENV.apiKey);

    return next(Object.assign({}, request, { headers }));
  },
};

export default class Requests extends RequestManager {
  constructor(args?: Record<string | symbol, unknown>) {
    super(args);
    this.use([LegacyNetworkHandler, AuthHandler, Fetch]);
  }
}
