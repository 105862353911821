import { template } from "@ember/template-compiler";
import { isAfter } from 'tio-common/utils/date/is-after';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AuthLoginLoginForm from 'tio-employee/components/auth/login/login-form';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import emailSchema from 'tio-common/validation-schema/models/email';
import IntlService from 'ember-intl/services/intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import type LoginController from 'tio-employee/controllers/login';
import type PreRegisterService from '../services/pre-register';
export interface LoginRouteComponentSignature {
    Args: {
        controller: LoginController;
    };
}
let LoginRoute = class LoginRoute extends Component<LoginRouteComponentSignature> {
    @service
    preRegister: PreRegisterService;
    @service
    intl: IntlService;
    @tracked
    email = '';
    @tracked
    emailLookupError: string | null = null;
    @tracked
    emailLookupIsLoading = false;
    constructor(owner1: unknown, args1: LoginRouteComponentSignature['Args']){
        super(owner1, args1);
        this.email = decodeURIComponent(this.args.controller.email || '');
    }
    get validationErrors() {
        try {
            // @ts-expect-error: same as email-field.gts
            emailSchema.fields.email?.validateSync?.(this.email);
        } catch (err1) {
            return err1.errors || [];
        }
        return [];
    }
    get settings() {
        return this.preRegister.settings;
    }
    get company() {
        return this.preRegister.sparseCompany;
    }
    get isLaunched() {
        return this.company?.launchDate ? isAfter(new Date(), this.company.launchDate) : true;
    }
    get isUsingSso() {
        return !!(this.settings?.ssoAuthUrl && this.settings?.idProvider && this.settings?.idProvider !== 'NONE');
    }
    get selfRegisterCompany() {
        return this.preRegister.settings.register['isSelfRegisterAllowed'] || false;
    }
    get signUpRoute() {
        return this.selfRegisterCompany ? '/self-registration' : '/register';
    }
    static{
        template(`
    {{pageTitle (t "login.page_title")}}
    <EmailLookupWrapper @route="login">
      {{#if this.preRegister.settings.ssoAuthUrl}}
        <div class="text-center mb-6">
          <h1 class="text-2xl my-2">
            {{t "login.sso_redirect"}}
          </h1>
        </div>
      {{else}}
        <div class="text-center mb-6">
          <h1 class="text-2xl my-2">
            {{t "login.log_in"}}
          </h1>

          <div class="mb-6 text-md">
            {{t "login.new_to_tuition"}}
            <a class="underline text-ocean-600" href={{this.signUpRoute}}>
              {{t "login.sign_up"}}
            </a>
          </div>
        </div>
        <h2 class="text-lg text-center mb-4">
          {{t "login.enter_email_and_pw"}}
        </h2>
        <AuthLoginLoginForm @email={{this.email}} />
      {{/if}}
    </EmailLookupWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(LoginRoute);
