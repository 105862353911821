import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedPslfSignRoute extends Route {
  @service declare store: StoreService;

  queryParams = {
    signerType: { refreshModel: false },
  };

  async model(params: { form_id: string; signer: string }) {
    return await this.store.findRecord('pslf-form', params.form_id);
  }
}
