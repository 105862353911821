import { template } from "@ember/template-compiler";
import { Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import toggle from 'ember-composable-helpers/helpers/toggle';
export interface ReadSecurityComponentArgs {
    Element: HTMLButtonElement;
}
export default class ReadSecurityComponent extends Component<ReadSecurityComponentArgs> {
    @tracked
    isReadSecurityShowing = false;
    static{
        template(`
    <button
      type="button"
      class="flex flex-row items-center cursor-pointer"
      {{on "click" (toggle "isReadSecurityShowing" this)}}
      ...attributes
    >
      <MaterialIcon @icon="info" class="mx-1 text-lg text-ocean-600" />
      <div class="ml-2 text-ocean-600 underline">
        {{t "pslf_dashboard.about_security"}}
      </div>
    </button>

    <Modal
      @isOpen={{this.isReadSecurityShowing}}
      @onClose={{toggle "isReadSecurityShowing" this}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>{{t "read_security_button.security_compliance"}}</m.Header>
      <m.Body>
        <div class="tio-security-compliance-content">
          <h2>{{t "read_security_button.compliance"}}</h2>

          <p>{{t "read_security_button.customer_info_compliance"}}</p>

          <p>{{t "read_security_button.tio_process_controls"}}</p>

          <p>{{t "read_security_button.iso_certification"}}</p>

          <p>{{t "read_security_button.learn_more"}}</p>

          <ul>
            <li>{{t "read_security_button.ccpa_compliance"}}</li>
            <li>{{t "security"}}</li>
          </ul>

          <p>{{t "read_security_button.visit_security_overview"}}</p>

          <h3>{{t "read_security_button.about_ccpa_header"}}</h3>

          <p>{{t "read_security_button.about_ccpa_content"}}</p>

          <h3>{{t "read_security_button.what_you_dont_know"}}</h3>

          <p>{{t "read_security_button.ccpa_penalties"}}</p>

          <h3>{{t "read_security_button.does_ccpa_apply"}}</h3>

          <p>{{t "read_security_button.does_ccpa_apply_content"}}</p>

          <h3>{{t "read_security_button.ccpa_rights_header"}}</h3>

          <p>{{t "read_security_button.five_new_rights"}}</p>

          <ol>
            <li>{{t "read_security_button.request_data_usage"}}</li>
            <li>{{t "read_security_button.request_information"}}</li>
            <li>{{t "read_security_button.delete_information"}}</li>
            <li>{{t "read_security_button.third_parties"}}</li>
            <li>{{t "read_security_button.no_retaliation"}}</li>
          </ol>

          <h3>{{t "read_security_button.tio_and_ccpa"}}</h3>

          <p>{{t "read_security_button.tio_security_measures"}}</p>

          <p>{{t "read_security_button.incorporate_tio"}}</p>

          <table>
            <caption>{{t "read_security_button.list_of_users"}}</caption>
            <thead>
              <tr>
                <th>{{t "read_security_button.ccpa_requires"}}</th>
                <th>{{t "read_security_button.tio_responds"}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{t "read_security_button.right_to_copy"}}
                </td>
                <td>{{t "read_security_button.quickly_respond"}}</td>
              </tr>
              <tr>
                <td>
                  {{t "read_security_button.right_to_be_forgotten"}}
                </td>
                <td>{{t "read_security_button.searchable_data"}}</td>
              </tr>
              <tr>
                <td>
                  {{t "read_security_button.full_doc_audit"}}
                </td>
                <td>{{t "read_security_button.auth_logins"}}</td>
              </tr>
            </tbody>
          </table>

          <h2>{{t "read_security_button.in_summary"}}</h2>

          <p>{{t "read_security_button.privacy_by_design_includes"}}</p>

          <ul>
            <li>{{t "read_security_button.limit_data"}}</li>
            <li>{{t "read_security_button.limit_processing"}}</li>
            <li>{{t "read_security_button.limit_access"}}</li>
            <li>{{t "read_security_button.impact_assessment"}}</li>
            <li>{{t "read_security_button.keep_reviewing"}}</li>
            <li>{{t "read_security_button.record_keeping"}}</li>
          </ul>

          <p>{{t "read_security_button.contact_for_more_insight"}}</p>

          <p>{{t "read_security_button.privacy_policy"}}</p>

          <p>{{t "read_security_button.protecting_digital_assets"}}</p>

          <p>{{t "read_security_button.security_first"}}</p>

          <h3>{{t "read_security_button.data_in_transit"}}</h3>

          <p>{{t "read_security_button.encrypted"}}</p>

          <h3>{{t "read_security_button.data_at_rest"}}</h3>

          <p>{{t "read_security_button.aes_256"}}</p>

          <h3>{{t "read_security_button.data_stored"}}</h3>

          <p>{{t "read_security_button.auth_logins"}}</p>

          <ul>
            <li>{{t "read_security_button.what_has_permission"}}</li>
            <li>{{t "read_security_button.who_has_accessed"}}</li>
          </ul>

          <p>{{t "read_security_button.confidential_and_sensitive"}}</p>

          <h3>{{t "read_security_button.confidential_data"}}</h3>

          <p>{{t "read_security_button.confidential_data_includes"}}</p>

          <p>{{t "read_security_button.iso_standard"}}</p>

          <h3>{{t "read_security_button.sensitive_data"}}</h3>

          <p>{{t "read_security_button.what_is_sensitive"}}</p>

          <p>{{t "read_security_button.aws_host"}}</p>

          <p>{{t "read_security_button.amazon_links" htmlSafe=true}}</p>

          <p>{{t "read_security_button.aws_secure"}}</p>

          <h3>{{t "read_security_button.data_securely_backed"}}</h3>

          <p>{{t "read_security_button.aws_storage"}}</p>
        </div>
      </m.Body>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
