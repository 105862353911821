import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { not, or } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { string } from 'yup';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import embedCalendly from 'tio-common/modifiers/embed-calendly';
import ENV from 'tio-employee/config/environment';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import { Divider } from 'tio-ui/components/utilities';
import type IntlService from 'ember-intl/services/intl';
import type SessionContextService from 'tio-employee/services/session-context';
export default class RepayingStudentDebtCoachSchedulerComponent extends Component {
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @tracked
    topic = null;
    @tracked
    repaymentGoal = null;
    @tracked
    studentLoanType = null;
    @tracked
    repaymentPlan = null;
    @tracked
    monthlyPayment = null;
    @tracked
    sessionType = null;
    @tracked
    email = null;
    @tracked
    showSchedulingCalendar = false;
    @tracked
    submissionError = false;
    @tracked
    calendlyError = false;
    @tracked
    didSubmitSuccessfully = false;
    paymentPlans = [
        '10 year standard repayment plan',
        'Graduated',
        'Extended',
        'Pay As You Earn (PAYE)',
        'SAVE (formerly REPAYE)',
        'Income-Based Repayment (IBR)',
        'Income-Contingent Repayment',
        'Other'
    ];
    get emailPreferred() {
        return this.sessionType === this.intl.t('askjeni.schedule_time.email');
    }
    get plainText() {
        const answers1 = {
            scheduling_question_topic: this.topic,
            scheduling_question_repayment_goal: this.repaymentGoal,
            scheduling_question_loan_type: this.studentLoanType,
            scheduling_question_repayment_plan: this.repaymentPlan,
            scheduling_question_can_afford: this.monthlyPayment,
            scheduling_question_session_kind: this.sessionType,
            first_name: this.sessionContext.user?.person.firstName,
            last_name: this.sessionContext.user?.person.lastName,
            employee_id: this.sessionContext.currentEmployee?.id,
            email: this.email
        };
        let plainText1 = '';
        Object.keys(answers1).forEach((question1)=>{
            // @ts-expect-error: return to this, not sure how to type this
            if (answers1[question1]) {
                // @ts-expect-error: return to this, not sure how to type this
                // eslint-disable-next-line prettier/prettier
                plainText1 += ` ${question1}: ${answers1[question1]}.` + '\n';
            }
        });
        return plainText1;
    }
    // Used to prefill data within the calendly widget
    get calendlyPrefillData() {
        return {
            firstName: this.sessionContext.user?.person.firstName,
            lastName: this.sessionContext.user?.person.lastName
        };
    }
    get submitEnabled() {
        const fieldsPresent1 = this.topic && this.repaymentGoal && this.studentLoanType && this.repaymentPlan && this.monthlyPayment && this.sessionType;
        if (!fieldsPresent1) {
            return false;
        }
        if (this.emailPreferred) {
            try {
                string().nullable().required().email().validateSync(this.email);
                return true;
            } catch (err1) {
                return false;
            }
        } else {
            return true;
        }
    }
    @action
    onCalendlyError() {
        this.calendlyError = true;
    }
    // TODO: Refactor to use a dropTask so we can add loading indicator for button,
    // prevent multiple submissions from button smashing, and give user more feedback once complete
    @action
    async submitSchedulingRequest() {
        const headers1 = {
            'x-api-key': ENV.apiKey,
            'tio-auth-token': this.sessionContext.session.data.authenticated.access_token,
            'Content-Type': 'application/json'
        };
        const url1 = `${ENV.apiHost}/coaching/schedule-loan`;
        const response1 = await fetch(url1, {
            method: 'POST',
            body: JSON.stringify({
                scheduleRequestContent: this.plainText
            }),
            headers: headers1
        });
        if (!response1.ok) {
            this.submissionError = true;
            return;
        }
        this.submissionError = false;
        // Scheduling is only needed for a phone session
        if (response1.ok && !this.emailPreferred) {
            this.showSchedulingCalendar = true;
        } else {
            this.didSubmitSuccessfully = true;
        }
    }
    static{
        template(`
    {{#unless (or this.showSchedulingCalendar this.didSubmitSuccessfully)}}
      <form class="text-gray-500">
        {{#if this.submissionError}}
          <TioAlert @type="error" @allowDismiss={{false}} class="mb-6">
            <:body>
              <p class="font-bold">{{t "askjeni.schedule_time.submission_error"}}</p>
            </:body>
          </TioAlert>
        {{/if}}

        <div>
          {{t "askjeni.schedule_time.answer_questions"}}
        </div>

        {{! Topic question section  }}
        <div class="pt-8 text-lg">
          {{t "askjeni.schedule_time.speak_about"}}
        </div>
        <div>
          {{! @glint-expect-error: research}}
          <FormInput data-legacy-input @containerClass="pt-4" @onInput={{fn (mut this.topic)}} />
          <div class="text-sm">
            {{t "askjeni.schedule_time.be_detailed"}}
          </div>
        </div>

        {{! Repayment goal question section }}
        <div class="pt-8 text-lg">
          {{t "askjeni.schedule_time.what_goal"}}
        </div>
        <FormRadioGroup
          data-legacy-input
          class="pt-4"
          {{! @glint-expect-error: This is a weird bug}}
          @value={{this.repaymentGoal}}
          {{! @glint-expect-error: Research mut}}
          @onChange={{fn (mut this.repaymentGoal)}}
          @required="true"
          as |Radio|
        >
          <div class="">
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.goal_choice_1"}}
              @label={{t "askjeni.schedule_time.goal_choice_1"}}
            />
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.goal_choice_2"}}
              @label={{t "askjeni.schedule_time.goal_choice_2"}}
            />
          </div>
        </FormRadioGroup>

        {{! Loan types question section }}
        <div class="pt-8 text-lg">
          {{t "askjeni.schedule_time.what_have_you"}}
        </div>
        <FormRadioGroup
          data-legacy-input
          class="pt-4"
          {{! @glint-expect-error: This is a weird bug}}
          @value={{this.studentLoanType}}
          {{! @glint-expect-error: Research mut}}
          @onChange={{fn (mut this.studentLoanType)}}
          @required="true"
          as |Radio|
        >
          <div class="">
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_1"}}
              @label={{t "askjeni.schedule_time.have_choice_1"}}
            />
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_2"}}
              @label={{t "askjeni.schedule_time.have_choice_2"}}
            />
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_3"}}
              @label={{t "askjeni.schedule_time.have_choice_3"}}
            />
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_4"}}
              @label={{t "askjeni.schedule_time.have_choice_4"}}
            />
          </div>
        </FormRadioGroup>

        {{! Payment plans question section }}
        <div class="pt-8 text-lg">
          {{t "askjeni.schedule_time.current_plan"}}
        </div>
        <FormSelect
          data-legacy-input
          class="pt-4"
          {{! @glint-expect-error: issue in power select}}
          @allowClear={{true}}
          @placeholder="Payment Method"
          @options={{this.paymentPlans}}
          @selected={{this.repaymentPlan}}
          @onChange={{fn (mut this.repaymentPlan)}}
          as |plan|
        >
          {{! @glint-expect-error: types aren't passing through }}
          {{plan}}
        </FormSelect>

        {{! Can afford question section }}
        <div class="pt-8 text-lg">
          {{t "askjeni.schedule_time.can_you_afford"}}
        </div>
        <FormRadioGroup
          data-legacy-input
          class="pt-4"
          {{! @glint-expect-error: This is a weird bug}}
          @value={{this.monthlyPayment}}
          {{! @glint-expect-error: Research mut}}
          @onChange={{fn (mut this.monthlyPayment)}}
          @required="true"
          as |Radio|
        >
          <div class="">
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.yes"}}
              @label={{t "askjeni.schedule_time.yes"}}
            />
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.no"}}
              @label={{t "askjeni.schedule_time.no"}}
            />
          </div>
        </FormRadioGroup>

        {{! Session kind question section }}
        <div class="pt-8 text-lg">
          {{t "askjeni.schedule_time.kind_of_session"}}
        </div>
        <FormRadioGroup
          data-legacy-input
          class="pt-4"
          {{! @glint-expect-error: This is a weird bug}}
          @value={{this.sessionType}}
          {{! @glint-expect-error: Research mut}}
          @onChange={{fn (mut this.sessionType)}}
          @required="true"
          as |Radio|
        >
          <div class="">
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.phone"}}
              @label={{t "askjeni.schedule_time.phone"}}
            />
            {{! @glint-expect-error: This is a weird bug}}
            <Radio
              @value={{t "askjeni.schedule_time.email"}}
              @label={{t "askjeni.schedule_time.email"}}
            />
          </div>
        </FormRadioGroup>

        {{#if this.emailPreferred}}
          {{! Email prompt section  }}
          <div class="pt-8 text-lg">
            {{t "askjeni.schedule_time.enter_email"}}
          </div>
          <div>
            {{! @glint-expect-error: Research mut}}
            <FormInput data-legacy-input @containerClass="pt-4" @onInput={{fn (mut this.email)}} />
          </div>
        {{/if}}

        <Divider class="my-9" />

        <div class="flex flex-row justify-between">
          <TioButton
            class="p-10"
            @linkTo="authenticated.repaying-student-debt.askjeni.index"
            @text={{true}}
          >
            {{t "cancel"}}
          </TioButton>
          <TioButton
            class="p-10"
            @onClick={{this.submitSchedulingRequest}}
            @disabled={{not this.submitEnabled}}
          >
            {{t "submit"}}
          </TioButton>
        </div>
      </form>
    {{/unless}}

    {{#if this.showSchedulingCalendar}}
      {{#if this.calendlyError}}
        <TioAlert @type="error" @allowDismiss={{false}}>
          <:body>
            <p class="font-bold">{{t "askjeni.schedule_time.calendly_error"}}</p>
          </:body>
        </TioAlert>
      {{/if}}
      <div
        id="calendly-container"
        class="h-[756px]"
        {{embedCalendly
          "https://calendly.com/tiocoaches"
          this.calendlyPrefillData
          this.onCalendlyError
        }}
      />
    {{/if}}

    {{#if this.didSubmitSuccessfully}}
      <TioAlert @type="success" @allowDismiss={{false}}>
        <:body>
          <p class="font-bold">{{t "askjeni.schedule_time.submission_success"}}</p>
        </:body>
      </TioAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
