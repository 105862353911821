import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type RouterService from 'tio-employee/services/router';

export default class AuthenticatedAccountActivityIndexRoute extends Route {
  @service declare router: RouterService;

  redirect() {
    this.router.transitionTo('authenticated.account-activity.accounts');
  }
}
