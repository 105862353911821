import { template } from "@ember/template-compiler";
import type StoreService from 'tio-common/services/store';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { service } from '@ember/service';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import EligibilityFilesEligibilityTableRow from 'tio-common/components/eligibility-files/eligibility-table-row';
import MaterialIcon from 'tio-common/components/material-icon';
import Table from 'tio-common/components/table/index';
import TablePaginator from 'tio-common/components/table/paginator';
import TioButton from 'tio-common/components/tio/button';
import TioEligibilityUploadModal from 'tio-common/components/tio/eligibility-upload-modal';
import type EligibilityFileJobLogModel from 'tio-common/models/eligibility-file-job-log';
import { statusFromDisplayName, statusOptions } from 'tio-common/utils/eligibility-upload/status-lookups';
import type IndexController from 'tio-employee/controllers/authenticated/admin/eligibility-files/index';
import type IntlService from 'ember-intl/services/intl';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
export interface S {
    Args: {
        model: EligibilityFileJobLogModel[];
        controller: IndexController;
    };
}
let AuthenticatedAdminEligibilityFilesIndexRouteComponent = class AuthenticatedAdminEligibilityFilesIndexRouteComponent extends Component<S> {
    @service
    partner: PartnerService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @tracked
    showUploadModal = false;
    get statusOptions() {
        return statusOptions();
    }
    get selectedStatus() {
        return this.intl.t('common.eligibility_file_upload.status.' + (this.args.controller.status || 'ALL'));
    }
    convertFromReadableName(status1: string) {
        return statusFromDisplayName(status1) || 'ALL';
    }
    @action
    setSelectedStatus(selectedStatus1: string) {
        if (selectedStatus1 === 'ALL') {
            this.args.controller.status = '';
        } else {
            this.args.controller.status = this.convertFromReadableName(selectedStatus1);
        }
    }
    @action
    setSelectedStartDate(startDate1: string) {
        this.args.controller.startDate = startDate1;
    }
    @action
    onSort(sort1: string) {
        this.args.controller.sort = sort1;
    }
    @action
    toggleUploadEligibilityFileModal() {
        this.showUploadModal = !this.showUploadModal;
    }
    @action
    closeModal() {
        this.showUploadModal = false;
    }
    @action
    previousPage() {
        this.args.controller.page = Math.max(1, this.args.controller.page - 1);
    }
    @action
    nextPage() {
        ++this.args.controller.page;
    }
    get totalPages() {
        const { model: model1, controller: controller1 } = this.args;
        // @ts-expect-error: ed needs types
        const meta1 = model1?.meta;
        if (meta1?.page.total) {
            return Math.ceil(meta1.page.total / controller1.limit);
        }
        return 0;
    }
    static{
        template(`
    {{pageTitle (t "partner.eligibility_files.eligibility_files")}}

    <div class="w-full p-4">
      <h1 class="font-semibold text-midnight text-3xl mb-2 ml-2">
        {{t "partner.eligibility_files.eligibility_files"}}
      </h1>
      <div class="sticky top-0 z-10 ml-2 mb-4 bg-white">
        <div class="w-1/4 mr-2 inline-block align-bottom">
          <label class="text-xs" aria-hidden={{true}}>
            {{t "partner.eligibility_files.filter_by_date"}}
          </label>
          <FormInput
            data-legacy-input
            @type="date"
            @value={{@controller.startDate}}
            @onInput={{this.setSelectedStartDate}}
          />
        </div>
        <div class="w-1/4 mr-2 inline-block align-bottom">
          <label class="text-xs" aria-hidden={{true}}>
            {{t "partner.eligibility_files.filter_by_status"}}
          </label>
          <FormSelect
            data-legacy-input
            @options={{this.statusOptions}}
            @selected={{this.selectedStatus}}
            @onChange={{this.setSelectedStatus}}
            {{! @glint-expect-error: power select types }}
            @allowClear={{true}}
            @renderInPlace={{true}}
            as |option|
          >
            {{! @glint-expect-error: power select types }}
            {{option}}
          </FormSelect>
        </div>
        <div class="w-1/8 mr-2 inline-block">
          <TioButton @outlined={{true}} @onClick={{this.toggleUploadEligibilityFileModal}}>
            <MaterialIcon @icon="upload_file" />
            &nbsp;
            {{t "partner.eligibility_files.upload_eligibility_file"}}
          </TioButton>
          {{#if this.showUploadModal}}
            <TioEligibilityUploadModal
              {{! @glint-expect-error: need to unify partial commpany types from session }}
              @company={{this.partner.company}}
              @cancel={{this.closeModal}}
              @toggle={{this.toggleUploadEligibilityFileModal}}
              @showRoute="authenticated.admin.eligibility-files.show"
            />
          {{/if}}
        </div>
      </div>

      <Table @isLoading={{@controller.isLoading}}>
        <:thead as |options|>
          <options.tr>
            <options.th
              @sortField="file-name"
              @activeSortField={{@controller.sort}}
              @onSort={{this.onSort}}
            >
              {{t "partner.eligibility_files.file_name"}}
            </options.th>
            <options.th
              @sortField="file-name"
              @activeSortField={{@controller.sort}}
              @onSort={{this.onSort}}
            >
              {{t "partner.eligibility_files.file_name"}}
            </options.th>
            <options.th
              @sortField="strategy"
              @activeSortField={{@controller.sort}}
              @onSort={{this.onSort}}
            >
              {{t "partner.eligibility_files.strategy"}}
            </options.th>
            <options.th
              @sortField="start-time"
              @activeSortField={{@controller.sort}}
              @onSort={{this.onSort}}
            >
              {{t "partner.eligibility_files.started_on"}}
            </options.th>
            <options.th
              @sortField="status"
              @activeSortField={{@controller.sort}}
              @onSort={{this.onSort}}
            >
              {{t "partner.eligibility_files.status"}}
            </options.th>
          </options.tr>
        </:thead>
        <:tbody as |options|>
          {{#each @model as |item|}}
            {{! @glint-expect-error: this component expects so many arguments }}
            <EligibilityFilesEligibilityTableRow
              @showRoute="authenticated.admin.eligibility-files.show"
              @item={{item}}
              @cellClass={{options.cellClass}}
            />
          {{/each}}
        </:tbody>
        <:tfoot>
          <tr>
            <td class="text-right" colspan="9">
              <TablePaginator
                @page={{@controller.page}}
                @totalPages={{this.totalPages}}
                @nextPage={{this.nextPage}}
                @previousPage={{this.previousPage}}
                @limit={{@controller.limit}}
                {{! @glint-expect-error: ed needs types }}
                @total={{@model.meta.page.total}}
              />
            </td>
          </tr>
        </:tfoot>
      </Table>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedAdminEligibilityFilesIndexRouteComponent);
