import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

import type SessionContextService from 'tio-employee/services/session-context';
import type { AuthenticatedSyfRouteModel } from '../../syf';

export default class AuthenticatedSyfPaymentsNewRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const person = await this.store.findRecord('person', this.sessionContext.user.person.id, {
      include: 'accounts',
      reload: true,
    });

    const matchParticipant = this.modelFor('authenticated.syf') as AuthenticatedSyfRouteModel;

    return {
      matchParticipant,
      accounts: person.activeAccounts,
    };
  }
}
