import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import FormInput from '@frontile/forms-legacy/components/form-input';
import TioButton from 'tio-common/components/tio/button';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface EmailLookupComponentSignature {
    Args: {
        email: string;
        emailLookupIsLoading: boolean;
        validationErrors: string[];
        submitEmailLookup: () => void;
    };
}
const EmailLookupComponent: TOC<EmailLookupComponentSignature> = template(`
  <form {{on "submit" @submitEmailLookup}}>
    <div class="mb-10">
      <FormInput
        data-legacy-input
        @label={{t "login.email"}}
        @value={{@email}}
        @onInput={{fn (mut @email)}}
        @errors={{@validationErrors}}
        @type="email"
      />
    </div>
    <div class="flex flex-col items-center">
      <TioButton
        @loading={{@emailLookupIsLoading}}
        @onClick={{@submitEmailLookup}}
        class="w-36 mb-10"
      >
        {{t "submit"}}
      </TioButton>
    </div>
  </form>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmailLookupComponent;
