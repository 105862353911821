import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import type {
  ExtractedStatementContent,
  ExtractedNsldsContent,
} from 'tio-common/models/observable-document';
import type ObservablesService from 'tio-common/services/observables';
import type CableService from 'tio-common/services/cable';
import type SessionContextService from './session-context';
import ENV from '../config/environment';

export type ObservabilityUploadInstructions = {
  title: string;
  header: string;
  subheader?: string | undefined;
  steps: SafeString[];
  footer: string;
};

export type ObservableSource = keyof typeof OBSERVABLE_PRODUCT_ROUTES;
type BatchStatus = {
  id: string;
  recordAttributes: ObservableDocumentModel;
};

type LoanSummary = {
  servicer?: string;
  balance?: number;
  accountNumber?: string;
  loans: {
    name: string;
    balance: number;
  }[];
}[];

const SERVICERS = ['nelnet', 'mohela', 'aidvantage', 'edfinancial', 'americaned'];
const STATEMENT_INSTRUCTIONS_TRANSLATION_BASE = 'observability.uploads.statement.instructions';
const NSLDS_INSTRUCTIONS_TRANSLATION_BASE = 'observability.uploads.nslds.instructions';

const OBSERVABLE_PRODUCT_ROUTES = {
  slr: 'authenticated.slr.dashboard',
  pslf: 'authenticated.pslf.dashboard',
  syf: 'authenticated.syf.dashboard',
};

export const getObservableProductRoute = (
  source?: keyof typeof OBSERVABLE_PRODUCT_ROUTES
): string => {
  if (source && source in OBSERVABLE_PRODUCT_ROUTES) {
    return OBSERVABLE_PRODUCT_ROUTES[source];
  }
  return 'authenticated.dashboard';
};

export const getRecommendedDocumentType = (source?: string): 'nslds' | 'statement' => {
  const nsldsSources = ['pslf', 'wellness'];
  return nsldsSources.includes(source as string) ? 'nslds' : 'statement';
};

const getInstitutionMatch = (institutionName = ''): string | undefined => {
  const keyMatch = SERVICERS.find((key) =>
    institutionName.toLowerCase().replace(/\s+/g, '').includes(key)
  );
  return keyMatch;
};

/*
  this is... relatively experimental; motivation here was to try to improve on the abstraction
  of imperatively splitting an AASM string and accessing the meaningful state part via index,
  the motivation for which is to be able to more sanely structure ember-intl translation YAML
  select statements

  it seems that translation *keys* can include a period(1)(2) since sometime after this issue(3)
  was closed, but anecdotally ember-intl throws an error in reference to an invalid translation
  format when any cases in a select statement include a period (which all AASM state strings do,
  sometimes more than 1), hence the motivation to generically isolate the meaningful segment of
  a string with that format

  as we hopefully continue to adopt select statements in translations, it seems likely that AASM
  strings will be a common use case for them, and therefore reasonable that we have a generic
  strategy to get from AASM strings to select statement cases

  while in practice calling .split('.').at(-1) incidentally accomplishes the same thing, I
  propose an implementation around a language feature that more declaratively supports
  destructuring strings into optionally captured substrings that can also be optionally named
  something that corresponds to their semantic meaning; I would argue this is a strictly better
  abstraction than relying on positionality relative to delimiters

  james 20241127

  (1) https://github.com/ember-intl/ember-intl/blob/0d9bcf7cec03f716a1d14da2199fe7d32cc077d1/tests/ember-intl/tests/unit/services/intl-test.ts#L57
  (2) https://ember-intl.github.io/ember-intl/docs/migration/v3
  (3) https://github.com/ember-intl/ember-intl/issues/581

  also,
  TODO: put this in a utils module (tio-common sounds better than tio-ui for that?) if we don't
  decide that it's completely insane - james 20241127
*/
const parseAasmState = (fullState: string): string => {
  // (?:[A-Z]+(?:_[A-Z]+)*\.)? - optional SCREAMING_SNAKE prefix followed by "." (inner/outer both groups uncaptured)
  // (?:[A-Z][a-z]+)+\. - mandatory PascalCase flavor string followed by "." (group uncaptured)
  // ([A-Z]+(?:_[A-Z]+)*) - mandatory SCREAMING_SNAKE state string, named group 'state' (inner "(?:_[A-Z]+)*" grouped for repetition but not captured)
  const aasmStatePattern =
    /(?:[A-Z]+(?:_[A-Z]+)*\.)?(?:[A-Z][a-z]+)+\.(?<state>[A-Z]+(?:_[A-Z]+)*)/g;

  // use matchAll here to get groupings
  const match = Array.from(fullState.matchAll(aasmStatePattern));

  // ack if this received a bad fullState argument; any model with a prop for an AASM attribute
  // should always have at least *a* value for it and that value should strictly be an AASM string
  if (!match.length) {
    throw new Error(`parseAasmState called with invalid string - ${fullState}`);
  }

  // spread match iterator into an array literal and cast it as a one-element array
  // with a full match and a captured group since it should be known by now that the
  // full state string matched the AASM pattern regexp
  const [
    {
      groups: { state },
    },
  ] = <[{ groups: { state: string } }]>(<unknown>[...match]);
  return state;
};

export const getStatus = (document: ObservableDocumentModel): string => {
  if (document.subsistenceState === 'SubsistenceState.IN_DISPUTE') {
    return parseAasmState(document.subsistenceState);
  } else {
    return parseAasmState(document.extractionState);
  }
};

export const getProvider = (document: ObservableDocumentModel): string => {
  return parseAasmState(document.provider);
};

export const extractContent = (document: ObservableDocumentModel): LoanSummary => {
  if (document.provider === 'ObservableProvider.NSLDS') {
    const extractedContent = <ExtractedNsldsContent>document.extractedContent;

    // get loans with outstanding balances - somehow the 'loans' attribute on
    // the document's extracted content can be nonexistent, which seems problematic
    const loans = (extractedContent.loans || []).filter((loan) => {
      const balance = Number(loan.loan_outstanding_principal_balance);
      return balance > 0;
    });

    // find servicers from those loans
    const servicers = loans.reduce<string[]>((currentServicers, loan) => {
      const servicer = loan.contact.find((contact) => contact.most_relevant === 'Yes');
      const servicerName = servicer!.loan_contact_name;
      if (!currentServicers.includes(servicerName)) {
        return [...currentServicers, servicerName];
      }
      return currentServicers;
    }, []);

    // return summary object
    return servicers.reduce<LoanSummary>((currentSummary, servicer) => {
      // get loans associated with current servicer
      const servicerLoans = loans.filter((loan) => {
        const currentContact = loan.contact.find((contact) => contact.most_relevant === 'Yes');
        return currentContact!.loan_contact_name === servicer;
      });

      // sum their balances
      const balance = servicerLoans.reduce(
        (total, loan) => total + Number(loan.loan_outstanding_principal_balance),
        0
      );

      // map them to summarized loan objects
      const summaryLoans = servicerLoans.map((loan) => ({
        name: loan.loan_type_code,
        balance: loan.loan_outstanding_principal_balance,
      }));

      // compose summary element
      const summaryItem = {
        servicer,
        balance,
        loans: summaryLoans,
      };

      // compose into current summary array
      return [...currentSummary, summaryItem];
    }, []);
  }
  // base case assumes document.provider === 'ObservableProvider.NSLDS'
  const extractedContent = <ExtractedStatementContent>document.extractedContent;

  // extract top level content
  const servicer = extractedContent.servicer_name;
  const balance = extractedContent.current_principal_balance;
  const accountNumber = extractedContent.account_number;

  // parse and format loans
  const loanDetails = extractedContent['loan_details'];
  const loans = Object.keys(loanDetails || {}).map((key) => {
    return { name: key, balance: loanDetails[key]!['loan_current_principal_balance'] };
  });

  const summaryItem = { servicer, balance, accountNumber, loans };

  return [summaryItem];
};

// TODO: sanitize observable document logs on staging/vodka and delete any
// of them without notes in the detail object in order to purge question
// mark operators here
// TODO: consider isDisputed or similar instance method on observable document
// log model to replace find operation, avoid need for ? operators altogether
export const getDisputeNote = (document: ObservableDocumentModel): string | undefined => {
  // get associated logs and find any dispute logs
  // TODO: get this from a logSource attribute when available rather than the detail JSON
  const logs = document.observableDocumentLogs || [];
  const disputeLog = logs.find((log) => {
    return Object.keys(log.detail.changes).includes('disputed_at');
  });

  return disputeLog?.detail?.note;
};

// how long to wait once a document is processed to transition to the confirmation
// page, so that the user can see the progress bar fill and a success message
const CONFIRMATION_TRANSITION_TIMEOUT = 1000;

export default class ObservabilityService extends Service {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare cable: CableService;
  @service declare observables: ObservablesService;

  @action
  getStatementInstructionSection(section: string, institutionName?: string): string {
    const servicer = getInstitutionMatch(institutionName);
    const translation = [STATEMENT_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    return this.intl.t(translation, { servicer });
  }

  @action
  getNsldsInstructionSection(section: string): string {
    const translation = [NSLDS_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    return this.intl.t(translation);
  }

  @action
  getDelimitedStatementInstructionSection(section: string, institutionName?: string): SafeString[] {
    const servicer = getInstitutionMatch(institutionName);
    const translation = [STATEMENT_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    const options = { linkClass: 'tio-link' };
    const result = this.intl.t(translation, { servicer, ...options });
    return result.split('|').map(htmlSafe);
  }

  @action
  getDelimitedNsldsInstructionSection(section: string): SafeString[] {
    const translation = [NSLDS_INSTRUCTIONS_TRANSLATION_BASE, section].join('.');
    const result = this.intl.t(translation);
    return result.split('|').map(htmlSafe);
  }

  @action
  getStatementInstructions(institutionName?: string): ObservabilityUploadInstructions {
    return {
      title: this.intl.t(`${STATEMENT_INSTRUCTIONS_TRANSLATION_BASE}.title`),
      header: this.getStatementInstructionSection('header', institutionName),
      subheader: this.getStatementInstructionSection('subheader', institutionName),
      steps: this.getDelimitedStatementInstructionSection('steps', institutionName),
      footer: this.getStatementInstructionSection('footer', institutionName),
    };
  }

  @action
  getNsldsInstructions(): ObservabilityUploadInstructions {
    return {
      title: this.intl.t(`${NSLDS_INSTRUCTIONS_TRANSLATION_BASE}.title`),
      header: this.getNsldsInstructionSection('header'),
      steps: this.getDelimitedNsldsInstructionSection('steps'),
      footer: this.getNsldsInstructionSection('footer'),
    };
  }

  @action
  navigateToConfirmation(document: ObservableDocumentModel): void {
    this.router.transitionTo('authenticated.observability.confirm', document.id);
  }

  @action
  subscribeToBatchStatus(batchId: string): void {
    const accessToken = this.sessionContext.session.data.authenticated.access_token;
    this.cable.connect(ENV.apiHost, ENV.apiKey, accessToken);

    const received = (msg: BatchStatus) => {
      const id = msg.id;
      const results = this.observables.updateObservableDocument({
        ...msg.recordAttributes,
        id,
      });

      const document = results[0];

      if (msg.recordAttributes.extractionState === 'ExtractionState.PROCESSED') {
        setTimeout(() => {
          this.navigateToConfirmation(document);
        }, CONFIRMATION_TRANSITION_TIMEOUT);
      }
    };

    const channel = 'ObservableDocumentBatchStatusChannel';
    const batch = batchId;

    return this.cable.consumer.subscriptions.create({ channel, batch }, { received });
  }
}
