import ApplicationAdapter from './application';
import type EmployeeModel from 'tio-common/models/employee';
/**
 *
 * @param {object} formModel The form model.
 * @returns {object} The server response.
 */
export default class CompanyAdapter extends ApplicationAdapter {
  async getTasApprovers(id: string) {
    const url = this.buildURL('companies') + `/${id}/tas-approvers`;
    const data = await this.ajax(url, 'GET');
    this.store.pushPayload(data);
    return this.store.push(data) as EmployeeModel[];
  }
}
