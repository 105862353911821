import { service } from '@ember/service';
import ApplicationAdapter from './application';
import type StoreService from 'tio-common/services/store';

/**
 * @memberof adapters
 */
export default class EligibilityFileJobLogAdapter extends ApplicationAdapter {
  @service store!: StoreService;

  async processJob(legacy_id: string, company_id: string, process_id: string) {
    return await this.ajax(
      this.buildURL('eligibility-files') + '/process-eligibility-file',
      'POST',
      {
        data: JSON.stringify({
          legacy_id,
          company_id,
          process_id,
        }),
      }
    );
  }

  async abortJob(legacy_id: string, company_id: string, process_id: string) {
    return await this.ajax(
      this.buildURL('eligibility-files') + '/legacy-abort-eligibility-file',
      'POST',
      {
        data: JSON.stringify({
          legacy_id,
          company_id,
          process_id,
        }),
      }
    );
  }

  async getUploadRecord(name: string, legacy_id: string, company_id: string, strategy: string) {
    const response = await this.ajax(
      this.buildURL('eligibility-files') + '/get-upload-url',
      'POST',
      {
        data: JSON.stringify({
          'file-name': name,
          legacy_id,
          company_id,
          strategy,
        }),
      }
    );

    this.store.pushPayload(response);
    return this.store.peekRecord('eligibility-file-job-log', response.data.id);
  }
}
