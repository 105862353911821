import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type PartnerService from 'tio-employee/services/partner';

type Query = {
  filter: {
    'is-active'?: boolean;
    'participation-status'?: string;
    plans?: string;
    company: string;
    search?: string;
  };
  fields: {
    user: string;
  };
  page: {
    limit: number;
    offset: number;
  };
  include: string;
  sort?: string;
};

type Params = {
  sort: string;
  search: string;
  plan: string;
  participationStatus: string;
  page: number;
  limit: number;
};

export default class AuthenticatedAdminEmployeesRoute extends Route {
  @service declare store: StoreService;
  @service declare partner: PartnerService;

  queryParams = {
    search: { refreshModel: true, replace: true },
    sort: { refreshModel: true, replace: true },
    plan: { refreshModel: true, replace: true },
    participationStatus: { refreshModel: true, replace: true },
    page: { refreshModel: true, replace: true },
    limit: { refreshModel: true, replace: true },
  };

  includes = ['plan', 'user', 'user.logins', 'user.recommendations'];

  async model({ sort, search, plan, participationStatus, page, limit }: Params) {
    if (plan) {
      const record = this.store.peekRecord('plan', plan);
      if (!record) {
        await this.store.findRecord('plan', plan);
      }
    }

    const filter: Partial<Query['filter']> = {
      company: this.partner.company?.id,
    };

    if (search) {
      filter.search = search;
    }
    // filter requires 'plan' to be labeled 'plans' when hitting the API
    if (plan) {
      filter.plans = plan;
    }

    if (participationStatus) {
      filter['participation-status'] = participationStatus.toLowerCase();
      if (participationStatus == 'Deactivated') {
        filter['is-active'] = false;
      }
    }

    const query = {
      filter,
      sort,
      page: {
        limit,
        offset: (page - 1) * limit,
      },
      include: this.includes.join(','),
      fields: { user: ['created-at', 'updated-at'].join(',') },
    };

    return this.store.query('employee', query);
  }
}
