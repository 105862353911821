import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCompaniesReportingTasApplicationsController extends Controller {
  @tracked applicationStatus = '';
  @tracked applicationCreatedAtStart = '';
  @tracked coursesEndDate = '';
  @tracked includeCourses = false;
  @tracked includePayments = false;
  @tracked terminatedEmployees = false;
  @tracked includeInvoiceDate = false;

  queryParams = [
    'applicationStatus',
    'applicationCreatedAtStart',
    'coursesEndDate',
    'includeCourses',
    'includePayments',
    'terminatedEmployees',
    'includeInvoiceDate',
  ];
}
