import { datadogRum } from '@datadog/browser-rum';
import { service } from '@ember/service';
import ENV from 'tio-employee/config/environment';
import RESTAdapter from '@ember-data/adapter/rest';
import type SessionService from '../services/session';
import type SessionContextService from '../services/session-context';

/**
 *
 * @returns {object} The server response.
 */
export default class SearchModelAdapter extends RESTAdapter {
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;

  namespace = 'api/v2';
  host = ENV.apiHost;

  get headers() {
    const headers: Record<string, unknown> = {
      'x-api-key': ENV.apiKey,
      'Content-Type': 'application/json;',
    };

    if (this.session.isAuthenticated) {
      headers['tio-auth-token'] = `${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  async search(query: Record<string, unknown>) {
    const url = this.buildURL('search');
    return await this.ajax(url, 'GET', { data: query });
  }

  urlForQuery() {
    // Customize the endpoint for the `SearchPerson` model
    return `${this.host}/${this.namespace}`;
  }

  /**
    This method is called for every response that the adapter receives from the
    API. If the response has a 401 or 403 status code it invalidates the session (see
    @method handleResponse
    @param {Number} status The response status as received from the API
    @param  {Object} headers HTTP headers as received from the API
    @param {Any} payload The response body as received from the API
    @param {Object} requestData the original request information
    @protected
  */
  handleResponse(
    status: number,
    headers: Record<string, unknown>,
    payload: Record<string, unknown>,
    requestData: Record<string, unknown>
  ) {
    this.ensureResponseAuthorized(status, headers, payload, requestData);

    return super.handleResponse(status, headers, payload, requestData);
  }

  /**
     The default implementation for handleResponse.
     If the response has a 401 or 403 status code it invalidates the session.
     Override this method if you want custom invalidation logic for incoming responses.
     @method ensureResponseAuthorized
     @param {Number} status The response status as received from the API
     @param  {Object} headers HTTP headers as received from the API
     @param {Any} payload The response body as received from the API
     @param {Object} requestData the original request information
    */
  ensureResponseAuthorized(
    status: number,
    headers: unknown,
    payload: unknown,
    requestData: unknown
  ) {
    if ([401, 403].includes(status) && this.session.isAuthenticated) {
      console.error('ensureResponseAuthorized caught error', {
        status,
        headers,
        payload,
        requestData,
        userId: this.sessionContext.user?.id,
      });

      datadogRum.addError(
        `ensureResponseAuthorized caught error status ${status} for user ${this.sessionContext.user?.id}`,
        {
          status,
          headers,
          payload,
          requestData,
          userId: this.sessionContext.user?.id,
        }
      );
      this.session.invalidate();
    }
  }
}
