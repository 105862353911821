import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PslfFormService from 'tio-employee/services/pslf-form';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedWhatNextRoute extends Route {
  @service declare pslfForm: PslfFormService;
  @service declare store: StoreService;

  queryParams = {
    formId: { refreshModel: true },
  };

  model(params: { formId: string }) {
    return this.store.findRecord('pslf-form', params.formId, {
      include: 'attachments',
    });
  }
}
