import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface RecommendationsArticleCardColumnSignature {
    Args: {
        title?: string;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const RecommendationsArticleCardColumn: TOC<RecommendationsArticleCardColumnSignature> = template(`
  <div class="px-2 mx-2 leading-relaxed" ...attributes>
    <h3 class="font-bold mb-2">
      {{@title}}
    </h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RecommendationsArticleCardColumn;
