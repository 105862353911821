import ApplicationAdapter from './application';
import type { DynamicEligibility, PeriodOfEligibility } from 'tio-common/types/tuition-assistance';
/**
 * An extended adapter for the `TasParticipant` model.
 *
 * @memberof adapters
 */

class TasParticipantAdapter extends ApplicationAdapter {
  /**
   * @param {string}  tasParticipantId - an ID of a TAS participant record
   * @param {object}  changelog - a changelog object with a note
   *
   * @return {object}
   */

  async removeTasParticipantHold(
    tasParticipantId: string,
    changelog: { note: string }
  ): Promise<void> {
    const url = this.buildURL('tas-participants', tasParticipantId) + '/transition/state/activate';
    return await this.ajax(url, 'PATCH', {
      data: { data: { attributes: { _changelog: changelog } } },
    });
  }

  async holdTasParticipant(tasParticipantId: string, changelog: { note: string }): Promise<void> {
    const url = this.buildURL('tas-participants', tasParticipantId) + '/transition/state/hold';
    return await this.ajax(url, 'PATCH', {
      data: { data: { attributes: { _changelog: changelog } } },
    });
  }

  /**
   * @param {string}  tasParticipantId - an ID of a TAS participant record
   * @param {string}  programCode - the code of a program ex: "DE"
   *
   * @return {array} dateRange - the start and end dates of the eligibility period ex:
   * [
   *   [
   *      "2020-10-15",
   *      "2021-10-15"
   *   ],
   *   [
   *      "2022-10-15",
   *      "2024-10-15"
   *   ]
   * ]
   */
  async periodOfEligibility(
    tasParticipantId: string,
    programCode?: string
  ): Promise<string[][] | PeriodOfEligibility> {
    const url = this.buildURL('tas-participants', tasParticipantId) + '/periods-of-eligibility';
    const allEligibilities = await this.ajax(url, 'GET');
    return programCode ? allEligibilities[programCode] : allEligibilities;
  }

  async dynamicEligibility(
    tasParticipantId: string,
    programCode?: string
  ): Promise<boolean | DynamicEligibility> {
    const url = this.buildURL('tas-participants', tasParticipantId) + '/dynamic-eligibility';
    const allEligibilities = await this.ajax(url, 'GET');
    return programCode ? allEligibilities[programCode] : allEligibilities;
  }
}

export default TasParticipantAdapter;
