import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PartnerService from 'tio-employee/services/partner';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminEmployeesAddRoute extends Route {
  @service declare partner: PartnerService;
  @service declare store: StoreService;

  model() {
    const employee = this.store.createRecord('employee');
    employee.company = this.partner.company;
    return employee;
  }
}
