import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { ChartBounds } from './chart-types';
import type LoanModel from 'tio-common/models/loan';
import ChartPie from 'ember-apache-echarts/components/chart/pie';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didResize from 'tio-common/modifiers/did-resize';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
export interface PrincipalAndInterestChartSignature {
    Args: {
        loans: LoanModel[];
    };
}
export default class PrincipalAndInterestChart extends Component<PrincipalAndInterestChartSignature> {
    @tracked
    chartBounds?: ChartBounds;
    get loans() {
        return this.args.loans;
    }
    get principalAndInterestData() {
        const totalBalances1 = this.loans.reduce((accumulator1, loan1)=>{
            return {
                principalBalance: accumulator1.principalBalance + loan1.principalBalance,
                interestBalance: accumulator1.interestBalance + loan1.interestBalance
            };
        }, {
            principalBalance: 0,
            interestBalance: 0
        });
        const principal1 = Math.round(totalBalances1?.principalBalance * 2) / 2;
        const interest1 = Math.round(totalBalances1?.interestBalance * 2) / 2;
        const chartData1 = [
            {
                name: 'Principal Balance',
                value: principal1
            },
            {
                name: 'Interest Balance',
                value: interest1
            }
        ];
        return chartData1;
    }
    @action
    didResizeContainer(element1: HTMLElement) {
        const bounds1 = element1.getBoundingClientRect();
        this.chartBounds = {
            height: bounds1.height - 40,
            width: bounds1.width - 40
        };
    }
    static{
        template(`
    <div
      class="flex flex-col justify-center items-center w-full h-full"
      {{didInsert this.didResizeContainer}}
      {{didResize this.didResizeContainer debounce=50}}
    >
      {{#if this.chartBounds}}
        {{! @glint-expect-error: ts work on charts is happening }}
        <ChartPie
          @width={{this.chartBounds.width}}
          @height={{this.chartBounds.height}}
          @variant="donut"
          @data={{this.principalAndInterestData}}
        >
          {{! prettier-ignore }}
          {{!-- template-lint-disable no-triple-curlies --}}
          {{! @glint-expect-error: ts work on charts is happening }}
          <:itemTooltip as |item|>
            {{{item.marker}}}
            <b>{{item.data.name}}:</b>
            {{safeFormatNumber item.value style="currency" currency="USD"}}
          </:itemTooltip>
        </ChartPie>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
