import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';
import type FeaturesService from 'tio-common/services/features';
import type RouterService from './router';
import type SessionContextService from './session-context';
import type SessionService from './session';

export type Payload = {
  documentId: string;
};

export default class PslfFormService extends Service {
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;
  @service declare features: FeaturesService;
  @service declare router: RouterService;

  @tracked pslfSignatureRequest: Payload | null = null;

  @action
  async prepareForPSLFSignatureFromVue(payload: Payload) {
    this.pslfSignatureRequest = payload;
    const { documentId } = this.pslfSignatureRequest;
    this.router.transitionTo('authenticated.pslf.form.sign-form', {
      queryParams: { formId: documentId },
    });
  }
}
