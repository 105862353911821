import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import RepayingStudentDebtCoachScheduler from 'tio-employee/components/repaying-student-debt/coach-scheduler';
export default RouteTemplate(template(`
    <div class="p-10 pt-2">
      <RepayingStudentDebtCoachScheduler />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
