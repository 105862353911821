import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import PslfQualifyingPaymentsChart from 'tio-common/components/pslf/qualifying-payments/chart';
import { Divider } from 'tio-ui/components/utilities';
import type SessionContextService from 'tio-employee/services/session-context';
export interface PslfDashboardQualifyingPaymentsComponentSignature {
    Element: HTMLDivElement;
}
export default class PslfDashboardQualifyingPaymentsComponent extends Component<PslfDashboardQualifyingPaymentsComponentSignature> {
    @service
    sessionContext: SessionContextService;
    data = trackedFunction(this, async ()=>await this.sessionContext.user.aggregate({
            model: 'pslf-observations',
            rollups: {
                value: 'count()'
            }
        }));
    get hasQualifyingPayments() {
        return this.data.value?.length;
    }
    static{
        template(`
    {{#if this.hasQualifyingPayments}}
      <AppContentCard
        @titleClass="uppercase"
        @title={{t "common.pslf.qualifying_payments.default"}}
        ...attributes
      >
        <Divider class="my-2" />
        <PslfQualifyingPaymentsChart @user={{this.sessionContext.user}} />
      </AppContentCard>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
