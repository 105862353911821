import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class PslfEmployerRoute extends Route {
  @service declare router: RouterService;

  queryParams = {
    approval: { refreshModel: true, replace: true },
  };

  model(params: { form_document_id: string }) {
    const { form_document_id } = params;
    return {
      documentId: form_document_id,
    };
  }

  redirect() {
    if (this.queryParams.approval) {
      this.router.transitionTo('pslf-employer.non-partner-verification');
    } else {
      this.router.transitionTo('pslf-employer.verification');
    }
  }
}
