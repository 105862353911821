import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { object, number, string } from 'yup';
import { getTranslationKeyForReportedSalaryForMatchPlan } from 'tio-common/utils/syf';
import TioButton from 'tio-common/components/tio/button';
import TioForm from 'tio-common/components/tio/form';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type EmailAddressModel from 'tio-common/models/email-address';
import type { IntlService } from 'ember-intl';
interface SyfPostEnrollmentSignature {
    Args: {
        matchParticipant: MatchParticipantModel;
        emailAddress: EmailAddressModel;
        loading: boolean;
        onSubmit: () => void;
        onSkip: () => void;
    };
}
type SyfPostEnrollmentFormData = {
    reportedSalary: number;
    email: string;
};
let SyfEnrollmentConfirmationComponent = class SyfEnrollmentConfirmationComponent extends Component<SyfPostEnrollmentSignature> {
    @service
    intl: IntlService;
    formSubmitAction: (() => void) | undefined = undefined;
    initialFormData: SyfPostEnrollmentFormData | Record<string, never> = {};
    constructor(owner1: unknown, args1: SyfPostEnrollmentSignature['Args']){
        super(owner1, args1);
        const { matchParticipant: matchParticipant1, emailAddress: emailAddress1 } = this.args;
        this.initialFormData = {
            reportedSalary: (matchParticipant1.reportedSalary || 0) / 100,
            email: emailAddress1.email || ''
        };
    }
    get reportedSalaryFieldLabel() {
        return this.intl.t(getTranslationKeyForReportedSalaryForMatchPlan(this.args.matchParticipant.matchPlan));
    }
    @action
    updateModelsWithFormValues(args1: SyfPostEnrollmentSignature['Args'], formData1: SyfPostEnrollmentFormData) {
        const { reportedSalary: reportedSalary1, email: email1 } = formData1;
        const { matchParticipant: matchParticipant1, emailAddress: emailAddress1 } = args1;
        matchParticipant1.reportedSalary = reportedSalary1 * 100;
        emailAddress1.email = email1;
    }
    @action
    onSubmit(formData1: SyfPostEnrollmentFormData) {
        this.updateModelsWithFormValues(this.args, formData1);
        this.args.onSubmit();
    }
    @action
    onSkip() {
        this.args.onSkip();
    }
    formSchema = object().shape({
        reportedSalary: number().nullable(),
        email: string().email().nullable()
    });
    static{
        template(`
    {{! template-lint-disable no-bare-strings }}
    <h2 class="text-midnight text-large font-semibold mb-4">My Information</h2>
    <h3 class="font-semibold mb-2">We need a few more optional pieces of information to maximize
      your retirement benefits.</h3>

    <TioForm
      class=""
      @i18nPath="syf.plan_form"
      @data={{this.initialFormData}}
      {{! @glint-expect-error not sure}}
      @schema={{this.formSchema}}
      @onSubmit={{this.onSubmit}}
      as |form|
    >
      <div class="grid grid-cols-12 mt-8">
        <div class="col-span-4 col-start-5">
          <form.CurrencyField
            @containerClass="mb-8"
            @name="reportedSalary"
            @label={{this.reportedSalaryFieldLabel}}
            @hint="This information helps us estimate your benefit"
          />

          <form.InputField
            @containerClass="mb-8"
            @name="email"
            @type="email"
            @label="Personal Email"
            @hint="It is important for us to have a personal email for benefit communications"
          />

          <form.Errors class="my-6" />
        </div>
      </div>
      <div class="grid grid-cols-12 mt-8">
        <div class="col-span-4 col-start-5">
          <form.Button class="m-auto px-8 mb-4" @type="submit" @disableWhenInvalid={{false}}>
            Go to Dashboard
          </form.Button>

          <TioButton
            @disabled={{@loading}}
            @onClick={{this.onSkip}}
            @text={{true}}
            class="m-auto px-8 mb-4 capitalize"
          >
            Skip
          </TioButton>
        </div>
      </div>
    </TioForm>
    {{! template-lint-enable no-bare-strings }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default SyfEnrollmentConfirmationComponent;
