import { service } from '@ember/service';
import type PartnerService from 'tio-employee/services/partner';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';

export default class AuthenticatedTuitionAssistanceIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare partner: PartnerService;

  get isNewTuitionAssistance() {
    return !this.partner.hasLegacyTuitionAssistance;
  }

  redirect() {
    if (this.isNewTuitionAssistance) {
      this.router.transitionTo('authenticated.tuition-assistance.dashboard');
    }
  }
}
