import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import { service } from '@ember/service';
import type { AuthenticatedTuitionAssistanceProgramsInstanceRouteModel } from '../../instance';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';

export type TasProgramsInstanceApplyProgramInformationRouteModel = {
  programInstance: AuthenticatedTuitionAssistanceProgramsInstanceRouteModel;
  selectedSchool?: TasUnifiedInstitutionModel;
};

export default class AuthenticatedTuitionAssistanceProgramsInstanceApplyProgramInformationRoute extends Route {
  @service declare store: StoreService;

  async model(): Promise<TasProgramsInstanceApplyProgramInformationRouteModel> {
    const instance = this.modelFor(
      'authenticated.tuition-assistance.programs.instance.apply'
    ) as AuthenticatedTuitionAssistanceProgramsInstanceRouteModel;

    let selectedSchool: TasUnifiedInstitutionModel | undefined;

    try {
      const tasInstitutionId = instance.fields['TAS_INSTITUTION_ID']?.values?.[0];
      const dapipInstitutionId = instance.fields['DAPIP_INSTITUTION_ID']?.values?.[0];

      if (tasInstitutionId || dapipInstitutionId) {
        const filter: Record<string, string | number | undefined> = {
          'tas-program-template': instance.tasProgramTemplate.id,
        };

        if (dapipInstitutionId) {
          filter['dapip-id'] = dapipInstitutionId;
        }

        if (tasInstitutionId) {
          filter['institution-id'] = tasInstitutionId;
        }
        const results = await this.store.query('tas-unified-institution', {
          filter,
          page: { limit: 1, offset: 0 },
        });
        selectedSchool = results.objectAt(0);
      }
    } catch (e) {
      console.error('Error attempting to load previously selected school', e);
    }

    return {
      programInstance: instance,
      selectedSchool: selectedSchool,
    };
  }
}
