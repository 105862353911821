import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedIdrDashboardForgivenessEstimatorResultsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  model({ recommendation_id }: { recommendation_id: string }) {
    return this.store.findRecord('recommendation', recommendation_id);
  }
}
