import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type PslfProfile from 'tio-common/models/pslf-profile';
import type PslfFormModel from 'tio-common/models/pslf-form';

export default class AuthenticatedPslfDashboardStartNewRoute extends Route {
  queryParams = {
    pslfFlag: { refreshModel: true },
  };

  @service declare store: StoreService;

  async model() {
    const pslfProfile = this.modelFor('authenticated.pslf') as PslfProfile;
    const currentEmployee = pslfProfile.employee;
    const filter = {
      'pslf-profile': pslfProfile.id,
      status: 'created',
    };

    const inProgressForms = (await this.store.query('pslfForm', {
      filter,
      sort: '-created-at',
    })) as PslfFormModel[];
    const inProgressCurrentEmployerForm = inProgressForms
      .slice()
      .filter((form) => form.isCurrentEmployer)[0];
    const inProgressPriorEmployerForm = inProgressForms
      .slice()
      .filter((form) => form.isPriorEmployer)[0];

    return {
      inProgressCurrentEmployerForm: inProgressCurrentEmployerForm,
      inProgressPriorEmployerForm: inProgressPriorEmployerForm,
      employee: currentEmployee,
    };
  }
}
