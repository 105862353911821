import ApplicationSerializer from './application';

export default class AccountSerializer extends ApplicationSerializer {
  belongsToRelationshipToOmit = ['financialInstitution'];
  // status attribute comes from the account observation in the API
  // resource response, and the value can fail the DB enum constraint;
  // we probably shouldn't have ever been serializing this attr for
  // PUT requests anyway
  attributesToOmit = ['status'];

  // TODO: now that this shows up on multiple serializers we could
  // consider moving it to the ApplicationSerializer class
  // TODO: these typings are bad and should be better; moving this
  // functionality to the parent class could be the right time to
  // address this, or could try to do it sooner
  serializeBelongsTo(
    snapshot: unknown,
    json: unknown,
    relationship: { key: string },
    ...rest: unknown[]
  ) {
    if (this.belongsToRelationshipToOmit.includes(relationship.key)) {
      return;
    }
    super.serializeBelongsTo(snapshot, json, relationship, ...rest);
  }

  serializeAttribute(
    snapshot: unknown,
    json: unknown,
    key: string,
    attributes: unknown,
    ...rest: unknown[]
  ) {
    if (this.attributesToOmit.includes(key)) {
      return;
    }
    super.serializeAttribute(snapshot, json, key, attributes, ...rest);
  }
}
