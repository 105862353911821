import Service, { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

import type MatchPaymentModel from 'tio-common/models/match-payment';

export default class MatchPaymentService extends Service {
  @service declare store: StoreService;

  async certifyMatchPayments(payments: Array<MatchPaymentModel>) {
    const { data } = await this.store.adapterFor('match-payment').certifyPayments(payments);
    this.store.pushPayload({ data });
  }
}
