import ApplicationAdapter from './application';

const BEGIN_ENROLLMENT_RESOURCE: string = '/begin-enrollment';
const COMPLETE_ENROLLMENT_RESOURCE: string = '/attempt-complete-enrollment';

/**
 * @memberof adapters
 */
export default class MatchParticipantAdapter extends ApplicationAdapter {
  async beginEnrollment({ id }: { id: string }) {
    const url = this.buildURL('match-participant', id) + BEGIN_ENROLLMENT_RESOURCE;
    return await this.ajax(url, 'PATCH', {});
  }

  async activateMatchParticipant({ id }: { id: string }) {
    const url = this.buildURL('match-participant', id) + COMPLETE_ENROLLMENT_RESOURCE;
    return await this.ajax(url, 'PATCH', {});
  }

  async getInsights({ id }: { id: string }) {
    const url = this.buildURL('match-participant', id) + '/insights';
    const response = await this.ajax(url, 'GET');
    return response.data;
  }
}
