import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router-service';
import type { AuthenticatedSyfRouteModel } from 'tio-employee/routes/authenticated/syf';

export default class AuthenticatedSyfPostEnrollmentRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;

  // the main model here is a match participant so that the user can
  // directly update its reportedSalary attribute; the route component
  // derives its email address (must be type 'PERSONAL') or creates
  // one for editing
  model() {
    return this.modelFor('authenticated.syf') as AuthenticatedSyfRouteModel;
  }
}
