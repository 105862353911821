import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type PslfFormService from 'tio-employee/services/pslf-form';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedPslfSignFormRoute extends Route {
  @service declare pslfForm: PslfFormService;
  @service declare store: StoreService;

  queryParams = {
    formId: { refreshModel: true },
    signer: { refreshModel: false },
  };

  async model(params: { formId: string; signer: string }) {
    const model = await this.store.findRecord('pslf-form', params.formId);
    return {
      form: model,
      signer: params.signer,
    };
  }
}
