import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import type InstitutionModel from 'tio-common/models/financial-institution';
import PowerSelect from 'ember-power-select/components/power-select';
import type StoreService from 'tio-common/services/store';
import { t } from 'ember-intl';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
import { Section } from 'tio-ui/components/layout';
const INPUT_DEBOUNCE = ENV.environment === 'test' ? 0 : 250;
const FEDERAL_SERVICERS = [
    'nelnet',
    'mohela',
    'edfinancial',
    'aidvantage'
];
const stripFederalLoanServicers = (institutions1: InstitutionModel[]): InstitutionModel[] =>{
    return institutions1.filter((institution1)=>{
        return !FEDERAL_SERVICERS.some((institutionMatch1)=>{
            return institution1.name.toLowerCase().includes(institutionMatch1);
        });
    });
};
interface InstitutionPickerSignature {
    Args: {
        onChange: (institution: InstitutionModel) => void;
    };
}
let InstitutionPickerComponent = class InstitutionPickerComponent extends Component<InstitutionPickerSignature> {
    @service
    store: StoreService;
    @tracked
    institutions: InstitutionModel[] = [];
    @tracked
    institutionsForDisplay: InstitutionModel[] = [];
    @tracked
    selectedInstitution?: InstitutionModel;
    constructor(owner1: unknown, args1: InstitutionPickerSignature['Args']){
        super(owner1, args1);
        // This could be removed if we used a resource
        this.loadRecords.perform();
    }
    loadRecords = restartableTask(async ()=>{
        const results1 = await this.store.query('financial-institution', {
            include: 'logo',
            sort: 'publicOrdinalValue',
            filter: {
                isActive: true,
                isPublic: true
            },
            page: {
                offset: 0,
                limit: 12
            }
        });
        this.institutionsForDisplay = stripFederalLoanServicers(results1);
    });
    // For searching in the select on user input
    // Look into documentation for ember-power-select for how we might
    // already start the dropdown with the previously loaded "featured"
    // servicers
    queryInstitutions = restartableTask(async (searchString1)=>{
        await timeout(INPUT_DEBOUNCE);
        const results1 = await this.store.query('financial-institution', {
            include: 'logo',
            filter: {
                isActive: true,
                isPublic: true,
                search: searchString1
            }
        });
        return stripFederalLoanServicers(results1);
    });
    static{
        template(`
    <Section>
      <:body>
        <div class="flex flex-col justify-center items-center gap-6">
          {{#if this.loadRecords.isRunning}}
            <TioLoadingSpinner />
          {{else}}
            {{! @glint-expect-error https://github.com/cibernox/ember-power-select/issues/1585}}
            <PowerSelect
              name="institution-filter"
              @options={{this.institutionsForDisplay}}
              @placeholder={{t "accounts.enter_servicer"}}
              @allowClear={{true}}
              {{! @glint-expect-error https://github.com/cibernox/ember-power-select/issues/1585}}
              @search={{this.queryInstitutions.perform}}
              @searchEnabled={{true}}
              @selected={{this.selectedInstitution}}
              @onChange={{@onChange}}
              class="w-full sm:w-3/5"
              as |institution|
            >
              {{institution.name}}
            </PowerSelect>
            <div
              class="items-stretch grid grid-cols-2 sm:grid-cols-4 gap-5 place-items-center auto-rows-fr w-4/5"
            >
              {{#each this.institutionsForDisplay as |institution|}}
                <button
                  type="button"
                  class="border flex w-full justify-center items-center p-2"
                  {{on "click" (fn @onChange institution)}}
                >
                  <img
                    class="object-contain h-24 w-48 p-4"
                    src={{institution.logo.signedUrl}}
                    alt={{institution.name}}
                  />
                </button>
              {{/each}}
            </div>
          {{/if}}
        </div>
      </:body>
    </Section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default InstitutionPickerComponent;
