import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t, type IntlService } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import FormTextarea from '@frontile/forms-legacy/components/form-textarea';
import type { ValidationError } from 'tio-employee/services/employee';
import type EmployeeService from 'tio-employee/services/employee';
import type NotificationsService from '@frontile/notifications/services/notifications';
import type SessionContextService from 'tio-employee/services/session-context';
interface SupportEmailModalSignature {
    Args: {
        show: boolean;
        onClose: () => void;
        error: ValidationError;
    };
}
export default class SupportEmailModal extends Component<SupportEmailModalSignature> {
    @service
    notifications: NotificationsService;
    @service
    sessionContext: SessionContextService;
    @service
    employee: EmployeeService;
    @service
    intl: IntlService;
    @tracked
    additionalComments: string = '';
    @tracked
    uploadedFile: File | null = null;
    @tracked
    isEmailLoading: boolean = false;
    get isSubmitDisabled() {
        return this.uploadedFile === null;
    }
    get validationError() {
        return this.args.error;
    }
    @action
    onClose() {
        this.additionalComments = '';
        this.uploadedFile = null;
        this.isEmailLoading = false;
        this.args.onClose();
    }
    @action
    onAddFile(event1: Event) {
        const inputElement1 = event1.target as HTMLInputElement;
        if (inputElement1.files) {
            this.uploadedFile = inputElement1.files[0] ?? null;
        }
    }
    @action
    async onSubmit() {
        this.isEmailLoading = true;
        const headers1 = {
            'x-api-key': ENV.apiKey,
            'tio-auth-token': this.sessionContext.session.data.authenticated.access_token
        };
        const url1 = `${ENV.apiHost}/support-email`;
        const body1 = new FormData();
        this.uploadedFile && body1.append('attachments', this.uploadedFile);
        body1.append('subject', 'SLR Contributions - loan statement attachment');
        body1.append('employee_id', this.employee.current.id);
        const content1 = `
      <p>Employee Name: ${this.employee.current.fullName}</p>
      <p>Employee Email: ${this.employee.current.email}</p>
      <p>Employee ID: ${this.employee.current.id}</p>

      <p>Validation Error: ${this.validationError.key}</p>
      <p>Error Message: ${this.validationError.message}</p>

      <p>User Comments: ${this.additionalComments}</p>
    `;
        body1.append('content', content1);
        const response1 = await fetch(url1, {
            method: 'POST',
            body: body1,
            headers: headers1
        });
        if (!response1.ok) {
            this.notifications.add('Something went wrong', {
                appearance: 'error'
            });
            this.onClose();
            return;
        }
        this.notifications.add('File attached successfully', {
            appearance: 'success'
        });
        this.onClose();
    }
    static{
        template(`
    <Modal
      @isOpen={{@show}}
      @onClose={{this.onClose}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>{{t "slr.support_modal.attach_statement"}}</m.Header>
      <m.Body>
        <p class="text-gray-600">{{t "slr.support_modal.instructions"}}</p>
        <ul class="list-disc list-inside text-gray-600">
          <li>{{t "slr.support_modal.full_name"}}</li>
          <li>{{t "slr.support_modal.account_number"}}</li>
          <li>{{t "slr.support_modal.address"}}</li>
        </ul>
        <label for="file_input" class="sr-only">
          {{t "slr.support_modal.attach_file"}}
        </label>
        <input
          id="file_input"
          type="file"
          class="block w-full text-sm text-slate-500 file:cursor-pointer file:border-0 file:mr-4 file:py-2 file:px-4 file:rounded-md file:text-sm file:font-semibold hover:file:bg-gray-200"
          {{on "change" this.onAddFile}}
        />
        <FormTextarea
          data-legacy-input
          @label="Additional Comments"
          @value={{this.additionalComments}}
          @onInput={{fn (mut this.additionalComments)}}
          @size="sm"
          rows="4"
        />
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.isEmailLoading}}
          @onSubmit={{this.onSubmit}}
          @submitDisabled={{this.isSubmitDisabled}}
          @onCancel={{this.onClose}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
