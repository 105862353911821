import { template } from "@ember/template-compiler";
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import type { IntlService } from 'ember-intl';
import { t } from 'ember-intl';
import { eq } from 'tio-ui/utilities';
import TioButton from 'tio-common/components/tio/button';
import TioSearchInput from 'tio-common/components/tio/search-input';
import ENV from 'tio-employee/config/environment';
const INPUT_DEBOUNCE = ENV.environment === 'test' ? 0 : 250;
type GlossaryTerm = {
    term: string;
    translatedTitle: string;
    translatedDescription: string;
    link?: string;
};
export default class QuestionsGlossaryComponent extends Component {
    @service
    intl: IntlService;
    @tracked
    letterFilter = '';
    @tracked
    search = '';
    alphabet = Array.from({
        length: 26
    }, (_1, i1)=>String.fromCharCode(i1 + 65));
    digits = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9'
    ];
    glossaryTerms: GlossaryTerm[] = [];
    constructor(owner1: unknown, args1: object){
        super(owner1, args1);
        this.glossaryTerms = [
            {
                term: 'avalanche',
                translatedTitle: this.intl.t('questions_you_have.glossary.avalanche.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.avalanche.description')
            },
            {
                term: 'default',
                translatedTitle: this.intl.t('questions_you_have.glossary.default.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.default.description')
            },
            {
                term: 'deferment',
                translatedTitle: this.intl.t('questions_you_have.glossary.deferment.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.deferment.description')
            },
            {
                term: 'delinquent',
                translatedTitle: this.intl.t('questions_you_have.glossary.delinquent.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.delinquent.description')
            },
            {
                term: 'employer_repayment',
                translatedTitle: this.intl.t('questions_you_have.glossary.employer_repayment.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.employer_repayment.description')
            },
            {
                term: 'entrance_counseling',
                translatedTitle: this.intl.t('questions_you_have.glossary.entrance_counseling.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.entrance_counseling.description')
            },
            {
                term: 'exit_counseling',
                translatedTitle: this.intl.t('questions_you_have.glossary.exit_counseling.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.exit_counseling.description')
            },
            {
                term: 'federal_loans',
                translatedTitle: this.intl.t('questions_you_have.glossary.federal_loans.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.federal_loans.description')
            },
            {
                term: 'federal_student_aid',
                link: 'authenticated.planning-for-college',
                translatedTitle: this.intl.t('questions_you_have.glossary.federal_student_aid.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.federal_student_aid.description')
            },
            {
                term: 'fees',
                translatedTitle: this.intl.t('questions_you_have.glossary.fees.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.fees.description')
            },
            {
                term: 'financial_aid',
                link: 'authenticated.questions-you-have.paying-for-college.index',
                translatedTitle: this.intl.t('questions_you_have.glossary.financial_aid.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.financial_aid.description')
            },
            {
                term: 'fixed_interest_rates',
                translatedTitle: this.intl.t('questions_you_have.glossary.fixed_interest_rates.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.fixed_interest_rates.description')
            },
            {
                term: 'forbearance',
                translatedTitle: this.intl.t('questions_you_have.glossary.forbearance.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.forbearance.description')
            },
            {
                term: 'grad_plus_loans',
                translatedTitle: this.intl.t('questions_you_have.glossary.grad_plus_loans.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.grad_plus_loans.description')
            },
            {
                term: 'good_standing',
                translatedTitle: this.intl.t('questions_you_have.glossary.good_standing.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.good_standing.description')
            },
            {
                term: 'grace_period',
                translatedTitle: this.intl.t('questions_you_have.glossary.grace_period.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.grace_period.description')
            },
            {
                term: 'hard_check',
                translatedTitle: this.intl.t('questions_you_have.glossary.hard_check.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.hard_check.description')
            },
            {
                term: 'ibr',
                translatedTitle: this.intl.t('questions_you_have.glossary.ibr.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.ibr.description')
            },
            {
                term: 'icr',
                translatedTitle: this.intl.t('questions_you_have.glossary.icr.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.icr.description')
            },
            {
                term: 'income_repayment',
                translatedTitle: this.intl.t('questions_you_have.glossary.income_repayment.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.income_repayment.description')
            },
            {
                term: 'loan_consolidation',
                translatedTitle: this.intl.t('questions_you_have.glossary.loan_consolidation.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.loan_consolidation.description')
            },
            {
                term: 'loan_forgiveness',
                translatedTitle: this.intl.t('questions_you_have.glossary.loan_forgiveness.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.loan_forgiveness.description')
            },
            {
                term: 'loan_rehabilitation',
                translatedTitle: this.intl.t('questions_you_have.glossary.loan_rehabilitation.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.loan_rehabilitation.description')
            },
            {
                term: 'loan_term',
                translatedTitle: this.intl.t('questions_you_have.glossary.loan_term.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.loan_term.description')
            },
            {
                term: 'monthly_payment',
                translatedTitle: this.intl.t('questions_you_have.glossary.monthly_payment.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.monthly_payment.description')
            },
            {
                term: 'negative_amortization',
                translatedTitle: this.intl.t('questions_you_have.glossary.negative_amortization.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.negative_amortization.description')
            },
            {
                term: 'nslds',
                translatedTitle: this.intl.t('questions_you_have.glossary.nslds.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.nslds.description')
            },
            {
                term: 'nslds_pin',
                translatedTitle: this.intl.t('questions_you_have.glossary.nslds_pin.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.nslds_pin.description')
            },
            {
                term: 'parent_plus_loans',
                translatedTitle: this.intl.t('questions_you_have.glossary.parent_plus_loans.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.parent_plus_loans.description')
            },
            {
                term: 'paye',
                translatedTitle: this.intl.t('questions_you_have.glossary.paye.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.paye.description')
            },
            {
                term: 'pell_grant',
                translatedTitle: this.intl.t('questions_you_have.glossary.pell_grant.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.pell_grant.description')
            },
            {
                term: 'perkins_loans',
                translatedTitle: this.intl.t('questions_you_have.glossary.perkins_loans.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.perkins_loans.description')
            },
            {
                term: 'private_loans',
                link: 'authenticated.questions-you-have.paying-for-college.private-loans',
                translatedTitle: this.intl.t('questions_you_have.glossary.private_loans.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.private_loans.description')
            },
            {
                term: 'promissory_note',
                translatedTitle: this.intl.t('questions_you_have.glossary.promissory_note.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.promissory_note.description')
            },
            {
                term: 'pslf',
                translatedTitle: this.intl.t('questions_you_have.glossary.pslf.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.pslf.description')
            },
            {
                term: 'refinancing',
                link: 'authenticated.repaying-student-debt.repayment-options',
                translatedTitle: this.intl.t('questions_you_have.glossary.refinancing.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.refinancing.description')
            },
            {
                term: 'repayment_plan',
                translatedTitle: this.intl.t('questions_you_have.glossary.repayment_plan.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.repayment_plan.description')
            },
            {
                term: 'repaye',
                translatedTitle: this.intl.t('questions_you_have.glossary.repaye.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.repaye.description')
            },
            {
                term: 'room_board',
                translatedTitle: this.intl.t('questions_you_have.glossary.room_board.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.room_board.description')
            },
            {
                term: 'scholarship',
                link: 'authenticated.planning-for-college.scholarship',
                translatedTitle: this.intl.t('questions_you_have.glossary.scholarship.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.scholarship.description')
            },
            {
                term: 'snowball_method',
                translatedTitle: this.intl.t('questions_you_have.glossary.snowball_method.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.snowball_method.description')
            },
            {
                term: 'soft_credit_check',
                translatedTitle: this.intl.t('questions_you_have.glossary.soft_credit_check.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.soft_credit_check.description')
            },
            {
                term: 'student_loan_discharge',
                translatedTitle: this.intl.t('questions_you_have.glossary.student_loan_discharge.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.student_loan_discharge.description')
            },
            {
                term: 'subsidized_loans',
                translatedTitle: this.intl.t('questions_you_have.glossary.subsidized_loans.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.subsidized_loans.description')
            },
            {
                term: 'teacher_forgiveness',
                translatedTitle: this.intl.t('questions_you_have.glossary.teacher_forgiveness.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.teacher_forgiveness.description')
            },
            {
                term: 'til',
                translatedTitle: this.intl.t('questions_you_have.glossary.til.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.til.description')
            },
            {
                term: 'tuition',
                translatedTitle: this.intl.t('questions_you_have.glossary.tuition.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.tuition.description')
            },
            {
                term: 'unsubsidized_loans',
                translatedTitle: this.intl.t('questions_you_have.glossary.unsubsidized_loans.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.unsubsidized_loans.description')
            },
            {
                term: 'variable_rates',
                translatedTitle: this.intl.t('questions_you_have.glossary.variable_rates.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.variable_rates.description')
            },
            {
                term: '529_savings',
                link: 'authenticated.planning-for-college.529-tools',
                translatedTitle: this.intl.t('questions_you_have.glossary.529_savings.title'),
                translatedDescription: this.intl.t('questions_you_have.glossary.529_savings.description')
            }
        ];
    }
    get availableFilters() {
        let leadingCharacters1: string[] = [];
        this.glossaryTerms.forEach((t1)=>{
            // @ts-expect-errorL lots of assumption about data
            if (!leadingCharacters1.includes(t1.term[0].toLowerCase())) {
                // @ts-expect-errorL lots of assumption about data
                leadingCharacters1.push(t1.term[0].toLowerCase());
            }
        });
        return leadingCharacters1;
    }
    get filterMenu() {
        const filterObjects1 = [];
        filterObjects1.push({
            name: 'ALL',
            filters: null,
            isAvailable: true
        });
        this.alphabet.forEach((upperLetter1)=>{
            const lowerLetter1 = upperLetter1.toLowerCase();
            filterObjects1.push({
                name: upperLetter1,
                filters: [
                    lowerLetter1
                ],
                isAvailable: this.availableFilters.includes(lowerLetter1)
            });
        });
        filterObjects1.push({
            name: '#',
            filters: this.digits,
            isAvailable: this.digits.reduce((soFar1, num1)=>{
                return this.availableFilters.includes(num1) || soFar1;
            }, false)
        });
        return filterObjects1;
    }
    get filteredTerms() {
        let filteredTermArray1 = [
            ...this.glossaryTerms
        ];
        if (this.letterFilter) {
            // @ts-expect-errorL lots of assumption about data
            filteredTermArray1 = filteredTermArray1.filter((t1)=>this.letterFilter.includes(t1.term[0]));
        }
        if (this.search && this.search.length > 0) {
            filteredTermArray1 = filteredTermArray1.filter((t1)=>{
                return (t1.translatedTitle.toLowerCase().includes(this.search.toLowerCase()) || t1.translatedDescription.toLowerCase().includes(this.search.toLowerCase()));
            });
        }
        return filteredTermArray1;
    }
    get noSearchResults() {
        return this.filteredTerms.length == 0;
    }
    @action
    setFilter(newFilter1: string) {
        this.letterFilter = newFilter1;
        return;
    }
    didSearch = restartableTask(async (search1)=>{
        await timeout(INPUT_DEBOUNCE);
        this.search = search1;
    });
    static{
        template(`
    <div class="pb-4 ml-4">
      <div class="max-width-[95vw]">
        <span>
          {{#each this.filterMenu as |filter|}}
            {{#if filter.isAvailable}}
              <button
                class="pr-2 pt-4
                  {{if
                    (eq this.letterFilter filter.filters)
                    'font-bold text-ocean-800 underline'
                    'font-semibold text-ocean-600'
                  }}"
                type="button"
                {{! @glint-expect-error: not sure}}
                {{on "click" (fn this.setFilter filter.filters)}}
              >
                {{filter.name}}
              </button>
            {{else}}
              <span class="pr-2 pt-4 font-semibold text-gray-600">{{filter.name}}</span>
            {{/if}}
          {{/each}}
        </span>
        <span class="pl-6">
          <TioSearchInput
            {{! @glint-expect-error: This label is wrong and we don't have an on submit }}
            @showLabel={{true}}
            @searchString={{this.search}}
            @onInput={{this.didSearch.perform}}
            @ariaLabel={{t "questions_you_have.glossary.search"}}
            @placeholder={{t "questions_you_have.glossary.search"}}
            class="max-w-screen-sm inline pt-4 lg:inline-block lg:pt-0"
          />
        </span>
      </div>
      {{#each this.filteredTerms as |entry|}}
        <h1 class="text-base font-semibold text-gray-500 pt-3">{{entry.translatedTitle}}</h1>
        <h1 class="text-base text-gray-500">{{entry.translatedDescription}}</h1>
        {{#if entry.link}}
          <TioButton @linkTo={{entry.link}} @text={{true}}>
            {{t (concat "questions_you_have.glossary." entry.term ".link_text")}}
          </TioButton>
        {{/if}}
      {{/each}}
      {{#if this.noSearchResults}}
        <h1 class="text-base font-semibold text-gray-500 pt-3">{{t
            "questions_you_have.glossary.no_results"
          }}</h1>
        <h1 class="text-base text-gray-500">{{t "questions_you_have.glossary.try_new_search"}}</h1>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
