import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCourseApplicationsShowEditAddCourseRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;

  constructor(owner: Owner) {
    super(owner);

    // If a user is transitioning away without saving, delete any unsaved records from the store
    this.router.on('routeDidChange', (transition) => {
      // @ts-expect-error: this might be a bug. name doesnt appear on route.
      if (!transition.to.find((route: Route) => route.name === this.routeName)) {
        const courses = this.store.peekAll('tas-course') as TasCourse[];
        const unsaved = courses.filter((course) => course.isNew);
        unsaved.forEach((course) => {
          course.rollbackAttributes();
        });
      }
    });
  }

  model() {
    const application = this.modelFor(
      'authenticated.tuition-assistance.programs.instance.course-applications.show'
    ) as TasApplication;
    return this.store.createRecord('tas-course', {
      tasApplication: application,
      fields: {},
      customFields: application.tasProgramInstance.tasProgramTemplate.courseCustomFields,
    });
  }
}
