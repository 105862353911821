import { template } from "@ember/template-compiler";
import { and } from 'tio-ui/utilities';
import { t } from 'ember-intl';
import AppPageProsVsCons from './pros-vs-cons';
import { Divider } from 'tio-ui/components/utilities';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
type Detail = {
    title: string;
    description: string;
};
type MoreSection = {
    title: string;
    description: string;
};
interface AppPagePlanDetailsSignature {
    Args: {
        cons: string[];
        description?: string;
        details: Detail[];
        moreSections?: MoreSection[];
        pros: string[];
        title?: string;
    };
}
// component requires the @details attribute:
// @details must be an array of objects, each with a title (string) and a description (string)
// --All above strings must be translation keys--
// component expects @pros & @cons
// @pros & @cons must be arrays of strings
// --All above strings must be translation keys--
// --If either @pros or @cons is not given, niether will be displayed--
// component can also accept three optional attributes:
// @title must be a string, otherwise will default to "plan details"
// @description must be a string
// @moreSections must be an array of objects, each with a title (string) and a description (string)
// --All above strings must be translation keys--
const AppPagePlanDetailsComponent: TOC<AppPagePlanDetailsSignature> = template(`
  <div class="p-4 mt-6 bg-gray-50">
    <h1 class="text-midnight text-xl font-semibold">
      {{if @title (t @title) (t "plan_details.title")}}
    </h1>
    {{#if @description}}
      <p class="pt-4">
        {{t @description htmlSafe=true}}
      </p>
    {{/if}}
    <div class="pt-6 pb-2 flex flex-col sm:grid sm:gap-2 sm:grid-cols-{{@details.length}}">
      {{#each @details as |detail|}}
        <div>
          <p class="pt-4 font-semibold text-gray-600">
            {{t detail.title}}
          </p>
          <p>
            {{t detail.description htmlSafe=true}}
          </p>
        </div>
      {{/each}}
    </div>
    {{#if (and @pros @cons)}}
      <Divider class="my-2" />
      <AppPageProsVsCons @pros={{@pros}} @cons={{@cons}} />
    {{/if}}
    {{#if @moreSections}}
      <Divider class="my-2" />
      {{#each @moreSections as |section|}}
        <div>
          <p class="pt-4 font-semibold text-gray-600">
            {{t section.title htmlSafe=true}}
          </p>
          <p class="pt-4">
            {{t section.description htmlSafe=true}}
          </p>
        </div>
      {{/each}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppPagePlanDetailsComponent;
