import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type PartnerService from 'tio-employee/services/partner';

export default class AuthenticatedAdminTuitionAssistanceDashboardRoute extends Route {
  @service declare store: StoreService;
  @service declare partner: PartnerService;

  async model() {
    const templates = await this.store.query('tas-program-template', {
      filter: {
        state: 'TAS.ProgramTemplateState.LAUNCHED',
        company: this.partner.company?.id,
        isTrial: 'true,false',
      },
    });

    return {
      templates,
    };
  }
}
