import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router';

export default class RedirectRoute extends Route {
  @service declare router: RouterService;

  async beforeModel() {
    const query = location.search;
    const params = new URLSearchParams(query);

    switch (params.get('target')) {
      case 'tuition-assistance/dashboard':
        this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        break;
      case 'admin/tuition-assistance/applications':
        this.router.transitionTo('authenticated.admin.tuition-assistance.applications');
        break;
      default:
        this.router.transitionTo('login');
    }
  }
}
