import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router';

export default class AuthenticatedAdminTuitionAssistanceIndexRoute extends Route {
  @service declare router: RouterService;

  beforeModel() {
    this.router.transitionTo('authenticated.admin.tuition-assistance.dashboard');
  }
}
