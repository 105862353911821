import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { UploadFile } from 'ember-file-upload';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type IntlService from 'ember-intl/services/intl';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import Document from 'ember-static-heroicons/components/outline-24/document';
import ExclamationTriangle from 'ember-static-heroicons/components/outline-24/exclamation-triangle';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import { or } from 'tio-ui/utilities';
import { Button } from 'tio-ui/components/buttons';
import CardGeneric from 'tio-ui/components/card-generic';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import { EXTRACTION_STATES } from 'tio-common/models/observable-document';
import DocumentUploadProgressBar from 'tio-employee/components/observability/upload-progress-bar';
const parseState = (state1: keyof typeof EXTRACTION_STATES): string =>{
    return state1.substring(state1.indexOf('.') + 1);
};
interface UploadProgressSignature {
    Args: {
        upload: UploadFile;
        observableDocument?: ObservableDocumentModel;
        uploadError?: Error;
        onRetry: () => void;
        timedOut: boolean;
    };
    Element: HTMLDivElement;
}
export default class UploadProgress extends Component<UploadProgressSignature> {
    @service
    intl: IntlService;
    get documentFailed() {
        const { observableDocument: observableDocument1 } = this.args;
        const state1 = observableDocument1?.extractionState;
        return state1 === 'ExtractionState.FAILED';
    }
    get documentInvalid() {
        const { uploadError: uploadError1, observableDocument: observableDocument1 } = this.args;
        const state1 = observableDocument1?.extractionState;
        return !!uploadError1 || state1 === 'ExtractionState.INVALID';
    }
    get documentSuccessful() {
        return this.args.observableDocument?.extractionState === 'ExtractionState.PROCESSED';
    }
    get processingTimedOut() {
        const { timedOut: timedOut1 } = this.args;
        const terminalStates1 = [
            this.documentSuccessful,
            this.documentInvalid,
            this.documentFailed
        ];
        return timedOut1 && !terminalStates1.some(Boolean);
    }
    get statusTextColor() {
        const { uploadError: uploadError1 } = this.args;
        if (uploadError1 || this.documentFailed || this.documentInvalid) {
            return 'text-red-700';
        } else if (this.documentSuccessful) {
            return 'text-green-700';
        }
        return 'text-indigo-700';
    }
    get progressBarStatus() {
        const { observableDocument: observableDocument1, uploadError: uploadError1 } = this.args;
        const translation1 = 'observability.uploads.progress.status';
        if (uploadError1) {
            return this.intl.t(translation1, {
                state: 'INVALID'
            });
        } else if (observableDocument1) {
            const state1 = parseState(observableDocument1.extractionState);
            return this.intl.t(translation1, {
                state: state1
            });
        }
        return this.intl.t(translation1, {
            state: null
        });
    }
    get progressBarMessage() {
        const { observableDocument: observableDocument1, uploadError: uploadError1 } = this.args;
        const translation1 = 'observability.uploads.progress.subtitle';
        if (uploadError1 || this.documentInvalid) {
            return this.intl.t(translation1, {
                state: 'INVALID'
            });
        } else if (observableDocument1) {
            const state1 = parseState(observableDocument1.extractionState);
            return this.intl.t(translation1, {
                state: state1
            });
        }
        return this.intl.t(translation1, {
            state: null
        });
    }
    static{
        template(`
    <div class="p-4">
      <div class="flex items-center my-4 text-tio-gray-600">
        <Document class="w-6 mr-2" />
        <span>{{@upload.file.name}}</span>
        {{#if (or this.documentInvalid this.documentFailed)}}
          <ExclamationTriangle class="w-6 ml-2 text-red-700" />
        {{else if this.documentSuccessful}}
          <CheckCircle class="w-6 ml-2 text-green-700" />
        {{/if}}
      </div>
      <DocumentUploadProgressBar
        @observableDocument={{@observableDocument}}
        @upload={{@upload}}
        @uploadError={{@uploadError}}
      />
      <div class="min-h-[4rem] my-4 {{this.statusTextColor}}">
        <h3 class="tio-h4 uppercase">{{this.progressBarStatus}}</h3>
        {{#if this.progressBarMessage}}
          <p>{{this.progressBarMessage}}</p>
        {{/if}}
      </div>
      {{#if this.processingTimedOut}}
        <CardGeneric class="p-4">
          <:header>{{t "observability.uploads.timeout.header"}}</:header>
          <:body>{{t "observability.uploads.timeout.body"}}</:body>
        </CardGeneric>
      {{else if (or this.documentInvalid this.documentFailed)}}
        <Button {{on "click" @onRetry}} @appearance="outlined">
          {{t "observability.uploads.retry"}}
        </Button>
      {{/if}}
      {{#if this.documentFailed}}
        <p class="my-4">
          {{t "observability.uploads.support.prompt"}}
          <span class="block text-sm my-2">
            {{htmlSafe
              (t
                "observability.uploads.support.email"
                email="support@tuition.io"
                linkClass="tio-link"
              )
            }}
          </span>
          <span class="block text-sm my-2">
            {{t "observability.uploads.support.phone"}}
          </span>
        </p>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
