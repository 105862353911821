import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import SelectParticipationStatus from 'tio-common/components/select/participation-status';
import SelectPlans from 'tio-common/components/select/plans';
import Table from 'tio-common/components/table/index';
import TablePaginator from 'tio-common/components/table/paginator';
import TioSearchInput from 'tio-common/components/tio/search-input';
import type EmployeeModel from 'tio-common/models/employee';
import type EmployeesIndexController from 'tio-employee/controllers/authenticated/admin/employees/index';
import type PartnerService from 'tio-employee/services/partner';
import type PlanModel from 'tio-common/models/plan';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
const INPUT_DEBOUNCE = ENV.environment === 'test' ? 0 : 250;
export interface S {
    Args: {
        controller: EmployeesIndexController;
        model: EmployeeModel[];
    };
}
let EmployeeIndexRoute = class EmployeeIndexRoute extends Component<S> {
    @service
    partner: PartnerService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    @tracked
    selectedPlanFilter?: PlanModel;
    @tracked
    selectedStatusFilter = '';
    @tracked
    checklist: string[] = [];
    @tracked
    showCheckboxes = false;
    @tracked
    newSelectedPlan = '';
    @tracked
    changePlans = false;
    @tracked
    isGrid = false;
    @tracked
    setSelectedCompanyFilter = '';
    get company() {
        return this.partner.company;
    }
    get plans() {
        return this.partner.company?.plans || [];
    }
    didSearch = restartableTask(async (string1)=>{
        await timeout(INPUT_DEBOUNCE);
        this.args.controller.search = string1;
    });
    @action
    toggleGrid() {
        this.isGrid = !this.isGrid;
    }
    @action
    createEmployeeTransition() {
        this.router.transitionTo('authenticated.admin.employees.add');
    }
    @action
    addToChecklist(id1: string) {
        if (this.checklist.includes(id1)) {
            // @ts-expect-error: This is a bug
            this.checklist.pop(id1);
        } else {
            this.checklist.push(id1);
        }
    }
    @action
    clearChanges() {
        this.changePlans = false;
        this.newSelectedPlan = '';
        this.checklist = [];
        this.showCheckboxes = false;
    }
    @action
    toggleChangePlans() {
        this.changePlans = !this.changePlans;
        this.showCheckboxes = !this.showCheckboxes;
    }
    @action
    setSelectedPlanFilter(model1: PlanModel) {
        this.selectedPlanFilter = model1;
        this.args.controller.plan = model1?.id || '';
    }
    @action
    setSelectedStatusFilter(participationStatus1: string) {
        this.args.controller.participationStatus = participationStatus1;
    }
    @action
    onSort(sort1: string) {
        this.args.controller.sort = sort1;
    }
    @action
    previousPage() {
        this.args.controller.page = Math.max(1, this.args.controller.page - 1);
    }
    @action
    nextPage() {
        ++this.args.controller.page;
    }
    get totalPages() {
        const { model: model1, controller: controller1 } = this.args;
        // @ts-expect-error: ed needs types
        const meta1 = model1?.meta;
        if (meta1?.page.total) {
            return Math.ceil(meta1.page.total / controller1.limit);
        }
        return 0;
    }
    static{
        template(`
    {{pageTitle (t "partner.employees")}}

    <VStack>
      <Header>
        <div class="flex justify-between items-center">
          <span>{{t "partner.employees"}}</span>
          <Button
            @intent="primary"
            @appearance="outlined"
            {{on "click" this.createEmployeeTransition}}
          >
            {{t "partner.employee.create_employee"}}
          </Button>
        </div>
      </Header>
      <div class="sticky top-0 z-10">
        <div class="w-1/4 inline-block align-bottom">
          <label class="text-xs" aria-hidden={{true}}>
            {{t "partner.employee.search"}}
          </label>
          <TioSearchInput
            @placeholder="Search (Name, Payroll ID, Email)"
            @searchString={{@controller.search}}
            @onInput={{this.didSearch.perform}}
            @onSubmit={{this.didSearch.perform}}
            class="max-w-screen-sm"
          />
        </div>
        <div class="w-1/4 ml-2 inline-block align-bottom">
          <SelectPlans
            @label="Filter by plan"
            @plans={{this.plans}}
            @companyId={{this.company.id}}
            @selected={{this.selectedPlanFilter}}
            @onChange={{this.setSelectedPlanFilter}}
          />
        </div>
        <div class="w-1/4 ml-2 inline-block align-bottom">
          <SelectParticipationStatus
            @placeholder={{@controller.participationStatus}}
            @label="Filter by status"
            {{! @glint-expect-error: fairly certain this is a bug }}
            @participationStatus={{@model.status}}
            @selected={{@controller.participationStatus}}
            @onChange={{this.setSelectedStatusFilter}}
            @companyId={{this.company.id}}
          />
        </div>
      </div>

      <Section>
        <:body>
          {{! @glint-expect-error: return once ED has better types }}
          <Table @isLoading={{@model.isLoading}}>
            <:thead as |options|>
              <options.tr>
                <options.th
                  @sortField="payroll-id"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.payroll_id"}}
                </options.th>
                <options.th
                  @sortField="first-name"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.first_name"}}
                </options.th>
                <options.th
                  @sortField="last-name"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.last_name"}}
                </options.th>
                <options.th
                  @sortField="email"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.email"}}
                </options.th>
                <options.th
                  @sortField="participation-status"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.status"}}
                </options.th>
                <options.th
                  @sortField="plan.company-key"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.plan"}}
                </options.th>
                <options.th
                  @sortField="eligibility-start-date"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.eligibility_start_date"}}
                </options.th>
                {{! using incorrect date }}
                <options.th
                  @sortField="created-at"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.activation_date"}}
                </options.th>
                <options.th>{{t "partner.employee.accounts"}}</options.th>
                <options.th>{{t "partner.employee.loans"}}</options.th>
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model as |employee|}}
                <options.tr>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.payrollId}}
                    </LinkTo>
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.firstName}}
                    </LinkTo>
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.lastName}}
                    </LinkTo>
                  </options.td>
                  <options.td>{{employee.email}}</options.td>
                  <options.td>{{employee.participationStatus}}</options.td>
                  <options.td>{{employee.plan.name}}</options.td>
                  <options.td>
                    {{safeFormatDate
                      employee.eligibilityStartDate
                      month="long"
                      day="2-digit"
                      year="numeric"
                    }}
                  </options.td>
                  {{! this is the wrong date }}
                  <options.td>
                    {{safeFormatDate employee.createdAt month="long" day="2-digit" year="numeric"}}
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.statistics.user.total_accounts}}
                    </LinkTo>
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.statistics.user.total_loans}}
                    </LinkTo>
                  </options.td>
                </options.tr>
              {{/each}}
            </:tbody>
            <:tfoot>
              <tr>
                <td class="text-right" colspan="9">
                  <TablePaginator
                    @page={{@controller.page}}
                    @totalPages={{this.totalPages}}
                    @nextPage={{this.nextPage}}
                    @previousPage={{this.previousPage}}
                    @limit={{@controller.limit}}
                    {{! @glint-expect-error: ed needs types }}
                    @total={{@model.meta.page.total}}
                  />
                </td>
              </tr>
            </:tfoot>
          </Table>
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EmployeeIndexRoute);
