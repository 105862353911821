import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminPslfFormSuccessRoute extends Route {
  @service declare store: StoreService;

  model(params: { form_id: string }) {
    return this.store.findRecord('pslf-form', params.form_id, { reload: true });
  }
}
