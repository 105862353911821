import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

type RouteParams = {
  page: number;
};

// NB: tio-api default is 50; worth discussing whether the frontend apps should have
// a default page size? 50 seems excessive for anything employee-facing - james 20241127
const PAGE_SIZE = 10;

export default class AuthenticatedObservabilityDocumentsIndex extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  queryParams = { page: { refreshModel: true } };

  includes = ['person', 'observable-asset'].join(',');

  async model(params: RouteParams) {
    const { page = 1 } = params;
    const person = this.sessionContext.user.person;
    const query = {
      filter: { person: person.id },
      include: this.includes,
      page: { limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE },
    };

    const observableDocuments = await this.store.query('observable-document', query);

    return {
      observableDocuments,
      total: observableDocuments.meta.page.total,
      page,
      pageSize: PAGE_SIZE,
    };
  }
}
