import ApplicationAdapter from './application';
/**
 *
 * @param {object} GenericRelationshipModel The form model containing information about the unlinked loan to create.
 * @returns {object} The server response.
 */

type DelegateGovernor = {
  'delegate-governor-id': string;
  'delegate-governor-type': string;
  'delegate-expires-after': string;
  'governor-id': string;
  'governor-type': string;
};

class GenericRelationshipsAdapter extends ApplicationAdapter {
  async delegateGovernorRelationship(
    delegateGovernorId: string,
    delegateGovernorType: string,
    expiresDate: string,
    governorId: string,
    governorType: string
  ) {
    if (
      !delegateGovernorId ||
      !delegateGovernorType ||
      !expiresDate ||
      !governorId ||
      !governorType
    ) {
      throw new Error('Missing required parameters');
    }
    const url = this.buildURL('generic-relationships') + '/delegate-governor';
    const relationship: DelegateGovernor = {
      'delegate-governor-id': delegateGovernorId,
      'delegate-governor-type': delegateGovernorType,
      'delegate-expires-after': expiresDate,
      'governor-id': governorId,
      'governor-type': governorType,
    };
    const response = await this.ajax(url, 'POST', {
      data: JSON.stringify({ ...relationship }),
    });
    return response;
  }

  async deleteJurisdictiveRelationship(roleId: string) {
    const url = this.buildURL('generic-relationships') + `/${roleId}/revoke`;
    return await this.ajax(url, 'DELETE');
  }
}

export default GenericRelationshipsAdapter;
