import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import filterBy from 'ember-composable-helpers/helpers/filter-by';
import LoanDetail from './linked-loans-summary/loan-detail';
import screenIs from 'tio-common/helpers/screen-is';
import { Divider } from 'tio-ui/components/utilities';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
export interface AccountsLinkedLoansSummarySignature {
    Args: {
        accounts: AccountModel[];
        allowOverrideEdits: boolean;
        person: PersonModel;
        loanSummary: LoanSummary;
        didCancel?: () => void;
    };
}
export default class AccountsLinkedLoansSummary extends Component<AccountsLinkedLoansSummarySignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: typeof Store;
    @tracked
    customLoanType = '';
    @tracked
    customInterestRate = null;
    get allowOverrideEdits() {
        return this.args.allowOverrideEdits || false;
    }
    get loanErrors() {
        return this.args.loanSummary.errors || [];
    }
    @action
    cancel() {
        this.args.didCancel?.();
    }
    static{
        template(`
    <div>
      {{#if (screenIs "md")}}
        {{#each @accounts as |account|}}
          <AppContentCard
            class="!bg-white !overflow-visible mb-4"
            @title={{account.institutionName}}
          >
            <div>
              <table class="table-fixed w-full">
                <thead>
                  <tr class="border text-center">
                    <th class="font-semibold text-tio-gray-500 text-left">
                      {{t "pslf_dashboard.actions.dialog.loan_name"}}
                    </th>
                    <th class="font-semibold text-tio-gray-500">
                      {{t "pslf_dashboard.actions.dialog.repayment_plan"}}
                    </th>
                    <th class="font-semibold text-tio-gray-500">
                      {{t "pslf_dashboard.actions.dialog.loan_type"}}
                    </th>
                    <th class="font-semibold text-tio-gray-500 w-32">
                      {{t "pslf_dashboard.actions.dialog.interest_rate"}}
                    </th>
                    <th class="font-semibold text-tio-gray-500 text-right w-48">
                      {{t "pslf_dashboard.actions.dialog.current_balance"}}
                    </th>
                    <th class="w-24"></th>
                  </tr>
                </thead>
                <tbody>
                  {{#each account.activeLoans as |loan|}}
                    <LoanDetail
                      @style="row"
                      @loan={{loan}}
                      @loanErrors={{filterBy "loanId" loan.id this.loanErrors}}
                      @allowOverrideEdits={{@allowOverrideEdits}}
                    />
                  {{/each}}
                </tbody>
              </table>
            </div>
          </AppContentCard>
        {{/each}}
      {{else}}
        {{#each @accounts as |account|}}
          <AppContentCard
            @titleClass="truncate text-midnight"
            @title={{account.institutionName}}
            class="mb-4 !overflow-visible"
          >
            <Divider class="my-2" />
            <ul>
              {{#each account.activeLoans as |loan|}}
                <LoanDetail
                  @style="list"
                  @loan={{loan}}
                  @loanErrors={{filterBy "loanId" loan.id this.loanErrors}}
                  @allowOverrideEdits={{@allowOverrideEdits}}
                />
              {{/each}}
            </ul>
          </AppContentCard>
        {{/each}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
