import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type Store from '@ember-data/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';

export default class AuthenticatedTuitionAssistanceProgramOverviewRoute extends Route {
  @service declare store: typeof Store;
  @service declare sessionContext: SessionContextService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  async model({ tas_program_template_id }: { tas_program_template_id: string }) {
    // Load the TasParticipant fresh to ensure we alert any HELD participant state
    const tasParticipant = await this.store.query('tas-participant', {
      filter: {
        employee: this.sessionContext.currentEmployee.id,
      },
    });

    await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();

    const tasGroups = await this.store.query('tas-group', {
      filter: {
        company: this.sessionContext.currentEmployee.company.id,
        flavor: 'TAS.GroupFlavor.MUTUALLY_EXCLUSIVE',
      },
      include: 'tas-groupings.tas-program-template',
    });

    const activeInstances = await this.store.query('tas-program-instance', {
      filter: { employee: this.sessionContext.currentEmployee.id, active: true },
      include: 'tas-program-template',
    });

    const programTemplate = await this.store.findRecord(
      'tas-program-template',
      tas_program_template_id
    );
    return {
      activeInstances: activeInstances,
      programTemplate: programTemplate,
      tasParticipant: tasParticipant[0],
      tasGroups: tasGroups,
    };
  }
}
