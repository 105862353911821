import { modifier } from 'ember-modifier';
import type { EmbedInfoSignature } from '../types';

type Options = {
  embedInfo: EmbedInfoSignature;
  onAnyEvent: (context: unknown, type: string) => void;
};

export default modifier(
  (element: HTMLElement, _, options: Options) => {
    // @ts-expect-error: Spinwheel isn't typed.
    let handler = null;

    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://cdn.spinwheel.io/dropin/v1/dim-initialize.js';
      script.id = 'spinwheel_script';
      element.id = 'spinwheel_container';

      script.onload = () => {
        handler = spinwheelDidLoad(options.embedInfo, options.onAnyEvent);
        resolve(true);
      };
      script.onerror = () => reject(new Error('Failed to load script'));
      element.parentElement?.appendChild(script);
    });
    return () => {
      // @ts-expect-error: Spinwheel isn't typed.
      handler.exit();

      document.getElementById('spinwheel_script')?.remove();
      window.Spinwheel = null;
    };
  },
  // @ts-expect-error: There is an issue with modifiers not exposing this correctly.
  { eager: false }
);

const spinwheelDidLoad = (
  embedInfo: EmbedInfoSignature,
  onAnyEvent?: (context: unknown, type: string) => void
) => {
  console.log('spinwheel loaded');
  const dropinConfig = {
    disableAddAnotherAccount: 1,
    footer: 0,
    header: 0,
    module: 'loan-servicers-login',
    token: embedInfo.embed.token,
    updateCredentials: 0,
    landingUrl: `loan-information/${embedInfo.embed.providerInstitutionId}`,
  };

  const handler = window.Spinwheel.create({
    containerId: 'spinwheel_container',
    env: embedInfo.embed.providerEnvironment,
    dropinConfig,
    onError: (context: unknown) => {
      onAnyEvent?.(context, 'error');
    },
    onEvent: (context: unknown) => {
      onAnyEvent?.(context, 'event');
    },
    onExit: (context: unknown) => {
      onAnyEvent?.(context, 'exit');
    },
    onLoad: (context: unknown) => {
      onAnyEvent?.(context, 'load');
    },
    onPageChange: (context: unknown) => {
      onAnyEvent?.(context, 'page change');
    },
    onSettled: (context: unknown) => {
      onAnyEvent?.(context, 'settled');
    },
    onResize: (context: unknown) => {
      onAnyEvent?.(context, 'resize');
    },
    onSuccess: (context: unknown) => {
      onAnyEvent?.(context, 'success');
    },
    onUnMountDropin: (context: unknown) => {
      onAnyEvent?.(context, 'unmount');
    },
  });
  handler.open();
  return handler;
};
