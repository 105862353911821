import ApplicationAdapter from './application';

/**
 * An extended adapter for the `User` model.
 *
 * @memberof adapters
 */
class UserAdapter extends ApplicationAdapter {
  /**
   * @param {string}  userId - an ID of a user record
   *
   * @return {object}
   */
  async getLoanSummary(userId: string) {
    // TODO: can we just remove this check once all consuming files are TS?
    if (!userId) {
      return;
    }
    const url = this.buildURL('user', userId) + '/summarize-loans';
    return await this.ajax(url, 'GET');
  }
}

export default UserAdapter;
