import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AccountsTodo from './actions/accounts-todo';
import EstimatorTodo from './actions/estimator-todo';
import FormStatusTodo from '../dashboard/actions/form-status-todo';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type AccountModel from 'tio-common/models/account';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
import type RecommendationModel from 'tio-common/models/recommendation';
import type PersonModel from 'tio-common/models/person';
import { Section, VStack } from 'tio-ui/components/layout';
interface PslfDashboardActionsSignature {
    Args: {
        accounts: AccountModel[];
        loanSummary: LoanSummary;
        pslfProfile: PslfProfileModel;
        person: PersonModel;
        recommendation: RecommendationModel;
    };
    Element: HTMLElement;
}
const PslfDashboardActionsComponent: TOC<PslfDashboardActionsSignature> = template(`
  <Section ...attributes>
    <:header>{{t "pslf_dashboard.actions.default"}}</:header>
    <:body>
      <VStack @collapsed={{true}}>
        <Section>
          <FormStatusTodo @itemNo="1" @pslfProfile={{@pslfProfile}} />
        </Section>
        <Section>
          <AccountsTodo
            @accounts={{@accounts}}
            @itemNo="2"
            @person={{@person}}
            @loanSummary={{@loanSummary}}
          />
        </Section>
        <Section>
          <EstimatorTodo
            @accounts={{@accounts}}
            @itemNo="3"
            @loanSummary={{@loanSummary}}
            @person={{@person}}
            @recommendation={{@recommendation}}
          />
        </Section>
      </VStack>
    </:body>
  </Section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfDashboardActionsComponent;
