import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type Controller from '@ember/controller';

import type PaymentModel from 'tio-common/models/tas-payment';
import type SessionContextService from 'tio-employee/services/session-context';
import type PartnerService from 'tio-employee/services/partner';

const PAGE_LIMIT = 50;

type Params = {
  page: number;
  state: string;
};

export default class AuthenticatedAdminTuitionAssistancePaymentsRoute extends Route {
  @service declare sessionContext: SessionContextService;
  @service declare partner: PartnerService;
  @service declare store: StoreService;

  queryParams = {
    page: { refreshModel: true },
    state: { refreshModel: true },
  };

  setupController(controller: Controller, model: PaymentModel[]) {
    super.setupController(controller, model);
    const params = this.paramsFor(this.routeName);
    if (params.state) {
      controller.set('state', params.state);
    }
  }

  async model(params: Params) {
    const { page, state } = params;

    const company = this.partner.company?.id;

    const pagination = {
      limit: PAGE_LIMIT,
      offset: ((page || 1) - 1) * PAGE_LIMIT,
    };

    const query = {
      filter: { company, state },
      page: pagination,
    };

    const tasPayments = await this.store.query('tas-payment', query);

    return {
      tasPayments,
      total: tasPayments.meta.page.total,
    };
  }
}
