import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import tippy from 'ember-tippy/modifiers/tippy';
import CardInfo from '../card-info';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import NumberBadge from '../todo/number-badge';
import TioButton from 'tio-common/components/tio/button';
import Todo from '../todo';
import type PartnerService from 'tio-employee/services/partner';
import type PslfForm from 'tio-common/models/pslf-form';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
export interface PslfDashboardActionsFormStatusTodoSignature {
    Args: {
        pslfProfile: PslfProfileModel;
        itemNo: string;
    };
}
export default class PslfDashboardActionsFormStatusTodoComponent extends Component<PslfDashboardActionsFormStatusTodoSignature> {
    @service
    partner: PartnerService;
    get isPriorEmployerEnabled() {
        return this.partner.isEnabledPriorEmployerPslf;
    }
    get forms() {
        return this.args.pslfProfile.pslfForms || [];
    }
    get submittedForms() {
        const submittedStates1 = [
            'submitted',
            're_submitted'
        ];
        return this.forms.slice().filter((form1: PslfForm)=>submittedStates1.includes(form1.status));
    }
    get signedForms() {
        const signedStates1 = [
            'borrower_signed',
            'employer_signed',
            'awaiting_submit',
            'submit_pending',
            'submit_errored',
            'submitted',
            're_submitted',
            're_submit_pending',
            're_submit_errored'
        ];
        return this.forms.slice().filter((form1: PslfForm)=>signedStates1.includes(form1.status));
    }
    get hasSignedForms() {
        return !!this.signedForms.length || !!this.submittedForms.length;
    }
    static{
        template(`
    <Todo
      @title={{t "pslf_dashboard.actions.pslf_form_status"}}
      @isComplete={{this.hasSignedForms}}
    >
      <:leftHeader>
        <NumberBadge @number={{@itemNo}} />
      </:leftHeader>
      <:body>
        <div class="grid grid-cols-2 gap-1">
          <CardInfo
            @icon="note_alt"
            @text={{t "pslf_dashboard.actions.forms_i_have_signed"}}
            @data="{{this.signedForms.length}}"
          />
          <CardInfo
            @icon="assignment_turned_in"
            @text={{t "pslf_dashboard.actions.forms_submitted_dept_of_ed"}}
            @data="{{this.submittedForms.length}}"
          />
        </div>
      </:body>
      <:actions>
        {{#if this.hasSignedForms}}
          <TioButton @linkTo="authenticated.pslf.dashboard.my-documents">
            {{t "pslf_dashboard.actions.view_details"}}
          </TioButton>
          <TioButton @linkTo="authenticated.pslf.dashboard.start-new" @outlined={{true}}>
            {{t "pslf_dashboard.actions.start_another_form"}}
            {{#if this.isPriorEmployerEnabled}}
              <MaterialIcon
                @icon="info"
                class="text-lg ml-1 leading-none"
                {{tippy (t "pslf_dashboard.actions.if_you_have_a_prior_employer") placement="top"}}
              />
            {{/if}}
          </TioButton>
        {{else}}
          <TioButton @linkTo="authenticated.pslf.dashboard.start-new">
            {{t "pslf_dashboard.actions.start_new_form"}}
          </TioButton>
        {{/if}}
      </:actions>
    </Todo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
