import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';

export type AuthenticatedTuitionAssistanceProgramsInstanceRouteModel = TasProgramInstanceModel;

export default class AuthenticatedTuitionAssistanceProgramsInstanceRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  includes = [
    'tas-program-template.conditions.public-assets',
    'tas-program-template.company.company-setting',
    'tas-program-template.tas-field-options',
    'tas-applications',
    'tas-applications.tags',
    'dependent',
    'employee.tas-participant',
    'employee.company',
    'employee.dependents',
    'tas-assets',
  ];

  async model({ tas_program_instance_id }: { tas_program_instance_id: string }) {
    const instance = await this.store.findRecord('tas-program-instance', tas_program_instance_id, {
      include: this.includes.join(','),
      reload: true,
    });

    await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();

    return instance;
  }

  afterModel(model: TasProgramInstanceModel /*, transition*/) {
    const { isDraft, tasProgramTemplate: template, tasApplications } = model;
    const firstApp = tasApplications?.[0];
    const routePrefix = 'authenticated.tuition-assistance.programs.instance';

    // this checks if instance is cert program and if the instance has been created
    // also checks if instance isDraftOrConsideredDraft OR isPrimedWithNoApplications
    // sends to cert.new to create a new record for application & course
    if (model.certificateInstanceInDraft) {
      this.router.transitionTo(`${routePrefix}.certificate.new`);
      // otherwise checks if instance is draft (regular flow)
    } else if (isDraft) {
      this.router.transitionTo(`${routePrefix}.apply.index`);
      // checks if tasApplication has been created and has not been submitted
    } else if (model.certificateApplicationInDraft && firstApp) {
      this.router.transitionTo(`${routePrefix}.certificate.edit`, firstApp.id);
      // checks if application is for a cert program, and if it is not in CREATED or DEFINE_COURSES state (submitted)
    } else if (template.isCertificateProgram && firstApp?.certificateApplicationHasSubmitted) {
      this.router.transitionTo(`${routePrefix}.course-applications.show.certificate`, firstApp?.id);
    }
  }
}
