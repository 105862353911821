import { setBuildURLConfig } from '@ember-data/request-utils';
import Application from '@ember/application';
import config from 'tio-employee/config/environment';
import ENV from 'tio-employee/config/environment';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import Model from '@ember-data/model';
// HACK: This fixes a race condition where ember inspector is acccessing ember/data in ways it shouldn't
// https://discord.com/channels/480462759797063690/486549196837486592/1121838241323155536
Model.modelName = '@ember-data/model';

setBuildURLConfig({
  host: ENV.apiHost,
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
