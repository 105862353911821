import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminPslfFormReviewRoute extends Route {
  @service declare store: StoreService;

  model(params: { form_id: string }) {
    const { form_id } = params;
    return this.store.findRecord('pslf-form', form_id, {
      include: 'attachments',
    });
  }
}
