import { service } from '@ember/service';
import { trackedFunction } from 'reactiveweb/function';
import Service from '@ember/service';
import type DataServices from './data';
import type PartnerService from './partner';
import type SessionContextService from './session-context';
import type StoreService from 'tio-common/services/store';
import { tracked } from '@glimmer/tracking';

export interface PastContributionPayment {
  amount: number;
  line_item_date: string;
  line_item_period: string;
  line_item_type: string;
  transaction_date?: string | null;
  servicer: string;
}

export enum ValidationErrorKey {
  ACCOUNTS_UNPAYABLE = 'ACCOUNTS_UNPAYABLE',
  NO_LOAN_BALANCE = 'NO_LOAN_BALANCE',
  EXCEEDED_LIMIT = 'EXCEEDED_LIMIT',
  NOT_STARTED_PLAN = 'NOT_STARTED_PLAN',
  NO_PLAN_CONTRIBUTION = 'NO_PLAN_CONTRIBUTION',
  INVALID_LOAN_TYPE = 'INVALID_LOAN_TYPE',
  NO_EMPLOYEE_TRANSACTIONS = 'NO_EMPLOYEE_TRANSACTIONS',
  TERMS_NOT_ACCEPTED = 'TERMS_NOT_ACCEPTED',
  STALE_ACCOUNT = 'STALE_ACCOUNT',
  NAME_MISMATCH = 'NAME_MISMATCH',
  INVALID_LOAN_STATUS = 'INVALID_LOAN_STATUS',
}

export interface ValidationError {
  key: ValidationErrorKey;
  message: string;
  source: string;
}

export interface FutureContributionPayment {
  amount: number;
  date: string;
  state: string;
  period: string;
  validation_errors: ValidationError[];
}

export default class EmployeeService extends Service {
  @service declare partner: PartnerService;
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare data: DataServices;

  @tracked isLoadingPastContributions = false;
  @tracked isLoadingFutureContributions = false;

  get employee() {
    return this.sessionContext.currentEmployee;
  }

  get current() {
    return this.sessionContext.currentEmployee;
  }

  get contributionPayments(): PastContributionPayment[] {
    return this.contributionsState.value?.payments || [];
  }

  get contributionSummary() {
    return this.contributionsState.value?.summary;
  }

  get futureContributionPayments(): FutureContributionPayment[] {
    return this.futurePayments.value || [];
  }

  get annualPreviewData() {
    return this.annualPreview.value;
  }

  get hasScholarships() {
    const scholarships = this.employee?.scholarships;

    if (scholarships?.length) {
      const validScholarship = scholarships.find((scholarship) => {
        if (!scholarship.thruDate) {
          return true;
        }
        const thruDate = new Date(scholarship.thruDate).getTime();
        const nowDate = new Date().getTime();
        return thruDate > nowDate;
      });
      return !!validScholarship;
    }

    return false;
  }

  get hasLegacyTuitionAssistance() {
    return this.partner.hasLegacyTuitionAssistance && this.hasScholarships;
  }

  get hasContributions() {
    return !!this.contributionPayments.length;
  }

  get isTaOnlyEmployee() {
    const companyKey = this.employee?.plan?.companyKey ?? '';
    const taOnlyPlanId = this.partner.companySettings?.tuitionAssistance?.taOnlyPlanId ?? '';
    return !!(taOnlyPlanId && companyKey.includes(taOnlyPlanId));
  }

  get isLoadedEmployee() {
    return !!this.employee?.id;
  }

  get isLoadingContributions() {
    return this.isLoadingPastContributions || this.isLoadingFutureContributions;
  }

  get needsToLinkLoans() {
    return !this.employee.needsToLinkLoans;
  }

  /**************************
   **  Tracked Functions   **
   **************************/

  contributionsState = trackedFunction(this, async () => {
    const employee = this.employee;
    if (!employee) {
      return;
    }

    try {
      this.isLoadingPastContributions = true;
      const contributionData = await this.store
        .adapterFor('employee')
        .getAccountingPaymentContributions(employee.id);
      this.isLoadingPastContributions = false;
      return contributionData;
    } catch (e) {
      this.isLoadingPastContributions = false;
      console.warn(e);
    }
  });

  futurePayments = trackedFunction(this, async () => {
    const employee = this.employee;
    if (employee.plan && employee.plan.cadence !== 'NEVER') {
      try {
        this.isLoadingFutureContributions = true;
        const data = await this.store
          .adapterFor('employee')
          .getAccountingFuturePayments(employee.id);
        this.isLoadingFutureContributions = false;
        return data;
      } catch (e) {
        this.isLoadingFutureContributions = false;
        console.warn(e);
      }
    } else {
      this.isLoadingFutureContributions = false;
      return;
    }
  });

  annualPreview = trackedFunction(this, async () => {
    const employee = this.employee;
    if (employee) {
      try {
        return await this.store.adapterFor('employee').getAnnualPreview(employee.id);
      } catch (e) {
        console.warn(e);
      }
    } else return;
  });
}
