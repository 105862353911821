import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router';
import type SessionContextService from '../../../services/session-context';

import ENV from 'tio-employee/config/environment';

export default class AuthenticatedObservabilityUploadRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  queryParams = { source: { refreshModel: false } };

  async model(params: { source: string }) {
    const person = this.sessionContext.user.person;

    if (!ENV.featureFlags.observabilityUploads) {
      if (params.source === 'pslf') {
        this.router.transitionTo('authenticated.pslf.linking');
      } else if (params.source === 'syf') {
        this.router.transitionTo('authenticated.syf.account-linking');
      }
      this.router.transitionTo('authenticated.slr.linking');
    }

    const institutionsQuery = {
      filter: {
        isActive: true,
        hasObservabilityInstructions: true,
      },
    };

    const financialInstitutions = await this.store.query(
      'financial-institution',
      institutionsQuery
    );
    return { source: params.source, person, financialInstitutions };
  }
}
