export const tioGray = {
  fifty: {
    name: 'gray-50',
    base: '#fcfcfc',
  },
  hundred: {
    name: 'gray-100',
    base: '#f2f2f2',
  },
  two: {
    name: 'gray-200',
    base: '#dbdbdb',
  },
  four: {
    name: 'gray-400',
    base: '#979797',
  },
  six: {
    name: 'gray-600',
    base: '#525252',
  },
  seven: {
    name: 'gray-700',
    base: '#474747',
  },
  eight: {
    name: 'gray-800',
    base: '#303030',
  },
};

export const tioViolet = {
  fifty: {
    name: 'violet-50',
    base: '#f6f3ff',
  },
  hundred: {
    name: 'violet-100',
    base: '#e6e0fd',
  },
  three: {
    name: 'violet-300',
    base: '#998ce3',
  },
  five: {
    name: 'violet-500',
    base: '#6e44c1',
  },
  eight: {
    name: 'violet-800',
    base: '#5d219e',
  },
  highlight: {
    name: 'violet-highlight',
    base: '#8d50ad',
  },
};

export const tioMidnight = {
  name: 'midnight',
  base: '#48276c',
};

export const tioPlum = {
  name: 'plum',
  base: '#843471',
};

export const tioMaroon = {
  name: 'maroon',
  base: '#bc1317',
};

export const tioTeal = {
  name: 'teal',
  base: '#77bcc0',
};

export const tioFuscia = {
  two: {
    name: 'fuscia-200',
    base: '#bb6bd9',
  },
  three: {
    name: 'fuscia-300',
    base: '#9d21d3',
  },
};

export const tioMint = {
  fifty: {
    name: 'mint-50',
    base: '#aeffe0',
  },
  two: {
    name: 'mint-200',
    base: '#6aedc7',
  },
  three: {
    name: 'mint-300',
    base: '#50e3b8',
  },
};

export const tioOcean = {
  fifty: {
    name: 'ocean-50',
    base: '#eff6f7',
  },
  three: {
    name: 'ocean-300',
    base: '#39c2c9',
  },
  four: {
    name: 'ocean-400',
    base: '#00a8d0',
  },
  six: {
    name: 'ocean-600',
    base: '#00809d',
  },
  eight: {
    name: 'ocean-800',
    base: '#006c9a',
  },
};

export const tioError = {
  four: {
    name: 'error-400',
    base: '#dc2626',
  },
  five: {
    name: 'error-500',
    base: '#b91c1c',
  },
};
