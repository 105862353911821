import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionContextService from 'tio-employee/services/session-context';
import type RootLoggerService from 'tio-employee/services/root-logger';
import type SessionService from 'tio-employee/services/session';

export default class RoleSelectRoute extends Route {
  @service declare rootLogger: RootLoggerService;
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;

  // @ts-expect-error: we should handle this in a constructor
  logger = this.rootLogger.get('route:role-select');

  async beforeModel(transition: unknown) {
    const loggedIn = this.session.requireAuthentication(transition, 'login');

    if (!loggedIn) {
      return;
    }

    if (!this.sessionContext.isLoaded) {
      try {
        await this.sessionContext.load();
      } catch (e) {
        this.logger.error('Could not load session context:', e);
      }
    }
  }
}
