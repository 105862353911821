import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AdminPslfForms extends Controller {
  @tracked group = '';
  @tracked limit = 50;
  @tracked page = 1;
  @tracked search = '';
  @tracked sort = '-created-at';
  @tracked status = 'borrower_signed';

  queryParams = ['search', 'group', 'status', 'page', 'limit'];
}
