import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminTuitionAssistanceProgramTemplatesShowRoute extends Route {
  @service declare store: StoreService;

  includes = ['company.company-setting', 'conditions.public-assets'];

  model(params: { tas_program_template_id: string }) {
    return this.store.findRecord('tas-program-template', params.tas_program_template_id, {
      reload: true,
      include: this.includes.join(','),
    });
  }
}
