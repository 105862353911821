import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedPslfDashboardIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const userId = this.sessionContext.user.id;
    const personId = this.sessionContext.user.person.id;

    const [loanSummary, person, mostRecentRecQuery] = await Promise.all([
      this.store.adapterFor('user').getLoanSummary(userId),
      this.store.findRecord('person', personId, {
        include: 'accounts.financial-institution,accounts.loans',
        reload: true,
      }),
      this.store.query('recommendation', {
        filter: {
          user: userId,
          'requesting-app': 'PSLF',
        },
        include: 'user',
        sort: '-created-at',
        page: {
          offset: 0,
          limit: 1,
        },
      }),
    ]);

    return {
      accounts: person.accounts,
      loanSummary: loanSummary,
      mostRecentRecommendation: mostRecentRecQuery?.[0],
      person: person,
      pslfProfile: this.modelFor('authenticated.pslf'),
    };
  }
}
