import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import RouteTemplate from 'ember-route-template';
import TioButton from 'tio-common/components/tio/button';
export default RouteTemplate(template(`
    <EmailLookupWrapper @route="forgot-password/confirmation">
      <div class="flex flex-col p-6">
        <h1 class="text-lg mb-6">{{t "login.forgot_password_confirmation_title"}}</h1>
        <p class="mb-6">{{t "login.forgot_password_confirmation_body"}}</p>
        <TioButton @linkTo="login" @outlined={{true}} class="w-36 mx-auto">
          {{t "login.back_to_login"}}
        </TioButton>
      </div>
    </EmailLookupWrapper>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
