import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import Paginator from 'tio-common/components/tio/paginator';
import Table from 'tio-common/components/table/index';
import TableRow from './company-forms-table/row';
import type { PslfForm } from 'tio-common/models/search';
import type SearchModel from 'tio-common/models/search';
import type Controller from 'tio-employee/controllers/authenticated/admin/pslf/forms';
interface PslfAdminCompanyFormsTableSignature {
    Args: {
        model: SearchModel<PslfForm>;
        controller: Controller;
    };
}
export default class PslfAdminCompanyFormsTableComponent extends Component<PslfAdminCompanyFormsTableSignature> {
    @action
    updateSortBy(sort1: string) {
        this.args.controller.sort = sort1;
    }
    @action
    changePaginatorPage(page1: number) {
        this.args.controller.page = page1;
    }
    static{
        template(`
    {{! @glint-expect-error: ED types}}
    <Table @isLoading={{@controller.loading}}>
      <:thead as |options|>
        <options.tr>
          <options.th>{{t "partner.employee.payroll_id"}}</options.th>
          <options.th>{{t "name"}}</options.th>
          <options.th>{{t "status"}}</options.th>
          <options.th>{{t "common.pslf.forms.approver"}}</options.th>
          <options.th>{{t "pslf.table.date_borrower_signed"}}</options.th>
          <options.th>{{t "pslf.document_repo.date_employer_signed"}}</options.th>
          <options.th>{{t "pslf.document_repo.form_type_and_employer"}}</options.th>
          <options.th />
        </options.tr>
      </:thead>
      <:tbody as |options|>
        {{#each @model.items as |item|}}
          <TableRow
            @model={{item}}
            @rowClass={{options.rowClass}}
            @cellClass={{options.cellClass}}
          />
        {{/each}}
      </:tbody>
      <:tfoot>
        <tr>
          <td class="text-right" colspan="8">
            <Paginator
              @totalItems={{@model.total}}
              @pageSize={{@controller.limit}}
              @currentPage={{@controller.page}}
              @onChange={{this.changePaginatorPage}}
            />
          </td>
        </tr>
      </:tfoot>
    </Table>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
