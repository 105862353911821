import OAuth2ImplicitGrantAuthenticator from 'ember-simple-auth/authenticators/oauth2-implicit-grant';
import ENV from 'tio-employee/config/environment';

export default class Saml extends OAuth2ImplicitGrantAuthenticator {
  async authenticate(hash: { access_token: string }) {
    await this.#validateToken(hash.access_token);

    return await super.authenticate(hash);
  }

  async #validateToken(token: string) {
    let response, error;

    if (token) {
      try {
        response = await fetch(`${ENV.apiHost}/me`, {
          headers: {
            'x-api-key': ENV.apiKey,
            'tio-auth-token': token,
            Accept: 'application/vnd.api+json',
          },
        });
      } catch (e) {
        error = e.message;
      }
    }

    if (!token) {
      error = 'missing token';
      // @ts-expect-error: not sure
    } else if (response?.status >= 500) {
      error = 'server error';
      // @ts-expect-error: not sure
    } else if (response?.status >= 400) {
      error = 'invalid token';
    }

    if (error) {
      throw new Error(`Could not authenticate using SAML: ${error}`);
    } else {
      return true;
    }
  }
}
