import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat, fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import RecResultsDetails from './results-details';
import screenIs from 'tio-common/helpers/screen-is';
import TioExpandableSection from 'tio-common/components/tio/expandable-section';
import type { PROGRAM_ATTRIBUTES } from './results-details';
import type IntlService from 'ember-intl/services/intl';
import type RecommendationModel from 'tio-common/models/recommendation';
import type RouterService from '@ember/routing/router-service';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
import { Section } from 'tio-ui/components/layout';
import Table from 'tio-common/components/table/index';
export type TableRow = {
    date: string;
    monthlyPayments: string;
    payoffAmount: string;
    termMonths: string;
    totalInterestPaid: string;
    remLoanBalance: string;
    recommendation: RecommendationModel;
};
export interface RecommendationHistorySignature {
    Args: {
        programType: keyof typeof PROGRAM_ATTRIBUTES;
        allRecommendations: RecommendationModel[];
    };
    Element: HTMLElement;
}
export default class RecommendationHistoryComponent extends Component<RecommendationHistorySignature> {
    @service
    intl: IntlService;
    @service
    strategyFinder: StrategyFinderService;
    @service
    router: RouterService;
    @tracked
    toggleDialog = false;
    @tracked
    recommendation?: RecommendationModel;
    @tracked
    date?: string;
    get columnDataKeyAndFormat() {
        return [
            {
                dataKey: 'date',
                formatter: 'formatDate'
            },
            {
                dataKey: 'monthlyPayments',
                formatter: 'formatMoney'
            },
            {
                dataKey: 'payoffAmount',
                formatter: 'formatMoney'
            },
            {
                dataKey: 'termMonths',
                formatter: 'formatTermMonths'
            },
            {
                dataKey: 'totalInterestPaid',
                formatter: 'formatMoney'
            },
            {
                subtitle: this.intl.t('recommendations_estimator.estimated_amount_forgiven_includes_interest'),
                dataKey: 'remLoanBalance',
                formatter: 'formatMoney'
            },
            {
                dataKey: 'recommendation'
            }
        ];
    }
    get horizontalHeaderColumns() {
        return [
            {
                title: this.intl.t('idr.results.date')
            },
            // { title: this.intl.t('recommendations_estimator.ideal_plan') },
            {
                title: this.intl.t('recommendations_estimator.monthly_payment')
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_total_to_pay')
            },
            {
                title: this.intl.t('recommendations_estimator.years_left_to_repay')
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_total_interest_paid')
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_amount_forgiven')
            }
        ];
    }
    get planHistory() {
        const mostRecentRecommendations1 = this.args.allRecommendations;
        const data1 = mostRecentRecommendations1.map((rec1)=>{
            const formatted1 = rec1.formattedBestPlanSummary;
            if (formatted1) {
                // @ts-expect-error: return to this
                formatted1.date = rec1.createdAt;
                // @ts-expect-error: return to this
                formatted1.recommendation = rec1;
            }
            return formatted1;
        });
        return data1;
    }
    get tableRowData() {
        return this.planHistory.map((record1)=>{
            const data1: Partial<TableRow> = {};
            this.columnDataKeyAndFormat.map((col1)=>{
                // @ts-expect-error: return to this
                data1[col1.dataKey] = this.formatData(col1.formatter, record1[col1.dataKey]);
            });
            return data1 as TableRow;
        });
    }
    get routeIsDashboard() {
        return !this.router.currentRouteName?.includes('recommendation-history');
    }
    get recHistoryRoute() {
        const program1 = this.args.programType;
        return program1 === 'idr' ? 'authenticated.idr.dashboard.recommendation-history' : 'authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.recommendation-history';
    }
    get programName() {
        const program1 = this.args.programType;
        return program1 === 'sf' ? this.intl.t('common.strategy_finder.default') : program1 === 'idr' ? this.intl.t('idr.abbreviated') : this.intl.t('pslf.default');
    }
    @action
    formatData(formatFunctionName1: string, data1: string) {
        // @ts-expect-error: return to this
        return this[formatFunctionName1]?.(data1) || data1;
    }
    @action
    formatDate(date1: string) {
        return this.strategyFinder.formatDateRange([
            date1
        ]);
    }
    @action
    formatMoney(cents1 = []) {
        return this.strategyFinder.formatCentsRange(cents1);
    }
    @action
    formatTermMonths(months1 = []) {
        return this.strategyFinder.formatMonthsRange(months1);
    }
    @action
    setRecAndToggleDialog(action1: string, model1?: RecommendationModel) {
        if (action1 === 'open' && model1) {
            this.recommendation = model1;
            this.date = this.formatDate(this.recommendation.createdAt);
            this.toggleDialog = true;
        } else if (action1 === 'close') {
            this.recommendation = undefined;
            delete this.date;
            this.toggleDialog = false;
        }
    }
    static{
        template(`
    <Section>
      <:body>
        {{#if (screenIs "md")}}
          <div ...attributes>
            <div class="font-semibold my-3 flex justify-between uppercase">
              {{concat this.programName " " (t "strategy_finder.recommendation_history")}}
              {{#if this.routeIsDashboard}}
                <LinkTo @route={{this.recHistoryRoute}} class="text-xs tio-copy">
                  {{t "idr.results.view_all"}}
                </LinkTo>
              {{/if}}
            </div>
            <Table>
              <:thead as |options|>
                <options.tr>
                  {{#each this.horizontalHeaderColumns as |column|}}
                    <options.th>{{column.title}}</options.th>
                  {{/each}}
                </options.tr>
              </:thead>
              <:tbody as |options|>
                {{#each this.tableRowData as |row|}}
                  <options.tr>
                    <options.td>
                      <button
                        type="button"
                        {{on "click" (fn this.setRecAndToggleDialog "open" row.recommendation)}}
                        class="tio-copy"
                      >
                        {{row.date}}
                      </button>
                    </options.td>
                    <options.td>{{row.monthlyPayments}}</options.td>
                    <options.td>{{row.payoffAmount}}</options.td>
                    <options.td>{{row.termMonths}}</options.td>
                    <options.td>{{row.totalInterestPaid}}</options.td>
                    <options.td>{{row.remLoanBalance}}</options.td>
                  </options.tr>
                {{/each}}
              </:tbody>
            </Table>
            <div class="flex justify-end">
              <LinkTo
                @route="authenticated.repaying-student-debt.askjeni"
                class="tio-copy justify-self-end"
              >
                {{t "need_help"}}
              </LinkTo>
            </div>
          </div>
        {{else}}
          <div class="font-semibold mb-3 flex justify-between">
            {{concat this.programName " " (t "strategy_finder.recommendation_history")}}
            {{#if this.routeIsDashboard}}
              <LinkTo @route={{this.recHistoryRoute}} class="text-xs tio-copy self-center">
                {{t "idr.results.view_all"}}
              </LinkTo>
            {{/if}}
          </div>
          {{#each this.tableRowData as |row|}}
            <TioExpandableSection @isOpen={{false}}>
              <:header>
                <p class="font-semibold text-sm">
                  <button
                    type="button"
                    {{on "click" (fn this.setRecAndToggleDialog "open" row.recommendation)}}
                    class="tio-copy"
                  >
                    {{row.date}}
                  </button>
                </p>
              </:header>
              <:content>
                <div class="grid grid-cols-3 text-sm mb-2">
                  <h2 class="col-span-2">
                    {{t "recommendations_estimator.monthly_payment"}}
                  </h2>
                  <p class="text-center">{{row.monthlyPayments}}</p>
                  <h2 class="col-span-2">
                    {{t "recommendations_estimator.estimated_total_to_pay"}}
                  </h2>
                  <p class="text-center">{{row.payoffAmount}}</p>
                  <h2 class="col-span-2">
                    {{t "recommendations_estimator.years_left_to_repay"}}
                  </h2>
                  <p class="text-center">{{row.termMonths}}</p>
                  <h2 class="col-span-2">
                    {{t "recommendations_estimator.estimated_total_interest_paid"}}
                  </h2>
                  <p class="text-center">{{row.totalInterestPaid}}</p>
                  <h2 class="col-span-2">
                    {{t "recommendations_estimator.estimated_amount_forgiven"}}
                  </h2>
                  <p class="text-center pb-2">{{row.remLoanBalance}}</p>
                </div>
              </:content>
            </TioExpandableSection>
          {{/each}}
          <div class="flex justify-end">
            <LinkTo
              @route="authenticated.repaying-student-debt.askjeni"
              class="tio-copy justify-self-end"
            >
              {{t "need_help"}}
            </LinkTo>
          </div>
        {{/if}}
      </:body>
    </Section>
    <Modal
      @isOpen={{this.toggleDialog}}
      @onClose={{fn this.setRecAndToggleDialog "close"}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>
        {{t "forgiveness_estimator.results.ideal_option"}}
        <div class="font-normal text-sm">{{this.date}}</div>
      </m.Header>
      <m.Body>
        {{#if this.recommendation}}
          <RecResultsDetails
            @recommendation={{this.recommendation}}
            @hideHeaderSection={{true}}
            @programType={{@programType}}
          />
        {{/if}}
      </m.Body>
      <m.Footer>
        <Footer @isRunning={{false}} @onSubmit={{fn this.setRecAndToggleDialog "close"}} />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
