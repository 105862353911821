import type { TasProgramInstanceState } from 'tio-common/types/tuition-assistance';

const tasProgramInstanceStateBadgeMap = {
  'TAS.ProgramInstanceState.CREATED': { intent: 'success', label: 'In Progress' },
  'TAS.ProgramInstanceState.DRAFT': { intent: 'success', label: 'In Progress' },
  'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL': { intent: 'primary', label: 'Pending' },
  'TAS.ProgramInstanceState.PROGRAM_APPROVED': { intent: 'success', label: 'In Progress' },
  'TAS.ProgramInstanceState.PROGRAM_NOT_APPROVED': { intent: 'error', label: 'Rejected' },
  'TAS.ProgramInstanceState.PROGRAM_REJECTED': { intent: 'error', label: 'Rejected' },
  'TAS.ProgramInstanceState.PRIMED': { intent: 'success', label: 'In Progress' },
  'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL': {
    intent: 'primary',
    label: 'Pending',
  },
  'TAS.ProgramInstanceState.COMPLETION_APPROVED': { intent: 'success', label: 'In Progress' },
  'TAS.ProgramInstanceState.COMPLETION_NOT_APPROVED': { intent: 'error', label: 'Rejected' },
  'TAS.ProgramInstanceState.COMPLETION_REJECTED': { intent: 'error', label: 'Rejected' },
  'TAS.ProgramInstanceState.PENDING_WITHDRAWAL_APPROVAL': {
    intent: 'primary',
    label: 'Pending',
  },
  'TAS.ProgramInstanceState.WITHDRAWAL_APPROVED': { intent: 'warning', label: 'Withdrawn' },
  'TAS.ProgramInstanceState.WITHDRAWAL_NOT_APPROVED': {
    intent: 'error',
    label: 'Withdrawal Not Approved',
  },
  'TAS.ProgramInstanceState.WITHDRAWAL_REJECTED': {
    intent: 'error',
    label: 'Withdrawal Rejected',
  },
  'TAS.ProgramInstanceState.COMPLETED': { intent: 'success', label: 'Completed' },
  'TAS.ProgramInstanceState.WITHDRAWN': { intent: 'error', label: 'Withdrawn' },
  'TAS.ProgramInstanceState.ABANDONED': { intent: 'error', label: 'Abandoned' },
};

export const instanceBadgeState = (state: TasProgramInstanceState) => {
  return tasProgramInstanceStateBadgeMap[state];
};
