import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not, and } from 'tio-ui/utilities';
import { object, string } from 'yup';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import type EmailAddressModel from 'tio-common/models/email-address';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import type Store from '@ember-data/store';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import TioIconButton from 'tio-common/components/tio/icon-button';
import { Divider } from 'tio-ui/components/utilities';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
const emailValidationSchema = object({
    email: string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format')
});
interface SyfDashboardPersonalEmailSignature {
    Args: {
        matchParticipant: MatchParticipantModel;
    };
    Element: HTMLDivElement;
}
let SyfDashboardPersonalEmailComponent = class SyfDashboardPersonalEmailComponent extends Component<SyfDashboardPersonalEmailSignature> {
    @service
    partner: PartnerService;
    @service
    store: typeof Store;
    @service
    sessionContext: SessionContextService;
    @tracked
    emailAddress: EmailAddressModel;
    @tracked
    isEditing: boolean = false;
    @tracked
    submitError = null;
    @tracked
    originalEmail: string;
    constructor(owner1: unknown, args1: SyfDashboardPersonalEmailSignature['Args']){
        super(owner1, args1);
        this.setEmailAddress();
        this.originalEmail = this.args.matchParticipant?.emailAddress?.email || '';
    }
    get emailValidationError() {
        const schema1 = emailValidationSchema;
        try {
            schema1?.validateSync?.({
                email: this.emailAddress.email
            }, {
                abortEarly: false
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    get disableSaveEmail() {
        const valueChanged1 = this.originalEmail !== this.emailAddress.email;
        return !valueChanged1 || !!this.emailValidationError.length;
    }
    saveEmail = dropTask(async ()=>{
        if (!this.emailValidationError) {
            return;
        }
        this.submitError = null;
        this.args.matchParticipant.emailAddress = this.emailAddress;
        try {
            await this.args.matchParticipant.emailAddress.save();
            await this.args.matchParticipant.save();
        } catch (e1) {
            this.submitError = e1;
            console.error(e1);
        } finally{
            this.originalEmail = this.emailAddress.email;
            this.toggleEdit();
        }
    });
    @action
    toggleEdit() {
        this.isEditing = !this.isEditing;
    }
    @action
    cancel() {
        this.isEditing = false;
        this.emailAddress.email = this.originalEmail;
    }
    @action
    setEmailAddress() {
        if (this.args.matchParticipant?.emailAddress) {
            // if found, register existing personal email address as tracked var
            this.emailAddress = this.args.matchParticipant.emailAddress;
        } else if (this.sessionContext.user.personalEmail) {
            this.emailAddress = this.sessionContext.user.personalEmail;
        } else {
            // if not, create a draft email address in the store and track
            this.emailAddress = this.store.createRecord('email-address', {
                contactType: 'PERSONAL',
                user: this.sessionContext.user,
                email: '',
                isActive: true
            });
        }
    }
    static{
        template(`
    <AppContentCard @titleClass="uppercase" @title={{t "syf.dashboard.email.header"}} ...attributes>
      <Divider class="my-2" />
      <div class="text-sm mb-4">
        {{t "syf.dashboard.email.content"}}
      </div>
      <div class="flex justify-center lg:justify-start my-2 mx-4">
        {{#if (and (not this.emailAddress.email) (not this.isEditing))}}
          <TioClickableText
            @icon="add"
            @iconLeft={{true}}
            @outlined={{true}}
            @onClick={{this.toggleEdit}}
            @textClass="font-semibold uppercase text-sm text-tio-gray-700"
          >
            {{t "syf.dashboard.email.add_email"}}
          </TioClickableText>
        {{else}}
          <FormInput
            data-legacy-input
            title={{this.emailAddress.email}}
            @value={{this.emailAddress.email}}
            @onInput={{fn (mut this.emailAddress.email)}}
            @containerClass="grow max-w-xl"
            @errors={{errorsForField "email" schemaErrors=this.emailValidationError}}
            disabled={{not this.isEditing}}
            class="text-center"
          />

          {{#if this.isEditing}}
            <div class="flex justify-end">
              <TioIconButton
                @onClick={{this.cancel}}
                @disabled={{this.saveEmail.isRunning}}
                @title="Cancel"
                @icon="close"
                @outlined={{true}}
              />
              <TioIconButton
                @onClick={{this.saveEmail.perform}}
                @disabled={{this.disableSaveEmail}}
                @title="Save"
                @icon="check"
              />
            </div>
          {{else}}
            <TioIconButton
              @onClick={{this.toggleEdit}}
              @title={{t "common.edit"}}
              @icon="edit"
              @outlined={{true}}
              class="mx-1"
            />
          {{/if}}
        {{/if}}
      </div>
    </AppContentCard>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default SyfDashboardPersonalEmailComponent;
