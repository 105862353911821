import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import type TasApplicationModel from 'tio-common/models/tas-application';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCourseApplicationsShowIndexRoute extends Route {
  @service declare router: RouterService;

  model() {
    return this.modelFor(
      'authenticated.tuition-assistance.programs.instance.course-applications.show'
    );
  }

  afterModel(model: TasApplicationModel /*, transition*/) {
    if (model.isDraft && !!model.activeCourses.length) {
      this.router.transitionTo(
        'authenticated.tuition-assistance.programs.instance.course-applications.show.pre-approval.review'
      );
    } else if (model.isDraft) {
      this.router.transitionTo(
        'authenticated.tuition-assistance.programs.instance.course-applications.show.pre-approval.add-course'
      );
    }
  }
}
