import ApplicationAdapter from './application';
import type MatchPaymentModel from 'tio-common/models/match-payment';

// is this a resource? more generically an edpoint? an action?
const CERTIFY_ENDPOINT = '/certify';

/**
 * @memberof adapters
 */
export default class MatchPaymentAdapter extends ApplicationAdapter {
  async certifyPayments(payments: Array<MatchPaymentModel>): Promise<void> {
    const payload = { match_payment_ids: payments.map((payment) => payment.id) };
    const url = this.buildURL('match-payments') + CERTIFY_ENDPOINT;
    return await this.ajax(url, 'PATCH', { data: payload });
  }
}
