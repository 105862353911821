import { service } from '@ember/service';
import { setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import Route from '@ember/routing/route';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCertificateNewRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  async model() {
    const programInstance = this.modelFor(
      'authenticated.tuition-assistance.programs.instance'
    ) as TasProgramInstance;
    const programTemplate = programInstance.tasProgramTemplate;
    const initialFields = programTemplate.applicationFieldsHash;
    const customFields = programTemplate.applicationCustomFields;

    const displayName = this.intl.formatDate(Date.now(), {
      month: 'long',
      year: 'numeric',
    });

    setSingleValueForTasField('APPLICATION_NAME', displayName, initialFields);

    const application = this.store.createRecord('tas-application', {
      tasProgramInstance: programInstance,
      fields: initialFields,
      customFields,
    });

    const course = this.store.createRecord('tas-course', {
      tasApplication: application,
      fields: {},
      customFields: application.tasProgramInstance.tasProgramTemplate.courseCustomFields,
    });

    programInstance.tasApplications.push(application);
    application.tasCourses.push(course);

    try {
      await application.save();
      await course.save();
    } catch (error) {
      console.error('Unable to pre-save application and course due to error:', error);
    }

    return {
      instance: application.tasProgramInstance,
      application: application,
      course: course,
    };
  }
}
