import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedAdminEligibilityFilesIndexController extends Controller {
  @tracked status = 'ALL';
  @tracked startDate = '';
  @tracked sort = '-created-at';
  @tracked page = 1;
  @tracked limit = 50;

  queryParams = ['status', 'startDate', 'sort', 'page', 'limit'];

  // Used by route to coordinate loading state
  @tracked isLoading = false;
}
