import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedSyfLinkingConfirmationRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model({ institution_id }: { institution_id: string }) {
    // By the time we get to this step the account is already linked.
    // This call can be used to get information about the last step.
    const linkedAccountData = await this.store
      .adapterFor('account')
      .fetchLinkingInfo(this.sessionContext.user.id, institution_id, 2);

    return await this.store.findRecord('account', linkedAccountData.id, {
      include: 'loans,financial-institution',
      reload: true,
    });
  }
}
