import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import TioButton from 'tio-common/components/tio/button';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
export default class TuitionAssistanceDashboardProgramHeader extends Component {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    get isDependentProgramsEnabled() {
        return (this.sessionContext.currentEmployee?.company.companySetting.tuitionAssistance?.isDependentProgramsEnabled || false);
    }
    get showDependentProgramsButton(): boolean {
        return (this.router.currentRouteName === 'authenticated.tuition-assistance.dashboard' && this.isDependentProgramsEnabled);
    }
    static{
        template(`
    <div class="flex justify-between items-center">
      <h2 class="text-lg font-semibold">{{t
          "common.tuition_assistance.dashboard.program_header"
        }}</h2>
      {{#if this.showDependentProgramsButton}}
        <div>
          <TioButton
            @linkTo="authenticated.tuition-assistance.dependent-programs"
            @outlined={{true}}
            class="!border-tio-gray-200 !rounded-lg !lowercase !capitalize !text-tio-gray-600"
            data-test-dependent-programs-button
          >
            <span class="flex items-end">{{svgJar "people-outline-24" class="mr-2"}}
              {{t "common.tuition_assistance.dependent_programs_button"}}
            </span>
          </TioButton>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
