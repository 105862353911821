import ApplicationAdapter from './application';
import type ObservableDocumentModel from 'tio-common/models/observable-document';

const MODEL_TYPE = 'observable-document';

class ObservableDocumentAdapter extends ApplicationAdapter {
  async dispute(observableDocument: ObservableDocumentModel, note: string) {
    const url =
      this.buildURL(MODEL_TYPE, observableDocument.id) + '/transition/subsistence-state/dispute';
    const payload = { note };
    await this.ajax(url, 'PATCH', { data: payload });
    await observableDocument.reload();
  }
}

export default ObservableDocumentAdapter;
