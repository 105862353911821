import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type Store from '@ember-data/store';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedTuitionAssistanceMentoringRoute extends Route {
  @service declare store: typeof Store;
  @service declare sessionContext: SessionContextService;
  @service declare partner: PartnerService;

  async model() {
    const employee = await this.store.findRecord(
      'employee',
      this.sessionContext.currentEmployee.id
    );

    return { employee: employee, hasSupport: this.isSupportEnabled };
  }

  get isSupportEnabled() {
    return this.partner.isAnySupportEnabled;
  }
}
