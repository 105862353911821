import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedRepayingStudentDebtRepaymentStrategyFinderDashboardIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const loanSummary = await this.store
      .adapterFor('user')
      .getLoanSummary(this.sessionContext.user.id);
    const allRecsQuery = await this.store.query('recommendation', {
      filter: {
        user: this.sessionContext.user.id,
        'requesting-app': 'STRATEGY_FINDER',
      },
      include: 'user',
      sort: '-created-at',
      page: {
        offset: 0,
        limit: 3,
      },
    });
    const person = await this.store.findRecord('person', this.sessionContext.user.person.id, {
      include: 'accounts.financial-institution,accounts.loans,user.answer-profile',
      reload: true,
    });
    // TODO: There is no reason to await any of this
    const user = await person.user;
    await user.answerProfile;
    const personAccounts = await person.accounts;
    await personAccounts.financialInstitution;

    // TODO: Why are we transforming the person model? Makes it hard to type.
    return {
      accounts: personAccounts,
      allRecommendations: allRecsQuery,
      loanSummary: loanSummary,
      mostRecentRecommendation: allRecsQuery?.objectAt(0),
      person: person,
      user: user,
    };
  }
}
