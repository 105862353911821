import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { eq } from 'tio-ui/utilities';
import TioIconButton from 'tio-common/components/tio/icon-button';
import CardsGeneric from 'tio-common/components/tuition-assistance/cards/generic';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import formatDateString from 'tio-common/helpers/format-date-string';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
const programFields = [
    {
        name: 'SCHOOL_INSTITUTION_NAME',
        mask: null
    },
    {
        name: 'PROGRAM_SPECIALIZATION',
        mask: null
    },
    {
        name: 'PROGRAM_MAJOR',
        mask: null
    },
    {
        name: 'ESTIMATED_PROGRAM_BEGIN',
        mask: 'date'
    },
    {
        name: 'ESTIMATED_PROGRAM_COMPLETION',
        mask: 'date'
    }
];
export interface S {
    Args: {
        programInstance: TasProgramInstanceModel;
    };
    Element: HTMLDivElement;
}
const FormsPreApprovalApplicationReviewProgramReviewCard: TOC<S> = template(`
  <CardsGeneric ...attributes>
    <:headerTitle>
      {{t "tuition_assistance.preapproval_app.program_info"}}
    </:headerTitle>
    <:body>
      <dl class="grid grid-cols-4 gap-2 my-4 w-full">
        {{#each programFields as |programField|}}
          <ProgramReadonlyField
            {{! @glint-expect-error: need better type on field }}
            @name={{programField.name}}
            @programTemplate={{@programInstance.tasProgramTemplate}}
            @fields={{@programInstance.fields}}
            as |field|
          >
            <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
            <dd class="col-span-2 font-semibold">
              {{#if (eq programField.mask "date")}}
                {{! @glint-expect-error }}
                {{formatDateString field.value}}
              {{else}}
                {{! @glint-expect-error }}
                {{field.value}}
              {{/if}}
            </dd>
          </ProgramReadonlyField>
        {{/each}}
      </dl>
    </:body>
    <:footer>
      <div class="flex justify-end">
        <TioIconButton
          @linkTo="authenticated.tuition-assistance.programs.instance.apply.program-information"
          @linkToModel={{@programInstance.id}}
          @title={{t "common.edit"}}
          @icon="edit"
          @outlined={{true}}
          class="mx-1"
        />
      </div>
    </:footer>
  </CardsGeneric>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FormsPreApprovalApplicationReviewProgramReviewCard;
