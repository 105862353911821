import ApplicationSerializer from './application';
const COMMON_READ_ONLY_KEYS = ['statistics', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy'];
export default class EmployeeSerializer extends ApplicationSerializer {
  //   /** These are technically has_one relationships in Rails,
  //    * therefore they cannot be assigned like a normal belongs_to */
  belongsToRelationshipToOmit = ['user', 'plan', 'person', 'tasParticipant', 'matchParticipant'];
  hasManyRelationshipToOmit = ['accounts'];

  serializeBelongsTo(snapshot: unknown, json: unknown, relationship: { key: string }) {
    if (this.belongsToRelationshipToOmit.includes(relationship.key)) {
      return;
    }
    super.serializeBelongsTo(snapshot, json, relationship);
  }

  serializeHasMany(snapshot: unknown, json: unknown, relationship: { key: string }) {
    if (this.hasManyRelationshipToOmit.includes(relationship.key)) {
      return;
    }
    super.serializeHasMany(snapshot, json, relationship);
  }

  keyForAttribute(attr: string) {
    if (attr === 'addressLine1') {
      return 'address-line-1';
    } else if (attr === 'addressLine2') {
      return 'address-line-2';
    } else {
      return super.keyForAttribute(attr);
    }
  }
  serializeAttribute(snapshot: unknown, json: unknown, key: string, attributes: unknown) {
    if (COMMON_READ_ONLY_KEYS.includes(key)) {
      return;
    }

    return super.serializeAttribute(snapshot, json, key, attributes);
  }
}
