import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ChartBar from 'ember-apache-echarts/components/chart/bar';
import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-at-ember-render-modifiers -- should move this to addon
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didResize from 'tio-common/modifiers/did-resize';
import { t } from 'ember-intl';
import type { ChartBounds, Line } from './chart-types';
import type { IntlService } from 'ember-intl';
import type LoanModel from 'tio-common/models/loan';
export interface CombinedAverageTrendSignature {
    Args: {
        loans: LoanModel[];
    };
    Blocks: {
        default: [string];
    };
}
export default class CombinedAverageTrend extends Component<CombinedAverageTrendSignature> {
    @tracked
    chartBounds?: ChartBounds;
    @service
    intl: IntlService;
    get loans() {
        return this.args.loans;
    }
    get averageTrend() {
        const lines1: Line[] = [];
        const filteredLoans1 = [];
        this.loans.forEach(({ observation: observation1 }, index1)=>{
            const labelName1 = `${this.loans[index1]?.account?.financialInstitution?.name} ${this.loans[index1]?.name}`;
            const { ['original-date']: originalDate1, ['original-amount']: originalAmount1, ['balance-date']: balanceDate1, balance: balance1 } = observation1 || {};
            if (originalDate1 && originalAmount1 && balanceDate1 && balance1) {
                lines1.push({
                    label: labelName1,
                    data: [
                        {
                            name: new Date(originalDate1),
                            value: originalAmount1
                        },
                        {
                            name: new Date(balanceDate1),
                            value: balance1
                        }
                    ]
                });
                filteredLoans1.push(this.loans[index1]);
            }
        });
        return lines1;
    }
    get chartData() {
        return this.averageTrend ?? [];
    }
    get averageTrendInterest() {
        let originalValue1 = 0;
        let currentValue1 = 0;
        this.averageTrend.forEach(({ data: data1 })=>{
            // @ts-expect-error: could use better checks
            originalValue1 += data1[0].value;
            // @ts-expect-error: could use better checks
            currentValue1 += data1[1].value;
        });
        const coefficient1 = originalValue1 / 100;
        const trendChanged1 = currentValue1 / coefficient1 - 100;
        const roundedTrend1 = this.roundToDecimals(trendChanged1);
        return `${Math.abs(roundedTrend1)}%`;
    }
    get axisDates() {
        const dates1: number[] = [];
        this.averageTrend.forEach(({ data: data1 })=>{
            // @ts-expect-error: could use better checks
            dates1.push(data1[0].name);
            // @ts-expect-error: could use better checks
            dates1.push(data1[1].name);
        });
        // TODO: If there is no data we shouldn't even be rendering the graphs
        if (dates1.length === 0) {
            return '';
        }
        const earliestDate1 = this.intl.formatDate(Math.min(...dates1), {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });
        const latestDate1 = this.intl.formatDate(Math.max(...dates1), {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });
        return `${earliestDate1} - ${latestDate1}`;
    }
    roundToDecimals(number1: number) {
        return Math.round((number1 + Number.EPSILON) * 100) / 100;
    }
    @action
    categoryAxisFormatter(value1: string) {
        value1 = '';
        return value1;
    }
    @action
    valueAxisFormatter(value1: number) {
        const abbrevValue1 = value1 / 1000;
        const roundedValue1 = Math.round(abbrevValue1 * 2) / 2;
        return `${roundedValue1}k`;
    }
    @action
    didResizeContainer(element1: HTMLElement) {
        const bounds1 = element1.getBoundingClientRect();
        this.chartBounds = {
            height: bounds1.height - 40,
            width: bounds1.width - 40
        };
    }
    static{
        template(`
    <div
      class="flex flex-col justify-center items-center w-full h-full"
      {{didInsert this.didResizeContainer}}
      {{didResize this.didResizeContainer debounce=50}}
    >
      {{#if this.chartBounds}}
        <div class="self-start font-semibold">{{this.averageTrendInterest}}</div>
        <div class="relative">
          <div
            class="absolute left-0 top-1/2 -rotate-90 transform origin-left -translate-y-1/2 -ml-4"
          >
            {{t "accounts.amount_of_debt"}}
          </div>
          <ChartBar
            @width={{this.chartBounds.width}}
            @height={{this.chartBounds.height}}
            @variant="groupedLine"
            @valueAxisFormatter={{this.valueAxisFormatter}}
            @categoryAxisFormatter={{this.categoryAxisFormatter}}
            @categoryProperty="name"
            {{! @glint-expect-error }}
            @categoryAxisType="time"
            @series={{this.chartData}}
            @noDataText="No data"
          />
        </div>
        {{yield this.axisDates}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
