import { service } from '@ember/service';
import { setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import Route from '@ember/routing/route';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import type TasGroupModel from 'tio-common/models/tas-group';

export default class AuthenticatedTasProgramsNewRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const { programInstance } = this.modelFor(
      'authenticated.tas.programs'
    ) as TasProgramInstanceModel;

    let selectedSchool: TasUnifiedInstitutionModel | undefined;

    if (Object.keys(programInstance?.fields ?? {}).length === 0) {
      programInstance.setInitialEmployeeFields();
      programInstance.customFields = programInstance.tasProgramTemplate.instanceCustomFields;
      setSingleValueForTasField('APPLICATION_ID', programInstance.id, programInstance.fields);

      await programInstance.save();
    }

    try {
      const tasInstitutionId = programInstance.fields['TAS_INSTITUTION_ID']?.values?.[0];
      const dapipInstitutionId = programInstance.fields['DAPIP_INSTITUTION_ID']?.values?.[0];

      if (tasInstitutionId || dapipInstitutionId) {
        const filter: Record<string, string | number | undefined> = {
          'tas-program-template': programInstance.tasProgramTemplate.id,
        };

        if (dapipInstitutionId) {
          filter['dapip-id'] = dapipInstitutionId;
        }

        if (tasInstitutionId) {
          filter['institution-id'] = tasInstitutionId;
        }
        const results = await this.store.query('tas-unified-institution', {
          filter,
          page: { limit: 1, offset: 0 },
        });
        selectedSchool = results.objectAt(0);
      }
    } catch (e) {
      console.error('Error attempting to load previously selected school', e);
    }

    // tasGroups and activeInstances will be needed for check of mutually exclusive groups
    // and in progress instances of the current template
    const tasGroups = await this.store.query<TasGroupModel[]>('tas-group', {
      filter: {
        company: this.sessionContext.currentEmployee.company.id,
        flavor: 'TAS.GroupFlavor.MUTUALLY_EXCLUSIVE',
      },
      include: 'tas-groupings.tas-program-template',
    });

    const activeInstances = await this.store.query<TasProgramInstanceModel[]>(
      'tas-program-instance',
      {
        filter: { employee: this.sessionContext.currentEmployee.id, active: true },
        include: 'tas-program-template',
      }
    );

    return {
      activeInstances: activeInstances,
      programInstance: programInstance,
      selectedSchool: selectedSchool,
      tasGroups: tasGroups,
    };
  }
}
