import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCourseApplicationsShowEditCourseRoute extends Route {
  @service declare store: StoreService;

  model({ tas_course_id }: { tas_course_id: string }) {
    return this.store.peekRecord('tas-course', tas_course_id);
  }
}
