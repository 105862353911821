import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type PslfProfileModel from 'tio-common/models/pslf-profile';

export default class AuthenticatedPslfRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const currentEmployee = await this.sessionContext.currentEmployee;
    const employee = await this.store.findRecord('employee', currentEmployee?.id, {
      include: 'pslf-profile.pslf-forms',
      reload: true,
    });
    const pslfProfile = await employee.pslfProfile;

    if (!pslfProfile) {
      const newProfile = this.store.createRecord('pslfProfile', {
        employee: employee,
      });
      await newProfile.save();
      return newProfile;
    }

    return pslfProfile as PslfProfileModel;
  }
}
